import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export default function SelectZone({ zones, zone, setZone, label=null }) {
    const handleChange = (event) => { setZone(event.target.value) }
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="zone-select-small-label">{label||'Бүс сонгох'}</InputLabel>
            <Select
                labelId="zone-select-small-label"
                id="zone-select-small"
                value={zone}
                label={label||'Бүс сонгох'}
                onChange={handleChange}
                size='small'
            >
                {zones?.map(z => {
                    return <MenuItem 
                                key={z.id} value={z.id}
                                sx={{ p: '2px 16px' }}
                            >
                                {z.name}
                            </MenuItem>
                })}
            </Select>
        </FormControl>
    )
}
