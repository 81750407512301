import { createContext, useContext, useEffect, useState } from "react"
import { SUPPLIER_ROLES } from "../utils/constants"
import { isTokenValid } from "../utils/functions"
import { registerPushSub } from "../api/auth"

const PushCtx = createContext()
export const usePushCtx = () => useContext(PushCtx)

const PushCtxProvider = ({ children }) => {
    const { role } = isTokenValid() // if user is logged in
    const [isNotifGranted, setIsNotifGranted] = useState(false)
    const [isSubscribed, setIsSubscribed] = useState(false)

    const requestNotificationPermission = async () => {
        if (role) {
            const permission = await Notification.requestPermission()
            if (permission === 'granted') {
                setIsNotifGranted(true)
                return true
            }
            setIsNotifGranted(false)
            return false
        }
    }

    const subscribeUser = async () => {
        if (!isNotifGranted) return
        const registration = await navigator?.serviceWorker?.ready
        const subscription = await registration?.pushManager?.subscribe({
            userVisibleOnly: true,
            applicationServerKey: process.env.REACT_APP_VAPID_KEY
        })
        if (!process.env.REACT_APP_MAIN_API?.includes('pharmo.mn')) {
            console.log("SUB: ", subscription?.endpoint)
        }

        // Send subscription to the backend
        if (subscription) {
            registerPushSub({
                    'endpoint': subscription.endpoint,
                    'auth': btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth')))),
                    'p256dh': btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh'))))
                })
                .then(res => {
                    setIsSubscribed(true)
                })
                .catch(e => {
                    //console.log("ERROR: ", e)
                    setIsSubscribed(false)
                })
        }
    }

    const unsubscribeUser = async () => {
        try {
            const registration = await navigator.serviceWorker.ready
            const subscription = await registration?.pushManager?.getSubscription()
        
            if (subscription) {
                await subscription.unsubscribe()
                console.log('Unsubscribed from push notifications')
            }
        } catch (error) {
            console.error('Failed to unsubscribe:', error)
        }
    }

    useEffect(() => {
        if (isNotifGranted && role) {
            subscribeUser()
        }
    }, [isNotifGranted, role])

    useEffect(() => {
        if (SUPPLIER_ROLES?.includes(role)) {
            requestNotificationPermission()
        }
    }, [role])

    return (
        <PushCtx.Provider
            value={{
                isNotifGranted,
                setIsNotifGranted,
                requestNotificationPermission,
                isSubscribed,
                setIsSubscribed,
                subscribeUser,
                unsubscribeUser,
            }}
        >
            {children}
        </PushCtx.Provider>
    )
}

export default PushCtxProvider