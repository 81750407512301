import { useBcCtx } from '../../../../../context/BroadcastCtx'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { useAuthCtx } from '../../../../../context/AuthCtx'
import { Box, Button, Typography } from '@mui/material'
import { cp } from '../../../../../api/company'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import QRCode from 'qrcode.react'

const Qr = ({ qr, boxOff }) => {
    const { clearUserItems } = useAuthCtx()

    const [paid, setPaid] = useState(null)
    const isPaid = paid?.isPaid
    
    const [err, setErr] = useState(null)
    const errOff = () => { setErr(null) }

    const check = () => {
        const invId = window.localStorage.getItem('invId')
        if (!invId) { return }
        cp({ invId })
            .then(res => {
                if ( res?.data?.isPaid === true ) {
                    setPaid(res?.data)
                    setErr(null)
                } else {
                    setErr('Төлбөр төлөгдөөгүй байна!')
                }
            })
            .catch((e) => { console.log("qpay check err: ", e) })
    }

    const navi = useNavigate()
    const toCompleted = () => { boxOff(); clearUserItems(); navi('/order/completed', { state: { orderNo: paid?.orderNo }}) }
    useEffect(() => {
        if (qr) { setTimeout(() => { boxOff() }, 1000 * 300) }
        if (isPaid) { setTimeout(() => { toCompleted() }, 1000 * 3) }
    }, [qr, isPaid])

    const { pushData, setPushData } = useBcCtx()
    useEffect(() => {
        if (pushData && pushData?.orderNo && pushData?.isPaid) {
            setPaid({ 'orderNo': pushData.orderNo, 'isPaid': pushData.isPaid })
            setPushData(null)
        }
    }, [pushData])

    const qmsg = isPaid ? 'Төлбөр төлөгдлөө!' : 'Доорх QR кодыг уншуулж төлбөр төлснөөр захиалга баталгаажна.'
    
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mb: 5 }}>
            { getToast(Boolean(err), errOff, err, 'warning') }
            <Typography
                textAlign='center' 
                fontSize={ isPaid ? '20px': '14px' }
                sx={{
                    color: isPaid ? 'green' : '#19305C',
                    maxWidth: '200px',
                    mt: 1
                }}
            >{qmsg}</Typography>
            {
                !isPaid
                    ?   <Box sx={{ display: 'flex', flexDirection: 'column',  width: '150px', height: '150px', mt: 1 }}>
                            <Box 
                                sx={{ 
                                    'boxShadow': '0px 2px 8px rgba(0, 0, 0, 0.2)',
                                    display: 'flex', justifyContent: 'center', p: '4px'
                                }}
                            >
                                <QRCode value={qr} />
                            </Box>
                            <Button size='small' variant='contained' sx={{ mt: 3 }} onClick={check}>
                                Төлбөр шалгах
                            </Button>
                        </Box>
                    :   null
            }
        </Box>
    )
}

export default Qr