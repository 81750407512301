import { TableCell, TableRow, Tooltip, useTheme } from "@mui/material"
import { ISCOMPANIES } from "../../../../../../utils/constants"
import { useExternalComponentRect } from "../../../../../hooks"
import TextFieldFilter from './FilterCells/TextFieldFilter'
import { FilterAlt } from "@mui/icons-material"
import DropCell from '../DropCell'
import ByField from './ByField'

export default function FilterRow({ filtering, setFiltering, hhRef }) {
    const theme = useTheme()
	const { height } = useExternalComponentRect({ externalRef: hhRef })

    return (
        <TableRow
			sx={{
				"& > td": {
					borderBottom: "1px solid gray",
					borderBottomColor: theme.palette.primary.light
				},
				position: 'sticky',
				top: height+8||'initial',
				zIndex: 1
			}}
        >
            <TableCell
				align="center"
				sx={{ p: '2px' }}
			>
                <Tooltip title="Шүүж хайх мөр" placement="right">
                    <FilterAlt
						sx={{
							color: theme.palette.secondary.light,
							fontSize: "18px",
							margin: 0
						}}
					/>
                </Tooltip>
            </TableCell>
            <TextFieldFilter
				setFilters={setFiltering}
				filters={filtering}
				field="name"
			/>
            <TextFieldFilter
				setFilters={setFiltering}
				filters={filtering}
				field="rn"
			/>
            <TableCell colSpan={11} sx={{ p: '2px' }} />
            {/* <ByField field='debt' label={'Нийт өр'} filters={filtering} setFilters={setFiltering} />
            <ByField field='credit' label={'Зээлийн лимит'} filters={filtering} setFilters={setFiltering} />
            <ByField field='bad_cnt' label={'Найдваргүй тоо'} filters={filtering} setFilters={setFiltering} />
            <TableCell />
            <TableCell />
            <TableCell />
            <TextFieldFilter setFilters={setFiltering} filters={filtering} field="customer__email" />
            <ByField field='orders_cnt' label={'Захиалга тоо'} filters={filtering} setFilters={setFiltering} />
            <ByField field='orders_sum' label={'Захиалга дүн'} filters={filtering} setFilters={setFiltering} />
            <ByField field='created' label={'Огноо'} filters={filtering} setFilters={setFiltering} /> */}
        </TableRow>
    )
}
