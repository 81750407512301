import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { acceptOrder } from '../../../../../api/order'
import { useState } from 'react'
import Item from './Item'

const changedColor = 'rgba(255, 0, 0, 0.2)'
const isQtyChanged = (item) => item.iQty !== item.itemQty
export default function Items({ items, order, setOrder }) {
    const [msg, setMsg] = useState(null)
    const msgOff = () => { setMsg(null) }
    const accept = () => {
        acceptOrder({ id: order?.id })
            .then(res => {
                setOrder(prev => ({ ...prev, process: 'Хүлээн авсан' }))
                setMsg({ m: 'Амжилттай!', s: 'success' })
            })
            .catch(err => {
                setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
                console.log("ERR: ", err)
            })
    }
    const isReceived = order.process === 'Хүлээн авсан'
    const toAccept = !order?.branch || order.process === 'Түгээлтэнд гарсан'
    const iqc = items.find(item => isQtyChanged(item))

    return (
        <TableRow sx={{ backgroundColor: '#f1f1f1' }}>
            <TableCell colSpan={11} sx={{ p: 0, pb: 1, pl: 3 }}>
                {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 2000)}
                <Table sx={{ ml: 1, width: '700px', boxShadow: 3 }} stickyHeader>
                    <TableHead>
                        <TableRow sx={{ '& > th': { fontSize: '13px', borderBottom: '1px solid grey', p: '1px 6px', backgroundColor: 'secondary.light', color: 'white', borderRight: 1, borderRightColor: 'white' } }}>
                            {['№', 'Барааны нэр', 'Барааны үнэ', 'Анх захиалсан тоо/ш', 'Тоо, ширхэг', 'Нийт үнэ'].map(c => <TableCell align={c === 'Тоо, ширхэг' ? 'center' : 'initial'}>{c}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item, idx) => (
                                    <Item
                                        key={item?.id}
                                        idx={idx}
                                        item={item}
                                        isQtyChanged={isQtyChanged}
                                        changedColor={changedColor}
                                    />
                                ))}
                        {
                            !isReceived && toAccept && <TableRow>
                                                            <TableCell colSpan={5} sx={{ p: '4px 2px', textAlign: 'center' }}>
                                                                <Button 
                                                                    size='small' variant='contained'
                                                                    sx={{ p: '3px', m: 1 }}
                                                                    onClick={accept}
                                                                >Захиалга хүлээн авсан</Button>
                                                            </TableCell>
                                                        </TableRow>
                        }
                    </TableBody>
                    {iqc && <TableCell colSpan={5} align='left' sx={{ p: '2px', pt: 1, pl: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Box sx={{ border: 1, bgcolor: changedColor, width: '12px', height: '12px', mr: 1 }} />
                            <Typography variant='caption'>- Тоо ширхэг өөрчлөгдсөн</Typography>
                        </Box>
                    </TableCell>}
                </Table>
            </TableCell>
        </TableRow>
    )
}