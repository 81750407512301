import { LocationOn } from '@mui/icons-material'
import { Box } from '@mui/material'

export default function LocIcon({ color = null}) {
    return (
        <Box sx={{ position: 'relative' }}>
            <LocationOn
                sx={{
                    color: color||'#004EFF',
                    position: 'absolute',
                    bottom: 0,
                    transform: 'translate(-50%)',
                    fontSize: '50px',
                    filter: 'drop-shadow(1px 2px 1px rgba(0,0,0,0.4))',
                }}
            />
        </Box>
    )
}
