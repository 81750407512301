import { Box, Dialog, IconButton, Typography } from "@mui/material"
import { Close } from "@mui/icons-material"

export default function AllowNotifBox({ open, handleClose }) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
        >
            <Box
                sx={{ p: 2, pb: 3 }}
            >
                <Box textAlign={'right'}>
                    <IconButton>
                        <Close onClick={handleClose} sx={{ fontSize: '18px' }} />
                    </IconButton>
                </Box>
                <Typography>
                    Захиалгын явц, өөрчлөлтийн талаарх мэдэгдлийг шуурхай авахыг хүсвэл зүүн дээд буланд харагдаж буй "Allow" товчин дээр дарна уу!
                </Typography>
                <img 
                    src="/images/allow_notif.PNG" 
                    alt="allo_notif" 
                    style={{
                        width: '250px',
                        height: 'auto',
                        display: 'block',
                        margin: 'auto',
                        marginTop: '10px'
                    }}
                />
            </Box>
        </Dialog>
    )
}
