import AxiosWithToken from "../global/axiosBase"
import { getPageParams } from "./utils"

export const getAllPharmoCustomers = ({ queryKey }) => {
    const [_, page, pageSize, filters] = queryKey
    const fs = filters?.filter(f => f.k !== 'isReviewed')
    let url = getPageParams(page, pageSize, 'confirm/customer/verify/?isReviewed=true', fs)
    return AxiosWithToken.get(url)
}

export const getPharmoCustomersToVerify = ({ queryKey }) => {
    const [_, page, pageSize, filters] = queryKey
    const fs = filters?.filter(f => f.k !== 'isReviewed')
    let url = getPageParams(page, pageSize, 'confirm/customer/verify/?isReviewed=false', fs)
    return AxiosWithToken.get(url)
}

export const verifyPharmoCustomer = (data) => {
    return AxiosWithToken.patch('confirm/customer/verify/', data)
}

export const getOs = () => { return AxiosWithToken.get('confirm/o/') }
export const getNotfs = () => { return AxiosWithToken.get('confirm_notf/') }
export const createNotf = (data) => { return AxiosWithToken.post('confirm_notf/', data) }
export const delNotf = (id) => { return AxiosWithToken.delete(`confirm_notf/${id}/`) }