import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { checkSQpay } from '../../../api/company'
import { useEffect, useState } from 'react'
import { CustomDivider } from '..'

export default function SelectPayType({ payType, setPayType }) {
    const [noQpay, setNoQpay] = useState(null)
    useEffect(() => {
        checkSQpay()
            .then(r => {
                if (r?.status === 204) { setNoQpay(true) }
                if (r?.status === 200) { setNoQpay(null) }
            })
            .catch(_ => {})
    }, [])

    return (
        <Box sx={{ width: '100%' }}>
            <CustomDivider text={'Төлбөрийн хэлбэр'} />
            <RadioGroup
                value={payType}
                onChange={(e) => setPayType(e.target.value)}
                row
                sx={{ width: '100%', justifyContent: 'center' }}
            >
                <FormControlLabel
                    value={'C'} 
                    control={<Radio />}
                    disabled={noQpay}
                    label={<Typography
                                sx={{ 
                                    color: '#555', 
                                    fontSize: '14px'
                                }}
                            >Бэлнээр</Typography>
                        }
                />
                <FormControlLabel 
                    value={'L'} 
                    control={<Radio />}
                    label={<Typography
                                sx={{ 
                                    color: '#555', 
                                    fontSize: '14px'
                                }}
                            >Зээлээр</Typography>
                        }
                />
            </RadioGroup>
        </Box>
    )
}