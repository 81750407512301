import { getCurrentDeliveries, getCurrentDeliveryLocations, getDelmanCurrentDeliveries, updateDeliveryLocation } from '../../../../../api/delivery'
import {APIProvider, ControlPosition, Map, MapControl, useMap } from '@vis.gl/react-google-maps'
import { createContext, useContext, useEffect, useState } from 'react'
import { defaultCenter, defaultZoom, maxBounds } from '../utils'
import { useBcCtx } from '../../../../../context/BroadcastCtx'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { isTokenValid } from '../../../../../utils/functions'
import DeliveryDestinations from './DeliveryDestinations'
import { mapOptions } from '../../../../utils'
import DeliveryRoutes from './DeliveryRoutes'
import ControlPanelBox from './ControlPanel'
import DeliveryItems from './DeliveryItems'
import DeliveryLocs from './DeliveryLocs'
import { useQuery } from 'react-query'
import { Box } from '@mui/material'

const boundRestriction = {
    restriction: {
        latLngBounds: maxBounds,
        strictBounds: true
    },
}

function findDuplicates(arr1, arr2) {
    const duplicates = []
    const set1 = new Set(arr1)
    for (const element of arr2) {
        if (set1.has(element)) {
            duplicates.push(element)
        }
    }
    return duplicates
}

const DeliveryMapCtx = createContext(null)
export const useDeliveryMapCtx = () => useContext(DeliveryMapCtx)
function DeliveryMap() {
    const map = useMap()
    const [zoom, setZoom] = useState(defaultZoom)
    const [center, setCenter] = useState(defaultCenter)

    // control panel
    const [checked, setChecked] = useState([])
    const withRoutes = checked?.includes(1) // show delivey routes (get deliveries locations with route points)
    const [selectedDeliveryId, setSelectedDeliveryId] = useState(null)

    // deliveries
    const { role } = isTokenValid()
    const isDelman = role === 'D'
    const { data: delvsData } = useQuery('get_current_deliveries', isDelman ? getDelmanCurrentDeliveries : getCurrentDeliveries, { refetchOnWindowFocus: true, enabled: findDuplicates(checked, [2, 3])?.length > 0 })
    const allOrders = delvsData?.data?.map(d => d.orders.map(o => ({...o, delman: d.delman, delivery: d, zone: d.zones?.find(z => z.id === o.zone?.id)}))).flat()
    const deliveryItems = delvsData?.data?.filter(d => d?.items?.length > 0)?.map(d => ({ delivery_id: d.id, delman: d.delman, items: d.items }))||[]
    const selDelItems = deliveryItems?.reduce((acc, d) => {
        d?.items?.forEach(i => {
            const added_item = acc?.find(a => a.id === i.id)
            if (!added_item) {
                const { delman, delivery, ...rest } = i
                acc?.push({
                    ...rest,
                    delivery_id: d.delivery_id,
                    delman: d.delman
                })
            }
        })
        return acc
    }, [])
    //console.log("DIs0: ", selDelItems, checked)
    const selectedDeliveryItems = selectedDeliveryId ? selDelItems?.filter(d => d.delivery_id === selectedDeliveryId) : selDelItems

    // destinations
    const dests = allOrders?.reduce((acc, order) => {
        const added_orderer = acc?.find(a => a.id === order.orderer?.id)
        if (added_orderer) {
            added_orderer.orders?.push(order)
            if (!added_orderer.delmans?.find(d => d.id === order.delman?.id)) {
                added_orderer.delmans?.push(order.delman)
            }
        } else {
            acc?.push({
                ...order.orderer,
                orders: [order],
                delmans: [order.delman],
                delivery_id: order.delivery?.id
            })
        }
        return acc
    }, [])
    const destinations = selectedDeliveryId ? dests?.filter(d => d.delivery_id === selectedDeliveryId) : dests
    //console.log("DID: ", destinations)

    // msg
    const [msg, setMsg] = useState(null)
    const successMsg = () => { setMsg({ m: 'Амжилттай!', s: 'success' }) }
    const msgOff = () => { setMsg(null) }
    const waitMsg = () => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) }

    useEffect(() => { document.title = 'Түгээлтийн явц' }, [])

    // deliveries' locations
    const [deliveryLocs, setDeliveryLocs] = useState([])
    const onSuccess = (data) => { setDeliveryLocs(data?.data||[]) }
    const onError = () => { waitMsg() }
    const { data } = useQuery(['get_current_delivery_locations', withRoutes], getCurrentDeliveryLocations, { refetchOnWindowFocus: true, onSuccess, onError })
    const delmans = deliveryLocs?.map(delivery => ({ id: delivery.delman?.id, name: delivery.delman?.name, delivery_id: delivery.id, lat: delivery.lat, lng: delivery.lng }))||[]
    //console.log("LOC: ", delmans)

    const locations = selectedDeliveryId ? deliveryLocs.filter(d => d.id === selectedDeliveryId) : deliveryLocs

    // get driver location updates from server
    const { pushNotif } = useBcCtx()
    useEffect(() => {
        if (pushNotif && pushNotif?.body?.data?.delmanId) {
            setDeliveryLocs(prev => (prev.map(cl => pushNotif.body.data.delmanId === cl.delmanId ? { ...cl, ...pushNotif.body.data } : cl)))
        }
    }, [pushNotif])

    // routes
    const routes = locations?.map(d => ({ delmanId: d.delman?.id, route: d.routes }))||[]
    const showRoute = checked?.includes(1)

    // show traffic jam
    const [trafficLayer, setTrafficLayer] = useState(null)
    const showTrafficJam = checked?.includes(4)
    useEffect(() => {
        if (map) {
            if (showTrafficJam) {
                if (!trafficLayer) {
                    const layer = new window.google.maps.TrafficLayer()
                    layer.setMap(map)
                    setTrafficLayer(layer)
                } else {
                    trafficLayer.setMap(map)
                }
            } else {
                if (trafficLayer) {
                    trafficLayer.setMap(null)
                }
            }
        }
    }, [showTrafficJam, map, trafficLayer])

    // for test
    const handleClickForTest = (e) => {
        return null
        if (!process.env.REACT_APP_MAIN_API?.includes('pharma.mn')) {
            console.log("C: ", e?.detail?.latLng?.lat, e?.detail?.latLng?.lng)
            if (e?.detail?.latLng?.lat && e?.detail?.latLng?.lng) {
                updateDeliveryLocation({
                    delivery_id: 76,
                    lat: e.detail.latLng.lat,
                    lng: e.detail.latLng.lng,
                })
                    .then(res => { console.log("Res: ", res) })
                    .catch(err => { console.log("Err: ", err) })
            }
        }
    }

    return (
        <DeliveryMapCtx.Provider
            values={{ setMsg, successMsg, waitMsg }}
        >
            <Box sx={{ width: '100%', height: '100vh' }}>
                {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s)}
                <Map
                    defaultCenter={center}
                    defaultZoom={zoom}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    minZoom={10}
                    maxZoom={18}
                    mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                    options={{ ...mapOptions, ...boundRestriction }}
                    onClick={handleClickForTest}
                >
                    <MapControl position={ControlPosition.TOP_LEFT}>
                        <ControlPanelBox
                            checked={checked}
                            setChecked={setChecked}
                            selectedDeliveryId={selectedDeliveryId}
                            setSelectedDeliveryId={setSelectedDeliveryId}
                            delmans={delmans}
                        />
                    </MapControl>
                    <DeliveryLocs deliveryLocs={locations} />
                    <DeliveryRoutes 
                        routes={routes} 
                        showRoute={showRoute} 
                    />
                    <DeliveryDestinations
                        destinations={destinations}
                        deliveryLocs={deliveryLocs}
                        selectedDeliveryId={selectedDeliveryId}
                        showDestinations={checked?.includes(2)}
                    />
                    <DeliveryItems
                        deliveryItems={selectedDeliveryItems}
                        showDeliveryItems={checked?.includes(3)}
                        //selectedDeliveryId={selectedDeliveryId}
                    />
                </Map>
            </Box>
        </DeliveryMapCtx.Provider>
    )
}

export default function DeliveryMapProvider() {
    return (
            <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                onLoad={() => { console.log('Maps API has loaded.'); }}
                onError={(err) => console.error('Maps API loading failed.', err)}
                libraries={["places", "geometry"]}
            >
                <DeliveryMap />
            </APIProvider>
    )
}