import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import { getAllPharmoCustomers } from "../../../../api/confirm"
import { scrollStyle } from "../../../../utils/constants"
import TablePages from "../../../common/TablePages"
import { useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import FilterRow from "../FilterRow"
import Row from "./Row"

export default function PharmoCustomers({ waitMsg }) {
	const theme = useTheme()
	const primaryLight = theme.palette.primary.light
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(50)
	const [filters, setFilters] = useState(null)
	const isSuppliers = filters?.find(f => f?.k === 'cType')?.v === 'S'
	const { data, error } = useQuery(["get_pharmo_customers", page, pageSize, filters], getAllPharmoCustomers, { refetchOnWindowFocus: true })
	useEffect(() => { if (error) { waitMsg() } }, [error])
	const pharmoCustomers = data?.data?.results || []
	const count = data?.data?.count||0

	const [customer, setCustomer] = useState(null)

	const hhRef = useRef(null)

	return (
		<TableContainer
			sx={{ 
				mt: 1,
				height: '95%',
				...scrollStyle,
				'&::-webkit-scrollbar': { width: 8, height: 8 }
			}}
		>
			<Table stickyHeader aria-label="simple table">
				<TableHead>
					<TableRow
						sx={{
							"& > th": {
								backgroundColor: "secondary.light",
								borderRight: "1px solid white",
								textAlign: "center",
								fontSize: "13px",
								color: "white",
								cursor: 'pointer',
								padding: '2px 6px'
							},
						}}
					>
						<TableCell ref={hhRef}>
							<Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>№</Typography>
						</TableCell>
						<TableCell>Хэрэглэгчийн имэйл</TableCell>
						<TableCell>Хэрэглэгчийн утас</TableCell>
						<TableCell>Байгууллагын нэр</TableCell>
						<TableCell>Байгууллагын регистр</TableCell>
						{isSuppliers && <TableCell>Нийлүүлэгчийн ID</TableCell>}
						<TableCell>Байгууллагын төрөл</TableCell>
						<TableCell>Тусгай зөвшөөрөл</TableCell>
						<TableCell>ТЗ дуусах хугацаа</TableCell>
						<TableCell>Огноо</TableCell>
					</TableRow>
					<FilterRow
						filters={filters}
						setFilters={setFilters}
						hhRef={hhRef}
					/>
				</TableHead>
				<TableBody>
					{
						pharmoCustomers?.map((row, idx) => (
							<Row
								key={`${idx}`}
								row={row}
								idx={idx}
								page={page}
								pageSize={pageSize}
								setCustomer={setCustomer}
								isSuppliers={isSuppliers}
								primaryLight={primaryLight}
							/>
						))
					}
				</TableBody>
				<TablePages
					count={count}
					page={page}
					setPage={setPage}
					pageSize={pageSize}
					setPageSize={setPageSize}
					colSpan={10}
				/>
			</Table>
		</TableContainer>
	)
}