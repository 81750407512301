import Categories from "../../components/admin/tabs/Categories"
import SystemLog from "../../components/admin/tabs/SystemLog"
import Promotion from "../../components/admin/tabs/Promotion"
import Products from "../../components/admin/tabs/Products"
import Customer from "../../components/admin/tabs/Customer"
import Delivery from "../../components/admin/tabs/Delivery"
import Setting from "../../components/admin/tabs/Settings"
import Report from "../../components/admin/tabs/Report"
import Order from "../../components/admin/tabs/Order"
// delivery
import CurrentDelivery from "../../components/admin/tabs/Delivery/CurrentDelivery"
import DeliveryHistory from "../../components/admin/tabs/Delivery/DeliveryHistory"
import Staff from "../../components/admin/tabs/Staff"

export const adminRoutes = [
    {
        index: true,
        path: 'orders',
        element: <Order />,
    },
    {
        path: 'promotion',
        element: <Promotion />
    },
    // {
    //     path: 'customers',
    //     element: <Customers />
    // },
    {
        path: 'products',
        children: [
            {
                index: true,
                path: 'list',
                element: <Products />,
            },
            {
                path: 'category',
                element: <Categories />,
            }
        ]
    },
    {
        path: 'staff',
        element: <Staff />
    },
    {
        path: 'settings',
        element: <Setting />
    },
    {
        path: 'delivery',
        children: [
            {
                index: true,
                element: <Delivery />
            },
            {
                path: 'current',
                element: <CurrentDelivery />
            },
            {
                path: 'history',
                element: <DeliveryHistory />
            }
        ],
    },
    {
        path: 'report',
        element: <Report />
    },
    {
        path: 'system_log',
        element: <SystemLog />
    },
    {
        path: 'customers',
        element: <Customer />
    },
]