import SearchInputTab from "../SearchInputTab"
import { Tab, Tabs } from "@mui/material"

export default function OrderTabs({ setFilters, setPage, tab, setTab }) {
    const clientName = 'MACS' // get from isTokenValid
    const handleTabChange = (e, value) => {
        setTab(value)
        const fValue = value === 1 ? null : value === 2 ? true : value === 3 ? false : 1
        setFilters(prev => {
            const newFilters = prev?.filter(f => f.fKey !== 'cid')||[]
            if (fValue !== null) {
                newFilters?.push({ fKey: 'cid', operator: '=', fValue })
            }
            return newFilters
        })
    }

    return (
        <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label='tabs'
            sx={{
                minHeight: '28px',
                height: '28px',
                mb: '4px',
                '& .MuiTab-root': { minHeight: '25px', height: '25px' }
            }}
        >
            <Tab label={'Бүгд'} value={1} />
            <Tab label={'Pharmo'} value={2} />
            <Tab label={`${clientName}`} value={3} />
            <SearchInputTab 
                setFilters={setFilters} 
                setPage={setPage}
                tab={tab}
            />
        </Tabs>
    )
}
