import { isTokenValid } from "../utils/functions"

export const toPrice = (price) => {
    return `${
                new Intl.NumberFormat(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                }).format(price)
            } ₮`
}

export const forceLogout = () => {
    localStorage.removeItem('access_token')
	localStorage.removeItem('refresh_token')
    window.location.href = '/home'
}

const showPharmoNotif = ({ title, body }) => {
    new Notification(
        title, 
        {
            body,
            icon: '/Pharmo.ico'
        }
    )
}

const isTheNotifShown = (notifId, notifType) => {
    const prevsData = window.localStorage.getItem(notifType)
    const prevs = prevsData ? JSON.parse(prevsData) : null
    if (prevs?.length > 0) {
        if (prevs.find(p => p === notifId)) return true
        window.localStorage.setItem(notifType, JSON.stringify([ ...prevs, notifId]))
        return false
    }
    window.localStorage.setItem(notifType, JSON.stringify([notifId]))
    return false
}

export const showNewOrderNotif = (pushData) => {
    const { user_id } = isTokenValid()
    const targetIds = pushData?.target_ids||[]
    if (targetIds?.find(t => t === user_id)) {
        const data = pushData?.data
        const orderNo = data?.order?.orderNo
        if (orderNo && !isTheNotifShown(orderNo, data?.type)) {
            const order = data?.order
            const isSeller = order?.is_seller
            const who = isSeller ? 'Борлуулагч' : 'Захиалагч'
            const name = order?.orderer||''
            const totalPrice = order?.total_price ? toPrice(order.total_price) : ''
            const title = 'Шинэ захиалга нэмэгдлээ!'
            const body = `\nЗахиалгын дугаар:\t${orderNo}\n${who}:\t\t${name}\nҮнийн дүн:\t\t${totalPrice}`
            showPharmoNotif({ title, body })
        }
    }
}

export const showDeliveryStart = (pushData) => {
    const { user_id } = isTokenValid()
    const targetIds = pushData?.target_ids||[]
    if (targetIds?.find(t => t === user_id)) {
        const data = pushData?.data
        const deliveryId = data?.delivery_id
        if (deliveryId && !isTheNotifShown(deliveryId, data?.type)) {
            const delmanName = data?.delman||''
            showPharmoNotif({
                title: 'Түгээлт эхэллээ!',
                body: `Түгээгч:\t${delmanName}`
            })
        }
    }
}

export const showDeliveryEnd = (pushData) => {
    const { user_id } = isTokenValid()
    const targetIds = pushData?.target_ids||[]
    if (targetIds?.find(t => t === user_id)) {
        const data = pushData?.data
        const deliveryId = data?.delivery_id
        if (deliveryId && !isTheNotifShown(deliveryId, data?.type)) {
            const delmanName = data?.delman||''
            showPharmoNotif({
                title: 'Түгээлт дууслаа!',
                body: `Түгээгч:\t${delmanName}`
            })
        }
    }
}

export const showNewLogin = (pushData) => {
    const { user_id } = isTokenValid()
    const data = pushData?.data
    const targetIds = pushData?.target_ids||[]
    if (targetIds.find(t => t === user_id)) {
        const ip = data?.ip||''
        const device = data?.device||''
        new Notification('Шинэ төхөөрөмжөөс нэвтэрлээ!', {
            body: `IP хаяг:\t${ip}\nТөхөөрөмж:\t${device}`,
            icon: '/Pharmo.ico'
        })
    }
}

// order delivery notif
const orderDeliveryNotifKey = 'order_delivery_notif'
export const isShownOrderDeliveryNotif = (uniqId) => {
    const prevsData = window.localStorage.getItem(orderDeliveryNotifKey)
    const prevs = prevsData ? JSON.parse(prevsData) : null
    if (prevs?.length > 0) {
        if (prevs.find(p => p === uniqId)) return true
        window.localStorage.setItem(orderDeliveryNotifKey, JSON.stringify([ ...prevs, uniqId]))
        return false
    }
    window.localStorage.setItem(orderDeliveryNotifKey, JSON.stringify([uniqId]))
    return false
}
export const showOrderDeliveryNotif = (notif) => {
    const notifData = notif?.data
    if (notifData?.uniqId && !isShownOrderDeliveryNotif(notifData?.uniqId)) {
        if (notif?.type === 'OO') {
            new Notification(notif?.title, {
                body: `\nЗахиалгын дугаар:\t${notifData?.orders?.join(', ')}\nТүгээгч:\t${notifData?.delman||'-'} (${notifData?.supplier||'-'})\nУтас:\t${notifData?.delmanPhone|'-'}`,
                icon: '/Pharmo.ico'
            })
        }
    }
}