import { Box, Dialog, DialogContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { scrollStyle } from '../../../../../utils/constants'
import { toPrice } from '../../../../../utils/functions'
import { Close } from '@mui/icons-material'

export default function OrderDetail({ orderItems, isFetched, setOrderId }) {
    const totalCount = orderItems?.reduce((acc, cur) => acc + cur?.itemQty, 0)
    const totalPrice = orderItems?.reduce((acc, cur) => acc + cur?.itemTotalPrice, 0)
    return (
        <Dialog 
            open={Boolean(orderItems?.length > 0 && isFetched)} 
            onClose={() => { setOrderId(null) }}
        >
            <DialogContent
                sx={{ p: '10px' }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            color: '#666'
                        }}
                    >
                        Захиалгын дэлгэрэнгүй
                    </Box>
                    <Box sx={{ textAlign: 'right' }}>
                        <IconButton 
                            onClick={() => { setOrderId(null) }}
                            sx={{ p: '2px', mb: 1 }}
                        >
                            <Close sx={{ fontSize: '18px' }} />
                        </IconButton>
                    </Box>
                </Box>
                <Box>
                    <TableContainer
                        sx={{
                            height: '400px',
                            maxHeight: '400px',
                            overflowY: 'auto',
                            ...scrollStyle,
                            boxShadow: 4,
                            borderRadius: 1,
                        }}
                    >
                        <Table 
                            size="small" 
                            aria-label="purchases"
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow 
                                    sx={{
                                            '& > th': { 
                                                fontSize: '13px', 
                                                fontWeight: 'bold', 
                                                textAlign: 'center', 
                                                p: '2px 4px', 
                                                borderBottom: 1,
                                                borderRight: 1,
                                                borderRightColor: 'white',
                                                backgroundColor: 'secondary.light',
                                                color: 'white'
                                            } 
                                        }}
                                >
                                    <TableCell>№</TableCell>
                                    <TableCell width='200px' sx={{ textAlign: 'left !important' }}>Барааны нэр</TableCell>
                                    <TableCell width='130px' sx={{ textAlign: 'left !important' }}>Үнэ</TableCell>
                                    <TableCell width='90px'>Тоо ширхэг</TableCell>
                                    <TableCell width='130px' sx={{ textAlign: 'right !important' }}>Нийт үнэ (₮)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody 
                                sx={{ 
                                    '& > tr': { '&:hover': { backgroundColor: '#f8f8f8' } },
                                    '& > tr > td': { fontSize: '13px' }
                                }}
                            >
                                {orderItems?.map((item, idx) => {
                                    //const u = updated === item?.itemId
                                    return <TableRow 
                                                key={`${idx}`}
                                                sx={{
                                                    '& > td': { textAlign: 'center', p: '2px' },
                                                }}
                                            >
                                                <TableCell>{idx+1}</TableCell>
                                                <TableCell sx={{ textAlign: 'left !important' }}>{item?.itemName}</TableCell>
                                                <TableCell sx={{ textAlign: 'right !important', pr: '16px !important' }}>{toPrice(item?.itemPrice)}</TableCell>
                                                <TableCell sx={{ color: 'secondary.light' }}>{item?.itemQty}</TableCell>
                                                <TableCell sx={{ textAlign: 'right !important', pr: '16px !important' }}>{toPrice(item?.itemTotalPrice)}</TableCell>
                                            </TableRow>
                                        })}
                                <TableRow sx={{ '& > td': { fontWeight: 'bold', color: 'secondary.light' } }}>
                                    <TableCell></TableCell>
                                    <TableCell colSpan={2} align='right'>Нийт</TableCell>
                                    <TableCell align='center'>{totalCount}</TableCell>
                                    <TableCell align='right' sx={{ pr: '16px' }}>{toPrice(totalPrice)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </DialogContent>
        </Dialog>
    )
}