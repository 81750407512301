import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import { getToast } from '../../../auth/helpers/ToastAlert'
import { getOrderItems } from '../../../../api/order'
import { toPrice } from '../../../../utils/functions'
import { North, South } from '@mui/icons-material'
import { useState } from 'react'
import Items from './Items'

export default function Row(props) {
    const { row, idx, page, pageSize, expandeds, setExpandeds } = props
    const [order, setOrder] = useState(row)
    const [items, setItems] = useState(null)
    const [err, setErr] = useState(null)
    const errOff = () => { setErr(null) }
    
    const fetchItems = (orderId) => {
        getOrderItems(orderId)
            .then(res => {
                setItems({ id: orderId, items: res?.data })
            })
            .catch(e => {
                setErr('Түр хүлээгээд дахин оролдоно уу!')
            })
            .finally(() => {})
    }

    const handleItems = (orderId) => {
        setExpandeds(prev => [...prev, orderId])
        fetchItems(orderId)
    }
    const handleClose = (orderId) => { 
        setExpandeds(prev => prev.filter(id => id !== orderId))
        setItems(null) 
    }

    return (
        <>
            {getToast(Boolean(err), errOff, err, 'warning', 1500)}
            <TableRow 
                sx={{
                    backgroundColor: expandeds?.includes(row?.id) ? '#f1f1f1' : 'transparent',
                    '&:hover': { 
                        backgroundColor: '#e4e4e4', 
                        cursor: 'pointer'
                    }, 
                    '& > td': { textAlign: 'center', padding: '2px 4px !important', fontSize: '13.5px' }
                }}
            >
                <TableCell>{ page <= 1 ? idx + 1 : (page - 1) * pageSize + idx + 1 }</TableCell>
                <TableCell>
                    <Tooltip title='Дэлгэрэнгүй' placement='right'>
                        <IconButton sx={{ p: 0 }}>
                            {
                                order?.id === items?.id
                                    ?   <North sx={{ fontSize: '25px', borderRadius: '50%', p: '5px' }} onClick={() => { handleClose(order?.id) }} />
                                    :   <South sx={{ fontSize: '25px', borderRadius: '50%', p: '5px' }} onClick={()=> {handleItems(order?.id)}} />
                            }
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>{ order?.orderNo||'-' }</TableCell>
                <TableCell>{ toPrice(order?.totalPrice)||'-' }</TableCell>
                <TableCell>{ order?.totalCount||'-' }</TableCell>
                <TableCell>{ order?.status||'-' }</TableCell>
                <TableCell>{ order?.payType||'-' }</TableCell>
                <TableCell>{ order?.process||'-' }</TableCell>
                <TableCell>{ order?.branch||'Очиж авах' }</TableCell>
                <TableCell>{ order?.supplier||'-' }</TableCell>
                <TableCell>{ order?.createdOn||'-' }</TableCell>
            </TableRow>
            { items?.items?.length > 0 && <Items items={items?.items} expandedOrderId={items?.id} order={order} setOrder={setOrder} /> }
        </>
    )
}
