import { COMPANY_TYPES } from "../../../../../utils/constants"
import { TableCell, TableRow } from "@mui/material"

export default function Row({ idx, row, setCustomer, page, pageSize }) {
	return (
		<TableRow
			key={`${idx + 1}`}
			sx={{
				"&:last-child td, &:last-child th": { border: 0 },
				"& > td": {
					padding: "2px 6px",
					fontSize: "13px"
				},
				'&:hover': {
					backgroundColor: '#f1f1f1',
					cursor: 'pointer'
				},
			}}
			onClick={() => { setCustomer(row) }}
		>
			<TableCell>
				{page === 1 ? idx + 1 : page * pageSize + idx + 1}
			</TableCell>
			<TableCell align="left" sx={{ pl: '10px !important' }}>{row.email||'-'}</TableCell>
			<TableCell align="center">{row.phone||'-'}</TableCell>
			<TableCell align="left" sx={{ pl: '10px !important' }}>{row.name||'-'}</TableCell>
			<TableCell align="center">{row.rd||'-'}</TableCell>
			<TableCell align="center">{COMPANY_TYPES?.[row.cType]||'-'}</TableCell>
			<TableCell align="center">{row.lics?.length > 0 ? '+' : '-'}</TableCell>
			<TableCell align="center">{row.licenseExp||'-'}</TableCell>
			<TableCell align="center">{row.createdOn}</TableCell>
		</TableRow>
	)
}
