import { getCompanyBranding } from '../../../api/company'
import { drawerWidth } from '../../../utils/constants'
import { Divider, List } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import { useQuery } from 'react-query'
import StaffInfo from './StaffInfo'
import Menu from './Menu'

const DrawerBase = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      	'& .MuiDrawer-paper': {
			position: 'relative',
			whiteSpace: 'nowrap',
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			boxSizing: 'border-box',
			...(!open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				width: theme.spacing(4),
				[theme.breakpoints.up('sm')]: {
					width: theme.spacing(6),
				},
        	}),
      },
    }),
)

export default function Drawer({ tab, open, toggleDrawer, menus }) {
	//const [staffData, setStaffData] = useState(null)
	const { data, isFetched } = useQuery(["get_company_branding"], getCompanyBranding)
	const staffData = data?.data

	return (
		<DrawerBase variant="permanent" open={open}>
			<Toolbar
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					p: 0, 
					px: [1]
				}}
				variant='dense'
			>
				{
					Boolean(open && isFetched) &&
							<StaffInfo 
								toggleDrawer={toggleDrawer}
								staffData={staffData}
							/>
				}
				
			</Toolbar>
			<Divider />
			<List component="nav" sx={{ ml: 1 }}>
				<Menu 
					open={open} 
					tab={tab} 
					menus={menus} 
				/>
				<Divider sx={{ my: 1 }} />
			</List>
		</DrawerBase>
	)
}