import { Box, IconButton, Tooltip, useTheme } from '@mui/material'
import { apiUrl } from '../../../../../../../global/axiosBase'
import { Delete } from '@mui/icons-material'

const getLogoUrl = (logo) => {
    return logo
            ?   logo instanceof File
                    ?   URL.createObjectURL(logo)
                    :   typeof logo === 'string'
                        ?   `${apiUrl}${logo}`
                        :   ''
            :   ''
}

export default function Thumbnail({ logo, removeImg }) {
    const theme = useTheme()
    const handleRemove = (e) => { removeImg(); e.stopPropagation() }
    const handleImgOpen = (e) => { window.open(getLogoUrl(logo), '_blank'); e.stopPropagation() }

    return (
        <Box sx={{ position: 'relative', width: '80px', height: '80px' }}>
            <img
                src={getLogoUrl(logo)}
                style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '8px',
                    boxShadow: '4px 4px 4px grey'
                }}
                onClick={handleImgOpen}
            />
            <Tooltip title='Устгах' placement='right'>
                <IconButton sx={{ position: 'absolute', bottom: -5, right: -5, p: '2px', backgroundColor: 'white', '&:hover': { backgroundColor: 'white' } }} onClick={handleRemove}>
                    <Delete sx={{ color: theme.palette.primary.light, '&:hover': { color: theme.palette.secondary.light } }} />
                </IconButton>
            </Tooltip>
        </Box>
    )
}
