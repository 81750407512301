import { CheckCircleOutlineOutlined, HighlightOffOutlined } from "@mui/icons-material";

export default function TrueFalseCell({ value }) {
    return (
        <>
            {
                value
                    ? <CheckCircleOutlineOutlined color='success' sx={{ fontSize: '18px' }} />
                    : <HighlightOffOutlined color='error' sx={{ fontSize: '18px' }} />
            }
        </>
    )
}
