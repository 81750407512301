import { AppBar, Box, Container, CssBaseline, Divider, Drawer, Paper, Toolbar, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import LogOut from '../admin/AdminNav/LogOut'
import { boardHeight } from '../admin/utils'
import TabMenu from './TabMenu'

const drawerWidth = 240
const tabs = {
    0: { name: 'Бүртгэл', path: 'verification' },
    1: { name: 'Баталгаажуулах код', path: 'otp' },
    /* 2: { name: 'Мэдэгдэл', path: 'notif'} */
}

export default function Confirmation() {
    const [selIdx, setSelIdx] = useState(0)
    const navi = useNavigate()
    const handleNavi = (selIdx) => {
        setSelIdx(selIdx)
        navi(tabs[selIdx]?.path)
    }
    useEffect(() => { navi('verification') }, [])
    const r = useRef(null)

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position='fixed'
                sx={{
                    height: '30px',
                    width: `calc(100% - ${drawerWidth}px)`,
                    ml: `${drawerWidth}px`
                }}
                ref={r}
            >
                <Toolbar
                    sx={{
                        p: 0,
                        height: '30px',
                        minHeight: '30px'
                    }}
                    variant='dense'
                >
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <LogOut />
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth, 
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
                anchor='left'
                variant='permanent'
            >
                <Toolbar>
                    <img
                        src='/Pharmo.png'
                        width='40px'
                        height='40px'
                        style={{ marginRight: '10px' }}
                    />
                    <Typography variant='h6' noWrap component='div' sx={{ color: 'primary.main' }}>Pharmo</Typography>
                </Toolbar>
                <Divider />
                <TabMenu 
                    tabs={tabs}
                    selIdx={selIdx}
                    handleNavi={handleNavi}
                />
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ?	theme.palette.grey[100]
                            :	theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100%',
                    overflow: 'auto'
                }}
            >
                <Toolbar 
                    sx={{
                        height: '40px',
                        minHeight: '40px'
                    }}
                    variant='dense'
                />
                <Container maxWidth="100%">
                    <Paper 
                        sx={{ 
                            borderRadius: 1,
                            padding: '4px', 
                            height: boardHeight
                        }}
                    >
                        <Outlet />
                    </Paper>
                </Container>
            </Box>
        </Box> 
    )
}