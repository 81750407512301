import AuthCtxProvider from './context/AuthCtx.js'
import ReactDOM from 'react-dom/client'

ReactDOM.createRoot(document.getElementById('root')).render(<AuthCtxProvider />)

if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker.register('/serviceWorker.js')
			.then(registration => {
				console.log('w')
				//registration.unregister()
			})
			.catch(err => { console.log('w failed.') })
	})
}