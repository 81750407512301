import { MarkerClusterer } from '@googlemaps/markerclusterer'
import DeliveryDestination from './DeliveryDestination'
import { useEffect, useRef, useState } from 'react'
import { useMap } from '@vis.gl/react-google-maps'
import getDelmanColor from '../delmanColors'

export default function DeliveryDestinations({ destinations, showDestinations }) {
    const map = useMap()
    const dests = destinations?.filter(d => d?.lat && d?.lng)?.map(s => ({...s, color: getDelmanColor(s.delmans?.[0]?.id) }))

    // fit bound
    useEffect(() => {
        if (map && dests?.length > 0) {
            const bounds = new window.google.maps.LatLngBounds()
            dests?.forEach((dest) => { bounds.extend(new window.google.maps.LatLng(dest.lat, dest.lng)) })
            map.fitBounds(bounds)
        }
    }, [map, dests])

    // clusterer
    const [markers, setMarkers] = useState({})
    const clusterer = useRef(null)
    useEffect(() => {
        if (!map) return
        if (!clusterer.current) { clusterer.current = new MarkerClusterer({ map }) }
    }, [map])
    useEffect(() => {
        if (!map || !markers) return
        if (clusterer.current) {
            const curItemIds = dests?.map(d => d?.id)||[]
            const newMarkers = Object.fromEntries(Object.entries(markers).filter(([key, value]) => curItemIds.includes(key)))
            clusterer.current.clearMarkers()
            clusterer.current.addMarkers(Object.values(newMarkers))
        }
        if (!showDestinations) {
            clusterer.current.clearMarkers()
        }
    }, [markers, dests, showDestinations])

    const setMarkersRef = (marker, key) => {
        if (!marker||!key) return
        setMarkers((prev) => {
            if (marker) {
                return { ...prev, [key]: marker }
            } else {
                let newMarkers = { ...prev }
                delete newMarkers[key]
                return newMarkers
            }
        })
    }

    return (
        <>
            {
                dests?.map((d, i) =>(
                    <DeliveryDestination 
                        key={`_${d?.itemId}`}
                        destination={d}
                        setMarkersRef={setMarkersRef}
                        showDestinations={showDestinations}
                    />
                ))
            }
        </>
    )
}
