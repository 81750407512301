import { Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { isTokenValid, toPrice } from '../../../../../../../utils/functions'
import { getOrder } from '../../../../../../../api/order'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { keys } from 'underscore'
import OrderItem from './OrderItem'

export default function OrderDetail({ row, setRow, colsLength }) {
    const { role } = isTokenValid()
    const [srow, setSRow] = useState(row)
    const [updated, setUpdated] = useState(null)
    const { data, isRefetching } = useQuery(['get_order', srow?.id, true], getOrder, { enabled: role !== 'P' })
    useEffect(() => { if (keys(data?.data)?.length) { setSRow(data?.data) } }, [data?.data])
    const orderItems = srow?.items||[]
    
    useEffect(() => {
        if (updated && isRefetching === false) {
            setRow(data?.data)
            setSRow(data?.data)
            setTimeout(() => { setUpdated(null) }, 1500)
        }
    }, [updated, isRefetching])

    return (
        <TableRow>
            <TableCell colSpan={colsLength+2} sx={{ backgroundColor: '#f1f1f1', p: 0 }}>
                <Collapse in={true} timeout="auto" unmountOnExit>
                    <Table size="small" aria-label="purchases" sx={{ width: '800px', m: 0, mb: 2, ml: 5 }}>
                        <TableHead>
                            <TableRow sx={{ '& > th': { fontSize: '13px', fontWeight: 'bold', textAlign: 'center', p: '2px 4px', borderBottom: 1 } }}>
                                <TableCell rowSpan={2}>№</TableCell>
                                <TableCell width='250px' sx={{ textAlign: 'left !important' }} rowSpan={2}>Барааны нэр</TableCell>
                                <TableCell width='130px' rowSpan={2}>Үнэ</TableCell>
                                <TableCell rowSpan={role === 'P' ? 2 : 1} colSpan={2} sx={{ borderBottom: '1px solid #e0e0e0 !important' }}>Тоо ширхэг</TableCell>
                                <TableCell width='130px' rowSpan={2} sx={{ textAlign: 'right !important' }}>Нийт үнэ (₮)</TableCell>
                            </TableRow>
                            {
                                role !== 'P' && 
                                <TableRow sx={{ '& > th': { fontSize: '13px', fontWeight: 'bold', textAlign: 'center', p: '0px 4px', borderBottom: 1 } }}>
                                    <TableCell rowSpan={1}>Захиалсан</TableCell>
                                    <TableCell rowSpan={1} sx={{ color: 'secondary.light' }}>Эцсийн</TableCell>
                                </TableRow>
                            }
                        </TableHead>
                        <TableBody 
                            sx={{ 
                                '& > tr': { '&:hover': { backgroundColor: '#f8f8f8' } },
                                '& > tr > td': { fontSize: '13px' }
                            }}
                        >
                            {orderItems?.map((item, idx) => <OrderItem key={`${idx}`} idx={idx} item={item} role={role} />)}
                            <TableRow sx={{ '& > td': { fontWeight: 'bold', color: 'secondary.light' } }}>
                                <TableCell></TableCell>
                                <TableCell colSpan={3} align='right'>Нийт</TableCell>
                                <TableCell align='center'>{row?.totalCount}</TableCell>
                                <TableCell align='right' sx={{ pr: 0 }}>{toPrice(row?.totalPrice)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Collapse>
            </TableCell>
        </TableRow>
    )
}
