import { Alert, Snackbar } from '@mui/material'

export default function ToastAlert({ open, onClose, msg, severity, autoHideDuration = 2500, vertical = 'top', horizontal = 'center' }) {
    return (
        <Snackbar 
            open={open} 
            onClose={onClose} 
            autoHideDuration={autoHideDuration} 
            transitionDuration={{ appear: 2000, enter: 300, exit: 100 }} 
            anchorOrigin={{ vertical, horizontal }}
        >
            <Alert 
                severity={severity} 
                variant='filled' 
                sx={{ width: '100%' }} 
                autoHideDuration={autoHideDuration} 
                onClose={onClose}
            >{msg}</Alert>
        </Snackbar>
    )
}

export const getToast = ( open, onClose, msg, severity, autoHideDuration, vertical, horizontal ) => {
    return <ToastAlert 
                open={open} 
                onClose={onClose} 
                msg={msg}
                severity={severity}
                autoHideDuration={autoHideDuration} 
                vertical={vertical}
                horizontal={horizontal}
            />
}