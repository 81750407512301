import { Avatar, Box, IconButton, Typography } from '@mui/material'
import { AccountCircle, ChevronLeft } from '@mui/icons-material'
import { isTokenValid } from '../../../../utils/functions'
import { apiUrl } from '../../../../global/axiosBase'
import { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router'

const roles = {
	A: 'Админ',
	S: 'Борлуулагч',
	D: 'Түгээгч',
	B: 'Захиалгын менежер',
	P: 'Бэлтгэгч'
}

export default function StaffInfo({ toggleDrawer, staffData }) {
    const { role } = isTokenValid()
    const navi = useNavigate()
    const goToSettings = () => { navi('settings') }
    const theme = useTheme()
    const [cmpLogo, setCmpLogo] = useState(staffData?.logo ? apiUrl + staffData?.logo : '')
    useEffect(() => {
        window.addEventListener(
            'logoUpdated', 
            (e) => {
                console.log("logo updated: ", e)
                if (e?.detail?.logo) {
                    setCmpLogo(`${apiUrl}${e.detail.logo}`)
                }
            }
        )
        return () => { window.removeEventListener('logoUpdated', () => {}) }
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                my: 1,
                position: 'relative',
                alignItems: 'center', 
                justifyContent: 'center',
                '&:hover': { cursor: 'pointer' }
            }}
            onClick={goToSettings}
        >
            <IconButton
                onClick={toggleDrawer} 
                sx={{
                    ml: '2px', 
                    p: '2px',
                    position: 'absolute',
                    top: 0,
                    right: 0
                }}
            >
                <ChevronLeft />
            </IconButton>
            {
                cmpLogo
                    ?   <Avatar
                            alt={`${staffData?.name}`}
                            src={cmpLogo}
                            sx={{ width: 50, height: 50, border: 1, mr: 1, borderColor: '#999' }}
                        />
                    :   <AccountCircle sx={{ color: theme.palette.primary, fontSize: '50px', mr: 1 }} />
            }
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                    '&:hover': { cursor: 'pointer' }
                }}
            >
                <Typography sx={{ color: 'primary.light', textAlign: 'center', fontSize: '14px', fontWeight: 600, lineHeight: 1, mb: '2px' }}>
                    {staffData?.name||''}
                </Typography>
                <Typography sx={{ color: 'secondary.light', textAlign: 'center', fontSize: '12px', fontWeight: 600, lineHeight: 1, fontStyle: 'italic' }}>
                    {`${roles?.[role]}`||''}
                </Typography>
                <Typography sx={{ color: '#666', textAlign: 'center', fontSize: '12px', fontWeight: 600, fontStyle: 'italic' }}>
                    {staffData?.staff_email||''}
                </Typography>
            </Box>
        </Box>
    )
}
