import { useMap } from '@vis.gl/react-google-maps'
import getDelmanColor from '../delmanColors'
import { useEffect, useState } from 'react'

export default function DeliveryRoutes({ routes, showRoute }) {
    const map = useMap()
    const [lines, setLines] = useState([])

    const clearLines = () => { if (lines?.length > 0) { lines?.map(l => l.setMap(null)) } }
    useEffect(() => {
        if (!showRoute) {
            clearLines()
            setLines([])
        }
    }, [showRoute])

    useEffect(() => {
        if (map && showRoute) {
            clearLines()
            for (let r of routes) {
                const line = new window.google.maps.Polyline({
                    path: r?.route,
                    geodesic: true,
                    strokeColor: getDelmanColor(r.delmanId),
                    strokeOpacity: 1.0,
                    strokeWeight: 6
                })
                line.setMap(map)
                setLines(prev => [...prev, line])
            }
        }
        return () => { clearLines() }
    }, [map, routes])
    return null
}
