import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'

export default function BranchItem({ selectBranch, branchId, branch, idx }) {
    return (
        <ListItem
            key={`${idx}`}
            onClick={() => selectBranch(branch)}
            sx={{
                '&:hover': { 
                    bgcolor: '#efefef',
                    cursor: 'pointer' 
                },
                borderBottom: '1px solid #efefef', 
                p: '2px 6px'
            }}
        >
            <ListItemIcon sx={{ mr: 0 }}>
                { 
                    branch.id === branchId
                        ?   <RadioButtonChecked sx={{ color: 'primary.main', fontSize: '16px' }} />
                        :   <RadioButtonUnchecked sx={{ fontSize: '16px' }} />
                }
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography 
                        color='primary.main'
                        fontSize='14px' 
                        textAlign={'left'}
                        sx={{ color: '#555' }}
                    >{branch.name||'-'}</Typography>
                }
            />
        </ListItem>
    )
}
