import { Box, Button, Grid, Typography } from "@mui/material"

export default function InfoBox({ children, label, handleUpdate }) {
    return (
        <Box
            sx={{
                border: 1, 
                borderRadius: 1, 
                p: 2, 
                my: 3, 
                borderColor: '#666',
                position: 'relative'
            }}
        >
            <Typography
                sx={{
                    mb: 2,
                    p: '0px 12px',
                    fontSize: '15px',
                    color: '#666',
                    border: 1,
                    borderRadius: 1,
                    backgroundColor: 'white',
                    width: 'fit-content',
                    position: 'absolute',
                    top: -10,
                }}
            >{label}</Typography>
            <Grid container spacing={1} sx={{ mt: 2, mb: 1 }}>
                <>
                    {children}
                </>
            </Grid>
            <Button
                size='small'
                variant='contained'
                sx={{ p: '1px 6px' }}
                onClick={handleUpdate}
            >Шинэчлэх</Button>
        </Box>
    )
}
