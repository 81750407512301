import { isTokenValid } from '../../../../../../utils/functions'
import { TableCell, TableRow } from '@mui/material'
import DropCell from './DropCell'

export default function FilterRow({ filters, setFilters, delmanNames, zones }) {
    const { role } = isTokenValid()
    const dc = delmanNames?.reduce((acc, d) => { acc[d.id] = d.name; return acc }, {})
    const delmanChoices = { ...dc, '-1': 'Бүгд' }
    //const zc = zones?.reduce((acc, z) => { acc[z.id] = z.name; return acc }, {})
    //const zoneChoices = { ...zc, '-1': 'Бүгд' }

    return (
        <TableRow
            sx={{
                '& > td': { p: '2px 4px' }
            }}
        >
            <TableCell colSpan={2} />
            {
                role === 'D'
                    ?   <TableCell />
                    :   <DropCell
                            field='delman'
                            choices={delmanChoices}
                            filters={filters}
                            setFilters={setFilters}
                        />
            }
            <TableCell colSpan={9}/>
            {/* <DropCell
                field='zone'
                choices={zoneChoices}
                filters={filters}
                setFilters={setFilters}
            /> */}
        </TableRow>
    )
}
