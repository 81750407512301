import { getDelvsCnt, getDeliveryZones, getCurrentDeliveries, getDelmanCurrentDeliveries, getDelmanNames } from '../../../../api/delivery'
import { createContext, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { getToast } from '../../../auth/helpers/ToastAlert'
import { isTokenValid } from '../../../../utils/functions'
import CurrentDelivery from './CurrentDelivery'
import DeliveryHistory from './DeliveryHistory'
import { Box, Tab, Tabs } from '@mui/material'
import { useQuery } from 'react-query'
import Allocation from './Allocation'

export const DELIVERY_ORDER_PROCESS = {
    'P': 'Бэлэн болсон',
    'D': 'Хүргэгдсэн',
    'C': 'Хаалттай байсан',
    'R': 'Буцаасан',
    'O': 'Түгээлтэнд гарсан',
    'A': 'Хүлээн авсан',
}

const DeliveryCtx = createContext()
export const useDeliveryCtx = () => useContext(DeliveryCtx)

export default function Delivery() {
    const { role } = isTokenValid()
    const isDelman = role === 'D'
    const [tab, setTab] = useState(isDelman ? 1 : 0)
    const [isTab1, setIsTab1] = useState(false)
    const handleTabChange = (e, v) => {
        setTab(v)
        if (v === 0) { setIsTab1(true) }
    }

    const [staffs, setStaffs] = useState([])
    const delmanNames = staffs?.map(s => ({ id: s.id, name: s.name }))
    useEffect(() => {
        getDelmanNames()
            .then(res => { if (res?.data) { setStaffs(res.data) } })
            .catch(err => { console.log("get delmans ERR: ", err) })
    }, [])

    // msg
    const [msg, setMsg] = useState(null)
	const msgOff = () => { setMsg(null) }
    const successMsg = () => { setMsg({ m: 'Амжилттай!', s: 'success' }) }
    const waitMsg = () => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) }

    const tabRef = useRef()
    const tabHeight = tabRef?.current?.getBoundingClientRect()?.height||0

    // deliveries
    const { data: delvsData, refetch: refetchDelvs, isFetching: isDelvsFetching } = useQuery('get_current_deliveries', isDelman ? getDelmanCurrentDeliveries : getCurrentDeliveries, { refetchOnWindowFocus: true })
    const allOrders = delvsData?.data?.map(d => d.orders?.map(o => ({...o, delman: d.delman, delivery: d }))).flat()
    const deliveries = delvsData?.data||[]

    // zones
    const [zones, setZones] = useState([])
    const zoneData = zones?.map(z => ({ id: z.id, name: z.name, delman: delmanNames.find(d => d.id === z.delman_id) }))
    const fetchZones = () => {
        getDeliveryZones()
            .then(res => { setZones(res?.data || []) })
            .catch(e => { waitMsg() })
    }
    useLayoutEffect(() => { fetchZones() }, [])

    const { data: delvsCntData, refetch: getDelvsCntRefetch } = useQuery('get_current_deliveries_cnt', getDelvsCnt, { refetchOnWindowFocus: true, enabled: !isDelman })
    const delvsCnt = delvsCntData?.data?.delivery_count||0

    return (
        <DeliveryCtx.Provider
            value={{
                allOrders,
                deliveries,
                refetchDelvs,
                isDelvsFetching,
                zoneData,
                zones,
                fetchZones,
                delmanNames, 
                //
                isDelman, 
                msg, 
                setMsg,
                successMsg,
                waitMsg,
                delvsCnt,
                getDelvsCntRefetch,
                setTab,
                isTab1
            }}
        >
            {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 2000)}
            <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label='tabs'
                ref={tabRef}
                sx={{
                    minHeight: '30px',
                    height: '30px',
                    '& .MuiTab-root': { minHeight: '30px', height: '30px' }
                }}
            >
                {!isDelman && <Tab label={'Хуваарилалт'} value={0} />}
                <Tab label={!isDelman ? delvsCnt ? `Өнөөдрийн түгээлт (${delvsCnt})` : 'Түгээлт' : 'Түгээлт'} value={1} />
                <Tab label={'Түгээлтийн түүх'} value={2} />
            </Tabs>
            <Box sx={{ height: `calc(100% - ${tabHeight}px)` }}>
                { tab === 0 && <Allocation /> }
                { tab === 1 && <CurrentDelivery /> }
                { tab === 2 && <DeliveryHistory /> }
            </Box>
        </DeliveryCtx.Provider>
    )
}
