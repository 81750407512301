import { Box, Checkbox, FormControlLabel, MenuItem, Select, Typography } from '@mui/material'
import { LocationOff } from '@mui/icons-material'
import getDelmanColor from '../delmanColors'

const controls = [
    { id: 1, name: 'Түгээгчийн явсан зам' },
    { id: 2, name: 'Түгээх цэгүүд' },
    { id: 3, name: 'Нэмэлт хүргэлтүүд' },
    { id: 4, name: 'Замын түгжрэл харах' },
]

export default function ControlPanelBox({ delmans, selectedDeliveryId, setSelectedDeliveryId, checked, setChecked }) {
    const handleCheck = (id) => { 
        setChecked(prev => {
            if (prev.includes(id)) return prev.filter(p => p !== id)
            return [...prev, id]
        })
    }

    const handleChange = (e) => { setSelectedDeliveryId(e.target.value) }

    return (
        <Box 
            sx={{
                boxShadow: '0 2px 10px #000',
                backgroundColor: 'white', 
                borderRadius: '5px',
                p: '10px',
                m: 1
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                {
                    controls.map(c => (
                        <FormControlLabel
                            key={c.id}
                            control={
                                <Checkbox
                                    checked={checked?.includes(c.id)}
                                    onChange={() => handleCheck(c.id)}
                                    size='small'
                                    sx={{ p: '2px' }}
                                />
                            }
                            label={<Typography sx={{ fontSize: '13px' }}>{c.name}</Typography>}
                        />
                    ))
                }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 1, ml: 1 }}>
                <Typography sx={{ fontSize: '13px', mr: 1 }}>Түгээгч сонгох:</Typography>
                <Select
                    id="demo-simple-select"
                    value={selectedDeliveryId||0}
                    onChange={handleChange}
                    displayEmpty
                    sx={{ '& .MuiSelect-select': { padding: '1px', width: 'fit-content', ml: 1 } }}
                >
                    <MenuItem value={0}>
                        <Typography sx={{ fontSize: '13px' }}>Бүгд</Typography>
                    </MenuItem>
                    {
                        delmans?.map(d => (
                            <MenuItem 
                                key={d.id} 
                                value={d.delivery_id}
                                sx={{
                                    width: 'fit-content',
                                }}
                            >
                                <Typography 
                                    sx={{ 
                                        fontSize: '13px',
                                        color: 'white',
                                        backgroundColor: getDelmanColor(d.id),
                                        p: '2px 6px',
                                        borderRadius: 1,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    {d.name}
                                    {
                                        (!d.lat||!d.lng) && <LocationOff sx={{ fontSize: '20px', color: 'white', ml: 1 }} />
                                    }
                                </Typography>
                            </MenuItem>
                        ))
                    }
                </Select>
            </Box>
        </Box>
    )
}

