import { Dialog, Paper, Slide } from '@mui/material'
import { getToast } from '../../helpers/ToastAlert'
import CheckRegistration from './CheckRegistration'
import RegisterLicense from '../../RegisterLicense'
import PharmoLogo from '../../../common/PharmoLogo'
import CreatePwd from '../../PwdReset/CreatePwd'
import { forwardRef, useState } from 'react'
import { isEmpty } from 'underscore'
import Box from '@mui/material/Box'

export const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

// Main component
export default function SignIn() {
	const [email, setEmail] = useState('')
	const [p, setP] = useState(null)

	const [badEmail, setBadEmail] = useState(null)
	const [noPwd, setNoPwd] = useState(null)

	//const [incomplete, setIncomplete] = useState(null)
	const [registerAddress, setRegisterAddress] = useState(null)
	const [registerLocation, setRegisterLocation] = useState(null)
	const [registerLicense, setRegisterLicense] = useState(null)

	const [alert, setAlert] = useState(null)
	const alertOff = () => { setAlert(null) }
	const alertSuccess = () => { setAlert({ m: 'Амжилттай!', s: 'success' }) }

	const restart = () => {
		setNoPwd(null)
		//setIncomplete(null)
		setRegisterAddress(null)
		setRegisterLicense(null)
		setRegisterLocation(null)
	}

	const toLogin = badEmail === false && noPwd === false

	const open = noPwd||registerAddress||registerLicense||registerLocation//||incomplete

	return (
		<>
			<Box
				sx={{ 
					display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    minHeight: '500px',
                    minWidth: '300px',
                    width: '450px',
                    p: 2,
					position: 'relative',
				}}
				component={Paper}
				elevation={5}
			>
				<Box sx={{ position: 'absolute', top: 0, right: 0 }}>
					<PharmoLogo />
				</Box>
				<CheckRegistration
					email={email}
					setEmail={setEmail}
					setBadEmail={setBadEmail}
					setNoPwd={setNoPwd}
					toLogin={toLogin}
					//setIncomplete={setIncomplete}
					setRegisterAddress={setRegisterAddress}
					setRegisterLocation={setRegisterLocation}
					setRegisterLicense={setRegisterLicense}
					setAlert={setAlert}
					restart={restart}
					setP={setP}
				/>
			</Box>
			{getToast(!isEmpty(alert), alertOff, alert?.m, alert?.s, 2500 )}
			<Dialog 
				open={open}
				onClose={restart}
				TransitionComponent={Transition}
				maxWidth='md'
			>
				{noPwd && <CreatePwd email={email} alertSuccess={alertSuccess} handleClose={restart} />}
				{registerLicense && <RegisterLicense alertSuccess={alertSuccess} handleClose={restart} email={email} p={p} setAlert={setAlert} />}
			</Dialog>
		</>
	)
}