import { Box, Button, Dialog, IconButton, Typography } from "@mui/material"
import { Close } from "@mui/icons-material"
import { useState } from "react"

export default function VerifiedDeleteIcon({ verifyText, onYes = null, onClose = null }) {
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        if (onClose) { onClose() }
        setOpen(false)
    }
    const handleYes = () => {
        if (onYes) { onYes() }
        setOpen(false)
    }

    return (
        <>
            <IconButton
                sx={{ p: 0 }}
                onClick={() => setOpen(true)}
            >
                <Close sx={{ fontSize: '18px' }} />
            </IconButton>
            <Dialog 
                open={open}
                onClose={handleClose}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 3
                    }}
                >
                    <Typography>{verifyText}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button
                            variant="contained"
                            sx={{ mr: 3 }}
                            onClick={handleYes}
                            size='small'
                        >
                            Тийм
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            size='small'
                        >
                            Үгүй
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}
