import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import { deleteSupplierStaff } from '../../../../../api/staff'
import { scrollStyle } from '../../../../../utils/constants'
import { SUPPLIER_STAFF_ROLES } from '../utils'
import DeleteIconBtn from './DeleteIconBtn'
import { Edit } from '@mui/icons-material'
import { sortBy } from 'underscore'
import { useState } from 'react'

export default function StaffsList({ staffs, fetchStaffs, successMsg, waitMsg, handleEdit }) {
    const stfs = sortBy(staffs, function(s){return Math.max(s.id)})

    const deleteStaffInfo = (staffId) => {
        deleteSupplierStaff(staffId)
            .then(_ => { successMsg(); fetchStaffs(); })
            .catch(e => { 
                //console.log("e: ", e)
                waitMsg()
            })
    }

    // row hover
    const [hover, setHover] = useState(false)

    return (
        <TableContainer
            sx={{
                boxShadow: 3,
                height: `100%`,
                ...scrollStyle,
                '&::-webkit-scrollbar': { 
                    width: 8,
                    height: 8
                }
            }}
        >
            <Table stickyHeader aria-label="sticky staff table">
                <TableHead>
                    <TableRow 
                        sx={{
                            color: 'white',
                            '& > th': { 
                                p: '4px 6px', 
                                borderRight: 1, 
                                borderColor: 'white', 
                                backgroundColor: 'secondary.light'
                            }
                        }}
                    >
                        <TableCell sx={{ color: 'white' }}>№</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Овог</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Нэр</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Регистр</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Имэйл хаяг</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Утасны дугаар</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Ажил үүрэг</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Засах</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Устгах</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { stfs?.map((s, idx) =>
                    <TableRow 
                        key={`Row-${idx}`} sx={{ '& > td': { p: '4px 8px' }, '&:hover': { backgroundColor: '#f1f1f1' } }}
                        onMouseEnter={() => {setHover(idx)}}
                        onMouseLeave={() => {setHover(null)}}
                    >
                        <TableCell width='20px'>{idx + 1}</TableCell>
                        <TableCell align='left'>{s.last_name}</TableCell>
                        <TableCell align='left'>{s.name}</TableCell>
                        <TableCell align='center'>{s.rd}</TableCell>
                        <TableCell align='left'>{s.email}</TableCell>
                        <TableCell align='center'>{s.phone}</TableCell>
                        <TableCell align='left'>{SUPPLIER_STAFF_ROLES?.[s.role]||'-'}</TableCell>
                        <TableCell align='center' width='60px'>
                            <Tooltip title='Засах' placement='top-start'>
                                <IconButton 
                                    onClick={() => handleEdit(s) }
                                    sx={{ 
                                        visibility: idx === hover ? 'visible' : 'hidden', 
                                        '&:hover': { color: 'secondary.light' }, 
                                        transition: 'all 0.1s linear',
                                        p: '2px'
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell align='center' width='60px'>
                            <DeleteIconBtn onYes={() => { deleteStaffInfo(s?.id) }} idx={idx} hoverId={hover} />
                        </TableCell>
                    </TableRow>) }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
