import CustomersToVerify from '../CustomerVerification/CustomersToVerify'
import { getToast } from '../../auth/helpers/ToastAlert'
import PharmoCustomers from './PharmoCustomers'
import { Box, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'

export default function CustomerVerification() {
    const [tab, setTab] = useState(0)

    const [customer, setCustomer] = useState(null)

    const [open, setOpen] = useState(false)
    const handleDialogClose = () => { setOpen(false) }
    useEffect(() => {
        if (!open) { setCustomer(null) }
    }, [open])

    const [msg, setMsg] = useState(null)
    const msgOff = () => { 
        setMsg(null)
        handleDialogClose()
    }
    const waitMsg = () => {setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })}
    const successMsg = () => { setMsg({ m: 'Амжилттай!', s: 'success' }) }

    useEffect(() => {
        if (customer?.id) { setOpen(true) }
    }, [customer])

    return (
        <>
            <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)}
                aria-label='tabs'
                sx={{
                    minHeight: '30px', 
                    height: '30px',
                    '& .MuiTab-root': { minHeight: '30px', height: '30px' }
                }}
            >
                <Tab label={'Бүртгүүлэх хүсэлт'} value={0} />
                <Tab label={'Бүртгэлтэй хэрэглэгчид'} value={1} />
                {/* <Tab label={'Имэйл баталгаажаагүй'} value={2} /> */}
            </Tabs>
            <Box sx={{ height: '100%' }}>
                {tab === 0 && 
                    <CustomersToVerify
                        setMsg={setMsg}
                        waitMsg={waitMsg}
                        successMsg={successMsg}
                    />
                }
                {tab === 1 && 
                    <PharmoCustomers
                        setMsg={setMsg}
                        waitMsg={waitMsg}
                        successMsg={successMsg}
                    />
                }
            </Box>
            {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s)}
        </>
    )
}