import { getFilterParams, getPageParam } from "./utils"
import AxiosWithToken from "../global/axiosBase"

// delivery zone apis
export const addUpdateDeliveryZone = (data) => {
    return AxiosWithToken.post('delivery/zone/', data)
}

export const removeDeliveryZone = (zoneId) => {
    return AxiosWithToken.delete('delivery/zone/?zone_id=' + zoneId)
}

export const getDeliveryZones = () => {
    return AxiosWithToken.get('delivery/zone/')
}

export const updateCustomerZone = (data) => {
    return AxiosWithToken.patch('delivery/customer_zone/', data)
}

export const updateDeliveryZoneDelman = (data) => {
    return AxiosWithToken.patch('delivery/delman_zone/', data)
}

// delivery
export const getOrdersToDeliver = () => {
    return AxiosWithToken.get('delivery/allocation/')
}

export const createDelivery = (data) => {
    return AxiosWithToken.post('delivery/current/', data)
}

export const removeOrderFromDelivery = (data) => {
    return AxiosWithToken.patch('delivery/remove_order/', data)
}

export const deleteDelivery = (deliveryId) => {
    return AxiosWithToken.delete('delivery/current/?delivery_id=' + deliveryId)
}

export const getCurrentDeliveries = () => {
    return AxiosWithToken.get('delivery/current/')
}

export const getDelvsCnt = () => {
    return AxiosWithToken.get('delivery/current/?count=true')
}

// for delman
export const getDelmanCurrentDeliveries = () => {
    return AxiosWithToken.get('delivery/delman_active/')
}

export const startDelivery = (data) => {
    return AxiosWithToken.patch('delivery/start/', data)
}

export const updateDeliveryLocation = (data) => {
    return AxiosWithToken.patch('delivery/location/', data)
}

export const updateDeliveryOrderStatus = (data) => {
    return AxiosWithToken.patch('delivery/order/', data)
}

export const endDelivery = (data) => {
    return AxiosWithToken.patch('delivery/end/', data)
}

export const getDeliveryOrderDetail = ({ queryKey }) => {
    const [, orderId] = queryKey
    return AxiosWithToken.get('delivery/order_detail/?order_id=' + orderId)
}

export const getDeliveryOrdererDetail = ({ queryKey }) => {
    const [, orderId] = queryKey
    return AxiosWithToken.get('delivery/orderer/?orderer_id=' + orderId)
}

// get delivery zones
export const getSupplierDeliveryZones = () => {
    return AxiosWithToken.get('seller/get_delivery_zones/')
}

// get all customers list for delivery zone selection
export const getAllCustomersForDeliveryZone = ({ queryKey }) => {
    const [_, page, pageSize, custGroup, custZone] = queryKey
    let url = 'all_customers/?page=' + page + '&page_size=' + pageSize
    if (custGroup) { url+=`&cust_group=${custGroup}` }
    if (custZone >= 0) { url+=`&cust_zone=${custZone}` }
    return AxiosWithToken.get(url)
}

// location and routes
export const getCurrentDeliveryLocations = ({ queryKey }) => {
    const [, withRoutes] = queryKey
    if (withRoutes) {
        return AxiosWithToken.get('delivery/locations/?with_routes=true')
    }
    return AxiosWithToken.get('delivery/locations/')
}

// delivery history
export const getDeliveryHistory = ({ queryKey }) => {
    const [_, page, pageSize, filters ] = queryKey
    let url = 'delivery/history/'
    url = getPageParam(page, pageSize, url)
    url = getFilterParams(filters, url)
    return AxiosWithToken.get(url)
}

export const getDelmanNames = () => {
    return AxiosWithToken.get('delivery/delmans/')
}