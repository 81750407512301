import CustomerVerification from "../../components/confirmation/CustomerVerification"
import Otp from "../../components/confirmation/Otp"

export const confirmRoutes = [
    {
        path: 'verification',
        element: <CustomerVerification />
    },
    {
        path: 'otp',
        element: <Otp />
    },
    /* {
        path: 'notif',
        element: <Notif />
    }, */
]