import { Box, Typography } from "@mui/material";

export default function DelmanLabel({ delman, progress, color }) {
	const labelTxt = progress ? `${delman} (${progress})` : delman
	return (
		<Box
			sx={{
				position: 'absolute',
				top: '10px', right: '10px',
				transform: 'translate(100%, -100%)',
				borderRadius: '5px',
				border: '1px solid #ccc',
				backgroundColor: `${color}`,
				color: 'white',
				boxShadow: '0 2px 10px #000',
				width: 'fit-content',
				p: '1px 5px',
			}}
		>
			<Typography sx={{ fontSize: '13px', p: 0 }} noWrap>{labelTxt}</Typography>
		</Box>
	)
}
