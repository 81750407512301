import AxiosWithToken from "../global/axiosBase"

const getFilters = (url, filters) => {
    for (let f in filters) {
        if (filters[f] === null) continue
        url += url.endsWith('/') ? `?${f}=${filters[f]}` : `&${f}=${filters[f]}`
    }
    return url
}

export const searchProductByNameOrBarcode = ({ queryKey }) => {
    const [ _, k, v, page, pageSize ] = queryKey
    let url = `sales/report/search_product/?${k}=${v}&page=${page}&page_size=${pageSize}`
    return AxiosWithToken.get(url)
}

export const getTotalSales = ({ queryKey }) => {
    const [ _, filters, monthOrQuarter ] = queryKey
    let url = monthOrQuarter === 'month' ? 'sales/report/by_month/' : 'sales/report/by_quarter/'
    url = getFilters(url, filters)
    return AxiosWithToken.get(url)
}

export const getSalesByCustomer = ({ queryKey }) => {
    const [ _, filters, monthOrQuarter ] = queryKey
    let url = getFilters('sales/report/by_customer/', filters)
    return AxiosWithToken.get(url)

}

export const getSalesByPharmoUser = ({ queryKey }) => {
    const [ _, filters, monthOrQuarter ] = queryKey
    let url = getFilters('sales/report/by_pharmo_user/', filters)
    return AxiosWithToken.get(url)
}

export const getSalesBySeller = ({ queryKey }) => {
    const [ _, filters, monthOrQuarter ] = queryKey
    let url = getFilters('sales/report/by_seller/', filters)
    return AxiosWithToken.get(url)
}

export const getSalesByProducts = ({ queryKey }) => {
    const [ _, filters, monthOrQuarter ] = queryKey
    let url = getFilters('sales/report/by_products/', filters)
    return AxiosWithToken.get(url)
}

export const getSalesByProduct = ({ queryKey }) => {
    const [ _, filters ] = queryKey
    let url = getFilters('sales/report/by_product/', filters)
    return AxiosWithToken.get(url)
}
