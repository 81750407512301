import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { useEffect, useRef, useState } from 'react'
import { useMap } from '@vis.gl/react-google-maps'
import getDelmanColor from '../delmanColors'
import DeliveryItem from './DeliveryItem'

export default function DeliveryItems({ deliveryItems, showDeliveryItems }) {
    const map = useMap()
    const items = deliveryItems?.map(s => ({ ...s, color: getDelmanColor(s.delman?.id) }))

    // fit bound
    useEffect(() => {
        if (map && items?.length > 0) {
            const bounds = new window.google.maps.LatLngBounds()
            items?.forEach((dest) => { bounds.extend(new window.google.maps.LatLng(dest.lat, dest.lng)) })
            map.fitBounds(bounds)
        }
    }, [map, items])

    // clusterer
    const [markers, setMarkers] = useState({})
    const clusterer = useRef(null)
    useEffect(() => {
        if (!map) return
        if (!clusterer.current) { clusterer.current = new MarkerClusterer({ map }) }
    }, [map])
    useEffect(() => {
        if (!map || !markers) return
        if (showDeliveryItems) {
            if (clusterer.current) {
                const curItemIds = items?.map(d => d?.id)||[]
                const newMarkers = Object.fromEntries(Object.entries(markers).filter(([key, value]) => curItemIds.includes(key)))
                clusterer.current.clearMarkers()
                clusterer.current.addMarkers(Object.values(newMarkers))
            }
        } else {
            clusterer.current.clearMarkers()
        }
    }, [markers, items, showDeliveryItems])
    //console.log("MARK: ", clusterer.current)

    const setMarkersRef = (marker, key) => {
        if (!marker||!key) return
        setMarkers((prev) => {
            if (marker) {
                return { ...prev, [key]: marker }
            } else {
                let newMarkers = { ...prev }
                delete newMarkers[key]
                return newMarkers
            }
        })
    }

    return (
        <>
            {
                items?.map((d, i) =>(
                    <DeliveryItem 
                        key={`_${d?.itemId}`}
                        item={d}
                        setMarkersRef={setMarkersRef}
                        showDeliveryItems={showDeliveryItems}
                    />
                ))
            }
        </>
    )
}
