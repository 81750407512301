import AxiosWithToken from "../global/axiosBase"
import { getPageParam } from "./utils"

export const getSupplierStaffs = ({ queryKey }) => {
    const [_, page, pageSize, role, ordering] = queryKey
    let url = 'supplier/staff/'
    url = getPageParam(page, pageSize, url)
    if (role) { url += `&role=${role}` }
    if (ordering) { url += `&ordering=${ordering}` }
    return AxiosWithToken.get(url)
}

export const addSupplierStaff = (data) => {
    return AxiosWithToken.post('supplier/staff/', data)
}

export const updateSupplierStaff = (data) => {
    return AxiosWithToken.patch('supplier/staff/', data)
}

export const deleteSupplierStaff = (staffId) => {
    return AxiosWithToken.delete(`supplier/staff/?staff_id=${staffId}`)
}

export const addCustomer = (cust) => {
    return AxiosWithToken.post('customer/register/', cust)
}

// get supplier all staffs' names and roles
export const getSupplierStaffsNamesRoles = () => {
    return AxiosWithToken.get(`supplier/staffs/`)
}

// select delman
export const selectDelman = ({ orderIds, delmanId }) => {
    return AxiosWithToken.patch('select_delman/', { orderIds, delmanId })
}

// select packer
export const selectPacker = (data) => {
    return AxiosWithToken.patch('select_packer/', data)
}

// for Packer
// change order process
export const changeOrderProcess = ({ oId, process }) => {
    return AxiosWithToken.patch('packer/', { oId, process })
}