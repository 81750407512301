import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import CustomersTablePages from './CustomersTablePages'

export default function CustomersTable({ customers, zones, selected, setSelected, bodyWidth, count, page, setPage, pageSize }) {
    const handleCheck = (id) => {
        if (selected.includes(id)) {
            setSelected(selected.filter(s => s !== id))
        } else {
            setSelected([...selected, id])
        }
    }

    return (
        <TableContainer
            sx={{
                boxShadow: 5,
                width: bodyWidth,
                maxHeight: 'calc(100% - 140px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': { width: '6px' },
                '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.light' },
                '&::-webkit-scrollbar-thumb:hover': { backgroundColor: 'secondary.main' },
            }}
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow
                        sx={{
                            '& > th': { 
                                backgroundColor: 'secondary.light', 
                                color: 'white',
                                p: '2px 4px',
                                borderRight: '1px solid white',
                            }
                        }}
                    >
                        <TableCell>
                            <Checkbox
                                checked={selected?.length === customers?.length}
                                onChange={() => { setSelected(selected.length === customers.length ? [] : customers.map(c => c.id)) }}
                                inputProps={{ 'aria-label': 'select all customers' }}
                                size='small'
                                sx={{
                                    color: 'white',
                                    p: '2px',
                                    '&.Mui-checked': {
                                        color: 'white',
                                    },
                                }}
                            />
                        </TableCell>
                        <TableCell>№</TableCell>
                        <TableCell sx={{ pl: '10px !important' }}>Харилцагчийн нэр</TableCell>
                        <TableCell align='center'>Регистр</TableCell>
                        <TableCell>Бүс</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        customers?.length > 0
                            ?   customers?.map((c, i) => (
                                    <TableRow 
                                        key={c.id}
                                        sx={{
                                            '& > td': { p: '2px 6px' },
                                            '&:hover': { backgroundColor: '#f1f1f1', cursor: 'pointer' },
                                            '&:nth-of-type(odd)': { backgroundColor: '#f1f1f1' }
                                        }}
                                        onClick={() => { handleCheck(c.id) }}
                                    >
                                        <TableCell>
                                            <Checkbox
                                                checked={selected?.includes(c.id)}
                                                onChange={() => { handleCheck(c.id) }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                size='small'
                                                sx={{ p: '2px' }}
                                            />
                                        </TableCell>
                                        <TableCell>{page===1 ? i+1 : pageSize*(page-1)+i+1}</TableCell>
                                        <TableCell sx={{ pl: '10px !important' }}>{c.name}</TableCell>
                                        <TableCell align='center'>{c.rn}</TableCell>
                                        <TableCell>{zones?.find(z => z.id === c.zone_id)?.name||'-'}</TableCell>
                                    </TableRow>
                                ))
                            :   <TableRow>
                                    <TableCell colSpan={5} align='center'>Харилцагчийн мэдээлэл олдсонгүй!</TableCell>
                                </TableRow>
                    }
                </TableBody>
                <CustomersTablePages
                    count={count} 
                    page={page} 
                    setPage={setPage} 
                    pageSize={pageSize}
                    setPageSize={() => {}}
                    colSpan={5}
                />
            </Table>
        </TableContainer>
    )
}
