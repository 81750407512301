import { TableCell, Tooltip, Typography } from "@mui/material"

export default function BranchCell({ branch, of_customer }) {
    const branchName = branch?.name ? branch.name : of_customer ? '-' : 'Очиж авах'
    const detail = branch?.address
    if (!detail) {
        return <TableCell align='center' sx={{ p: '4px' }}>{branchName}</TableCell>
    }

    return (
        <Tooltip
            title={<Typography fontSize='13px' sx={{ width: '250px' }}>{detail}</Typography>}
            PopperProps={{ modifiers: [ { name: "offset", options: { offset: [0, -30] } } ] }}
        >
            <TableCell align='center' sx={{ p: '4px' }}>{branchName}</TableCell>
        </Tooltip>
    )
}