import Checkbox from '@mui/material/Checkbox'

export default function CheckColumn({ isShown }) {
    return (
        <Checkbox
            size='small'
            checked={isShown}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ p: '3px' }}
        />
    )
}
