//import { isTokenValid } from '../../../../utils/functions'
import { Tab, Tabs } from '@mui/material'
import AccountsInfo from './Info'
import { useState } from 'react'
import Setups from './Setups'
import Qpay from './Qpay'

const tabs = [ 'Ерөнхий мэдээлэл', 'Qpay холбох', 'Тохиргоо' ]

export default function Setting() {
	//const { role } = isTokenValid()
	//const isAB = ['A', 'B'].includes(role)
	const [tab, setTab] = useState(tabs[0])
	const handleChange = (e) => { setTab(e.target.textContent) }

	return (
		<div>
			<Tabs 
				value={tab} 
				onChange={ handleChange }
				sx={{
					minHeight: '28px',
					height: '28px',
					mb: '4px',
					'& .MuiTab-root': { minHeight: '25px', height: '25px' }
				}}
			>
			{
				tabs.map(t => <Tab key={t} value={t} label={t} />)
			}
			</Tabs>
			{ tab === 'Ерөнхий мэдээлэл' && <AccountsInfo /> }
			{ tab === 'Qpay холбох' && <Qpay /> }
			{ tab === 'Тохиргоо' && <Setups /> }
		</div>
	)
}