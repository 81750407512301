import { IconButton, TableCell, TableRow } from "@mui/material"
import { Delete, Edit } from "@mui/icons-material"

export default function DeliveryZoneRow({ zone, idx, setSelectedZone }) {
    const delman = zone?.delman?.name||'-'
    return (
        <TableRow
            sx={{
                '&:hover': { backgroundColor: '#f0f0f0' },
                transition: '0.15s',
                '& > td': {
                    p: '2px 6px',
                    textAlign: 'center'
                }
            }}
        >
            <TableCell>{idx+1}</TableCell>
            <TableCell>{zone.name}</TableCell>
            <TableCell>{delman}</TableCell>
            <TableCell>
                <IconButton
                    sx={{ p: '2px' }}
                    onClick={() => setSelectedZone(zone)}
                >
                    <Edit />
                </IconButton>
            </TableCell>
            <TableCell>
                <IconButton 
                    sx={{ p: '2px' }}
                    onClick={() => setSelectedZone({ ...zone, is_delete: true})}
                >
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}