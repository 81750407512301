import { TableFooter, Box, Typography, TableRow, TableCell } from '@mui/material'
import PaginationControls from './PaginationControls'

export default function CustomersTablePages({ count, page, setPage, pageSize, colSpan, children, children2 }) {
    const lastPage = Math.ceil(count / pageSize)
    const handleFirst = () => { setPage(1) }
    const handleLast = () => { setPage(lastPage) }
    const handlePage = (isNext) => {
        if (page <= 1) { setPage(1) }
        if (page >= lastPage) { setPage(lastPage) }
        isNext ? setPage(page + 1) : setPage(page - 1)
    }
    const labelDisplayedRows = () => {
        if ((page === 1 && count <= pageSize) || (page === lastPage)) return `${(lastPage-1)*pageSize+1} - ${count}`
        const from = (page - 1) * pageSize + 1
        const to = page * pageSize > count ? count : page * pageSize
        return `${from} - ${to} (Нийт: ${count} )`
    }

    return (
        <TableFooter
            sx={{
                position: 'sticky', 
                bottom: 0, 
                background: 'white',
                boxShadow: '0 0 1px 1px #e1e1e1', 
                p: 0, 
                m: 0,
            }}
        >
            <TableRow sx={{ p: 0, m: 0 }}>
                <TableCell sx={{ p: 0 }} colSpan={colSpan||1}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {children2}
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                            <Typography sx={{ fontSize: '14px', color: 'secondary.light', mr: 2 }}>
                                {labelDisplayedRows()}
                            </Typography>
                            <PaginationControls {...{handleFirst, handleLast, handlePage, page, lastPage}} />
                        </Box>
                        {children}
                    </Box>
                </TableCell>
            </TableRow>
        </TableFooter>
    )
}
