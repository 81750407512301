import { TextField } from '@mui/material'
import { useState } from 'react'

export default function SmallTextField(props) {
    const [labelShrink, setLabelShrink] = useState(props?.value||false)
    const handleBlur = () => {
        if (props.value?.length === 0|| !props.value) {
            setLabelShrink(false)
        }
    }

    return (
        <TextField
            {...props}
            onFocus={() => setLabelShrink(true)}
            onBlur={handleBlur}
            InputLabelProps={{ 
                ...props.InputLabelProps,
                shrink: props?.value?.length>0 || labelShrink,
                style: { 
                    fontSize: '14px',
                    color: 'primary.light',
                    transform: labelShrink 
                                    ?   'translate(13px, -8px) scale(0.75)' 
                                    :   'translate(13px, 2px) scale(1)',
                    ...props.InputLabelProps?.style
                }
            }}
            inputProps={{
                ...props.inputProps,
                style: { 
                    fontSize: '14px', 
                    color: '#1b2e3c', 
                    cursor: 'pointer', 
                    padding: '2px 6px',
                    ...props.inputProps?.style,
                }
            }}
            size={props.size || 'small'}
        />
    )
}