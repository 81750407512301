import { Box, Button, Dialog, DialogContent, Grid, IconButton, TextField, Typography } from '@mui/material'
import { addBranch, updateBranch } from '../../../../../api/company'
import { emailYup, phoneYup } from '../../../../../utils/constants'
import CustomTextField from './CustomTextField'
import { useEffect, useState } from 'react'
import { Close } from '@mui/icons-material'
import { useProfileCtx } from '../../..'
import { useFormik } from 'formik'
import { keys } from 'underscore'
import Manager from './Manager'
import * as Yup from 'yup'

const managerSchema = Yup.object({
    last_name: Yup.string().required('Менежерийн овог оруулна уу!').max(50, '50 тэмдэгтээс хэтрэхгүй байх ёстой!').nullable(),
    name: Yup.string().required('Менежерийн нэр оруулна уу!').max(50, '50 тэмдэгтээс хэтрэхгүй байх ёстой!').nullable(),
    ...emailYup,
    ...phoneYup,
    rd: Yup.string().transform(val => val.trim().replace(/\s+/g, ''))
            .matches(/^[а-яА-Я]{2}\d{8}$/, 'Регистрийн дугаар буруу байна!')
            .required('Регистрийн дугаар оруулна уу!'),
}).nullable()

const validationSchema = Yup.object({
    name: Yup.string().required('Салбарын нэр оруулна уу!'),
    phone: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').required('Салбарын утасны дугаар оруулна уу!'),
    phone2: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').nullable(),
    //manager: managerSchema,
    note: Yup.string().max(255, '255 тэмдэгтээс хэтрэхгүй байх ёстой!').nullable(),
    /* lat: Yup.number()
        .required('Latitude is required')
        .min(-90, 'Latitude must be between -90 and 90')
        .max(90, 'Latitude must be between -90 and 90'),
    lng: Yup.number()
        .required('Longitude is required')
        .min(-180, 'Longitude must be between -180 and 180')
        .max(180, 'Longitude must be between -180 and 180'), */
})

export default function AddBranch({ refetch, selected, open, handleClose }) {
    const { setMsg } = useProfileCtx()
    const isUpdate = Boolean(selected?.id)
    const { handleSubmit, getFieldProps, setFieldValue, setErrors, resetForm, values, errors } = useFormik({
        initialValues: {
            name: selected?.name||'',
            phone: selected?.phone||'',
            phone2: selected?.phone2||null,
            note: selected?.note||null,
        },
        onSubmit: (branchInfo) => {
            if (isUpdate) {
                updateBranch(branchInfo, selected.id)
                    .then(_ => {
                        setMsg({ m: 'Амжилттай!', s: 'success' })
                        refetch()
                        handleClose()
                    })
                    .catch(e => {
                        console.log("AE: ", e)
                        const d = e?.response?.data
                        if (d?.manager?.last_name) { setErrors({ 'manager': { 'last_name': d.manager.last_name[0] } }) }
                        if (d?.manager?.name) { setErrors({ 'manager': { 'name': d.manager.name[0] } }) }
                        if (d?.manager?.email) { setErrors({ 'manager': { 'email': d.manager.email[0] } }) }
                        if (d?.manager?.phone) { setErrors({ 'manager': { 'phone': d.manager.phone[0] } }) }
                    })
            } else {
                addBranch(branchInfo)
                    .then(res => {
                        setMsg({ m: 'Амжилттай!', s: 'success' })
                        refetch()
                        handleClose()
                    })
                    .catch(e => {
                        const d = e?.response?.data
                        console.log("UE: ", e)
                        if (d?.manager?.email) { setErrors({ 'manager': { 'email': d.manager.email[0] } }); console.log("E") }
                        if (d?.manager?.phone) { setErrors({ 'manager': { 'phone': d.manager.phone[0] } }) }
                        if (d?.manager?.name) { setErrors({ 'manager': { 'name': d.manager.name[0] } }) }
                        if (d?.manager?.last_name) { setErrors({ 'manager': { 'last_name': d.manager.name[0] } }) }
                    })
            }
        },
        validationSchema,
        validateOnChange: false
    })
    const { lat, lng, ...rest } = values

    useEffect(() => { resetForm() }, [])

    const getErrors = (name) => {
        const isErr = keys(errors)?.includes(name)// && keys(touched)?.includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }
    //const handleAddressFieldValue = (field, value) => { setFieldValue(`address.${field}`, value) }

    const [isApiFailed, setIsApiFailed] = useState(false)
    const [isGettingAddress, setIsGettingAddress] = useState(false)        
    const handleAddressInfo = (value) => {
        setFieldValue('lat', value?.lat)
        setFieldValue('lng', value?.lng)
    }

    // err
    useEffect(() => {
        if (Boolean(errors?.manager) === true && typeof errors.manager === 'string') { 
            setMsg({ m: errors?.manager, s: 'warning' }) 
        }
    }, [errors])
    console.log("ERRS: ", selected)

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='650px'>
            <DialogContent>
                <Box 
                    sx={{
                        textAlign: 'right',
                        pb: 2
                    }}
                >
                    <IconButton 
                        sx={{ p: '2px' }}
                        onClick={handleClose}
                    >
                        <Close sx={{ fontSize: '20px' }} />
                    </IconButton>
                </Box>
                <Grid container spacing={1}>
                    <Grid item sx={4}>
                        <CustomTextField
                            label='Салбарын нэр'
                            name='name'
                            {...getFieldProps('name')}
                            {...getErrors('name')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CustomTextField
                            label='Салбарын утас'
                            name='phone'
                            {...getFieldProps('phone')}
                            {...getErrors('phone')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CustomTextField
                            label='Салбарын утас2'
                            name='phone2'
                            {...getFieldProps('phone2')}
                            {...getErrors('phone2')}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs>
                        <CustomTextField
                            label='Нэмэлт мэдээлэл'
                            name='note'
                            multiline
                            rows={3}
                            {...getFieldProps('note')}
                            {...getErrors('note')}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Button
                        variant='contained'
                        size='small'
                        sx={{ my: 1, width: '200px', p: '2px' }}
                        onClick={handleSubmit}
                        disabled={isGettingAddress}
                    >{isUpdate ? 'Шинэчлэх' : 'Нэмэх'}</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
