import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { getDeliveryZones, updateCustomerZone } from "../../../../../api/delivery"
import { useCallback, useEffect, useRef, useState } from "react"
import { getSuppCustomers } from "../../../../../api/company"
import { scrollStyle } from "../../../../../utils/constants"
import TablePages from "../../../../common/TablePages"
import SelectDeliveryZone from "../SelectDeliveryZone"
import { isEmpty, keys } from "underscore"
import HeadCell from "./Cells/HeadCell"
import { useQuery } from "react-query"
import FilterRow from "./Filter"
import Row from "./Row"

export default function SupplierCustomers({ setCustomer, isLocationUpdated, waitMsg, setMsg }) {
	const [columns, setColumns] = useState({
		name: { label: "Нэр", order: "", align: "center" },
		rn: { label: "РД", order: "", align: "center" },
		email: { label: "И-мэйл", order: "", align: "center" },
		phone: { label: "Утасны дугаар", order: "", align: "center" },
		is_cmp: { label: "Компани эсэх", order: "", align: "center" },
		zone: { label: "Түгээлтийн бүс", order: "", align: "center" },
		location: { label: "Байршил", order: "", align: "center" },
		cust_type: { label: "Ангилал", order: "", align: "center" },
		note: { label: "Нэмэлт мэдээлэл", order: "", align: "center" },
		created: { label: "Огноо", order: "", align: "center" },
	})
	// ordering
	const handleOrder = (fieldName) => {
		const c = columns?.[fieldName]
		const v =
			c?.order === ""
				? fieldName
				: c?.order === fieldName
					? `-${fieldName}`
					: ""
		setColumns({ ...columns, [fieldName]: { ...c, order: v } });
	}

	const orderParam = keys(columns)?.reduce((acc, k) => {
		const cur = columns?.[k]?.order;
		if (cur !== "") {
			acc += '&ordering=' + cur
		}
		return acc
	}, "")

	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(50)
	const [filtering, setFiltering] = useState(null)

	const [partners, setPartners] = useState([])
	const [count, setCount] = useState(0)
	const getData = (res) => { setPartners(res?.data?.results || []); setCount(res?.data?.count || 0) }
	const { data, refetch } = useQuery(["get_suppcustomers", page, pageSize, orderParam, filtering], getSuppCustomers, { onSuccess: getData })
	useEffect(() => { if (isLocationUpdated) { refetch() } }, [isLocationUpdated])

	useEffect(() => {
		if (!isEmpty(data?.data)) {
			setPartners(data?.data?.results || [])
		} else {
			setPartners([])
		}
	}, [data?.data])

	const hhRef = useRef(null)
	//console.log("P: ", data)

	const selectCustomer = (customer) => { setCustomer(customer) }

	// select delivery zone
	const [deliveryZones, setDeliveryZones] = useState([])
	const fetchDeliveryZones = () => {
		getDeliveryZones()
			.then(res => {
				setDeliveryZones([...res?.data, {id: 0, name: 'Болих'}] || [])
			})
			.catch(e => {
				console.log("ERR: ", e)
				waitMsg()
			})
	}
	useEffect(() => { fetchDeliveryZones() }, [])

	const [selectedCustId, setSelectedCustId] = useState(null)
	const handleSelectCustId = (e, custId) => {
		setSelectedCustId(custId)
		e?.stopPropagation()
	}
	const [zoneBoxOpen, setZoneBoxOpen] = useState(false)
	const zoneBoxOff = () => { setZoneBoxOpen(false); setSelectedCustId(null) }
	useEffect(() => {
		if (selectedCustId) {
			if (deliveryZones.length <= 0) {
				waitMsg()
			} else {
				setZoneBoxOpen(true)
			}
		}
	}, [selectedCustId])
	const handleSelectDeliveryZone = useCallback((zoneId) => {
		let data = { customer_ids: [selectedCustId], of_supplier: true }
		if (zoneId) {
			data = { ...data, zone_id: zoneId }
		} else if (zoneId === 0) {
			data = { ...data, zone_id: null }
		}
		updateCustomerZone(data)
			.then(res => {
				if (res?.data) {
					refetch()
					setMsg({ m: 'Амжилттай!', s: 'success' })
				}
			})
			.catch(e => {
				waitMsg()
			})
			.finally(() => { zoneBoxOff() })
	}, [selectedCustId])

	return (
		<>
			<TableContainer 
				sx={{ 
					mt: 1,
					height: '100%', 
					...scrollStyle,
					'&::-webkit-scrollbar': { width: 8, height: 8 }
				}}
			>
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow
							sx={{
								"& > th": {
									fontSize: "12px",
									backgroundColor: "secondary.light",
									color: "white",
									borderRight: "1px solid white",
									p: "4px 4px",
									textAlign: "center",
								},
							}}
							ref={hhRef}
						>
							<TableCell>
								<Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
									№
								</Typography>
							</TableCell>
							{
								keys(columns)?.map((c) => (
									<HeadCell
										key={c}
										field={columns?.[c]}
										fieldName={c}
										handleOrder={handleOrder}
									/>
								))
							}
						</TableRow>
						<FilterRow 
							filtering={filtering} 
							setFiltering={setFiltering}
							hhRef={hhRef}
						/>
					</TableHead>
					<TableBody>
						{
							partners?.map((row, idx) => (
								<Row
									key={`${idx}`}
									row={row}
									setPartners={setPartners}
									idx={idx}
									page={page}
									pageSize={pageSize}
									selectCustomer={selectCustomer}
									deliveryZones={deliveryZones}
									handleSelectCustId={handleSelectCustId}
								/>
							))
						}
					</TableBody>
					<TablePages
						count={count}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						colSpan={keys(columns)?.length + 1}
					/>
				</Table>
			</TableContainer>
			{
				deliveryZones.length > 0 &&
					<SelectDeliveryZone
						zoneBoxOpen={zoneBoxOpen}
						zoneBoxOff={zoneBoxOff}
						deliveryZones={deliveryZones}
						handleSelectDeliveryZone={handleSelectDeliveryZone}
					/>
			}
		</>
	)
}