import { Box, Table, TableCell, TableRow } from '@mui/material'

export default function Popup ({ item, color }) {
    const { delman, visited_on, note } = item
    
    return (
        <Box
            sx={{
                position: 'absolute',
                borderRadius: '5px',
                bottom: 30,
                left: 10,
                border: `1px solid ${color}`,
                backgroundColor: 'white',
                width: 'fit-content',
                boxShadow: 1,
                p: '1px 4px'
            }}
        >
            <Table 
                sx={{
                    '& > tr > td': {
                        p: '2px 6px',
                        fontSize: '13px'
                    },
                    width: '300px',
                }}
            >
                <TableRow
                    sx={{
                        '& > td': {
                            backgroundColor: 'secondary.light',
                            color: 'white'
                        }
                    }}
                >
                    <TableCell sx={{ borderRight: 1, borderRightColor: 'secondary.light' }}>Тайлбар</TableCell>
                    <TableCell width={'130px'}>Очсон цаг</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ borderRight: 1, borderRightColor: color }}>{note}</TableCell>
                    <TableCell>{visited_on||'-'}</TableCell>
                </TableRow>
            </Table>
            {/* <Typography 
                sx={{ fontSize: '13px', fontWeight: 600, color }}
                noWrap
            >{note||'-'}</Typography> */}
        </Box>
    )
}