import { Box, InputAdornment, MenuItem, TableCell, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import { Close } from '@mui/icons-material'
import DropCellMenu from './DropCellMenu'
import { keys } from 'underscore'
import { useState } from 'react'

export default function DropCell({ field, choices, filters, setFilters }) {
    const theme = useTheme()
    const value = filters?.find(f => f.fKey === field)?.fValue
    const handleSelect = (newVal) => {
        setFilters(prev => {
            const f = { fKey: field, fValue: newVal, operator: '=' }
            if (prev?.length) {
                if (prev.map(p => p.fKey).includes(field)) {
                    return prev.map(p => p?.fKey === field ? f : p)
                } else { return [...prev, f] }
            }
            return [f]
        })
    }
    const clearSelect = () => { setFilters(prev => prev?.filter(p => p.fKey !== field) ) }
    
    // hover
    const [hover, setHover] = useState(false)

    return (
        <TableCell key={field} sx={{ p: '2px' }}>
            <DropCellMenu
                title={<Tooltip title={<Typography fontSize={'13px'}>{choices?.[value]}</Typography>} placement='top' open={Boolean(value && hover)}>
                            <TextField
                                onMouseEnter={() => { setHover(true) }}
                                onMouseLeave={() => { setHover(false) }}
                                placeholder={choices?.[value]??'Сонгох'}
                                InputProps={{
                                    endAdornment: (<InputAdornment sx={{ backgroundColor: 'transparent' }}>
                                                        <Close 
                                                            sx={{ 
                                                                fontSize: '15px', 
                                                                color: theme.palette.primary.light,
                                                                backgroundColor: 'transparent',
                                                                '&:hover': { cursor: 'pointer' },
                                                                display: value ? 'block' : 'none',
                                                            }}
                                                            onClick={clearSelect}
                                                        />
                                                    </InputAdornment>)
                                }}
                                inputProps={{ style: { padding: '2px 5px', cursor: 'pointer' } }}
                                sx={{
                                    '& fieldset': { 
                                        borderRadius: 0, 
                                        borderColor: theme.palette.primary.light 
                                    },
                                    //'&:hover fieldset': { borderColor: theme.palette.primary.light },
                                    '& .MuiInputBase-input::placeholder': {
                                        color: theme.palette.primary.light, 
                                        ml: '5px',
                                        fontSize: '13px', 
                                        opacity: 1
                                    },
                                    '& .MuiInputBase-input': { 'caretColor': 'transparent' },
                                    border: 'none'
                                }}
                                size='small'
                            />
                        </Tooltip>}
                detail={<Box sx={{ backgroundColor: 'white', boxShadow: 8 }}>
                            {keys(choices).map(k => <MenuItem key={k} sx={{ fontSize: '13px', color: theme.palette.primary.light }} onClick={() => handleSelect(k)}>
                                                        {choices[k]}
                                                    </MenuItem>)}
                        </Box>}
            />
        </TableCell>
    )
}
