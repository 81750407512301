import { getProductFieldsInfo, getProducts, updateProductFieldsInfo } from '../../../../../api/product'
import { Box, CircularProgress, Table, TableCell, TableContainer } from '@mui/material'
import TableCustomization from '../../../../common/TableCustomization'
import { createContext, useContext, useEffect, useState } from 'react'
import { scrollStyle } from '../../../../../utils/constants'
import TablePages from '../../../../common/TablePages'
import ProductTableHeader from './ProductTableHeader'
import ProductTableBody from './ProductTableBody'
import DeleteProduct from './DeleteProduct'
import { useAdminCtx } from '../../..'
import { useQuery } from 'react-query'

const ProductListCtx = createContext()
export const useProductListCtx = () => useContext(ProductListCtx)

export default function ProductTable({ cats, handleAddOpen, setOpen, setSelectedProduct, added, setAdded }) {
    const { alertWarning } = useAdminCtx()
    // fetch products
    const [ page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)

    // fields
    const { data: fields, refetch: refetchFieldsInfo, isFetched: isFieldsFetched } = useQuery('get_product_fields_info', getProductFieldsInfo)
    const fieldsInfo = fields?.data||[]
    const cols = fieldsInfo?.filter(f => f?.isShown)

    // filters
    const [filters, setFilters] = useState([])
    const { data, refetch, isFetching, error } = useQuery(['get_products', filters, page, pageSize], getProducts, { retry: false })
    useEffect(() => { if (added) { refetch(); setAdded(false); console.log("REFETCHED: ") } }, [added])
    useEffect(() => { if (error) { alertWarning() } }, [error])
    const products = data?.data?.results
    const first = products?.[0]||null
    const count = data?.data?.count

    // delete
    const [toDelIds, setToDelIds] = useState([])
    const handleSelectAll = () => {
        if (toDelIds?.length != products?.length) {
            setToDelIds(products.map(p => p.id ))
        } else { setToDelIds([]) }
    }

    const { colNames, colFields } = cols?.reduce((acc, cur) => {
        if (!acc['colNames']) { acc['colNames'] = [] }
        if (!acc['colFields']) { acc['colFields'] = [] }
        acc['colNames'].push(cur.name)
        acc['colFields'].push(cur.field_name)
        return acc
    }, {})

    // filter data
    const [catData, setCatData] = useState([])
    const [mnfrs, setMnfrs] = useState([])
    const [vndrs, setVndrs] = useState([])

    return (
        <ProductListCtx.Provider
            value={{
                cats,
                first,
                colFields,
                filters,
                setFilters,
                // filter data
                catData,
                setCatData,
                mnfrs,
                setMnfrs,
                vndrs,
                setVndrs
            }}
        >
            <TableContainer 
                sx={{ 
                    ...scrollStyle,
                    height: '100%',
                    '&::-webkit-scrollbar': { width: 8, height: 8 }, 
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'secondary.light',
                        borderRadius: 0
                    } 
                }}
            >
                <Table stickyHeader>
                    <ProductTableHeader
                        prodsCnt={products?.length} 
                        selCnt={toDelIds?.length}
                        handleSelectAll={handleSelectAll}
                        colNames={colNames}
                        colFields={colFields}
                    />
                        {
                            isFetching
                                ?   <TableCell colSpan={colFields?.length + 2}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                            <CircularProgress />
                                        </Box>
                                    </TableCell>
                                :   <ProductTableBody
                                        setOpen={setOpen} 
                                        products={products} 
                                        colFields={colFields}
                                        toDelIds={toDelIds} 
                                        setToDelIds={setToDelIds} 
                                        setSelectedProduct={setSelectedProduct} 
                                        count={count} 
                                        page={page} 
                                        pageSize={pageSize} 
                                        isFetching={isFetching}
                                        handleAddOpen={handleAddOpen}
                                        filters={filters}
                                    />
                        }
                    {
                    isFieldsFetched &&
                        <TablePages
                            count={count}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            colSpan={colFields?.length + 2}
                            children={
                                <TableCustomization
                                    fieldsInfo={fieldsInfo}
                                    refetchFieldsInfo={refetchFieldsInfo}
                                    updateFields={updateProductFieldsInfo}
                                />
                            }
                            children2={
                                <DeleteProduct 
                                    handleAddOpen={handleAddOpen}
                                    selCnt={toDelIds?.length} 
                                    toDelIds={toDelIds}
                                    refetch={refetch} 
                                    setDelIds={setToDelIds} 
                                />
                            }
                        />
                    }
                </Table>
            </TableContainer>
        </ProductListCtx.Provider>
    )
}
