import { apiUrl } from '../../../../../../../global/axiosBase'
import { Box } from '@mui/material'
import Thumbnail from './Thumbnail'

export default function Thumbs({ licenseFiles }) {
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                gap: '6px'
            }}
        >
            {
                licenseFiles?.map(lf => {
                    return <Thumbnail key={lf.name} fileUrl={lf} apiUrl={apiUrl} />
                })
            }
        </Box>
    )
}