import { addUpdateDeliveryZone } from "../../../../../../../api/delivery"
import { Box, Button, TextField } from "@mui/material"
import { useEffect, useRef, useState } from "react"

export default function AddUpdateDeliveryZone({ fetchZones, setMsg, waitMsg, zoneToEdit = null, setZoneToEdit = null }) {
    const [name, setName] = useState('')
    const nameRef = useRef(null)
    useEffect(() => {
        if (zoneToEdit?.name) {
            setName(zoneToEdit.name)
            nameRef.current.focus()
        } else if (zoneToEdit === null) {
            setName('');
            if (nameRef.current) {
                nameRef.current.value = ''
                nameRef.current.blur()
            }
        }
    }, [zoneToEdit])

    const handleAddUpdateZone = (name) => {
        let data = { name }
        if (zoneToEdit?.id) {
            data = { ...data, zone_id: zoneToEdit.id }
        }
        addUpdateDeliveryZone(data)
            .then(_ => {
                setName('')
                if (nameRef.current) {
                    nameRef.current.value = ''
                    nameRef.current.blur()
                }
                setMsg({ m: 'Амжилттай!', s: 'success' })
                fetchZones()
                setZoneToEdit(null)
            })
            .catch(e => { 
                waitMsg()
            })
            .finally(() => {})
    }

    const handleAdd = () => {
        if (!name) {
            setMsg({ m: 'Бүсийн нэр оруулна уу!', s: 'warning' })
        } else if (name?.length > 100) {
            setMsg({ m: 'Бүсийн нэр 100 тэмдэгтээс бага байх ёстой!', s: 'warning' })
        } else {
            handleAddUpdateZone(name)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 1,
            }}
        >
            <TextField
                label="Түгээлтийн бүс нэмэх"
                placeholder="Нэр"
                variant="outlined"
                fullWidth
                value={name}
                onChange={e => setName(e.target.value)}
                onKeyDown={e => e.key === 'Enter' && handleAdd()}
                size='small'
                inputRef={nameRef}
            />
            <Button 
                size='small' 
                variant='contained' 
                onClick={handleAdd}
            >
                {zoneToEdit?.id ? 'Засах' : 'Нэмэх'}
            </Button>
        </Box>
    )
}