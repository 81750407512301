import { Box } from '@mui/material'

export default function Thumbnail({ fileUrl, apiUrl }) {
    const imgUrl = `${apiUrl}${fileUrl}`
    const handleImgOpen = (e) => { window.open(imgUrl, '_blank'); e.stopPropagation() }
    return (
        <Box sx={{ position: 'relative', width: '80px', height: '80px' }} onClick={handleImgOpen}>
            <img 
                src={imgUrl} 
                style={{ 
                    width: '80px',
                    height: '80px',
                    borderRadius: '8px',
                    boxShadow: '4px 4px 4px grey'
                }} 
            />
        </Box>
    )
}
