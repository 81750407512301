import { removeTokens } from '../../../../utils/functions'
import { useAuthCtx } from '../../../../context/AuthCtx'
import AxiosWithToken from '../../../../global/axiosBase'
import { Button } from '@mui/material'

export default function LogOut () {
    const { clearUserItems } = useAuthCtx()

    const handleLogout = () => {
        AxiosWithToken.post('auth/logout/')
            .then(_ => {
                removeTokens()
                clearUserItems()
                window.location.href = '/home'
            })
            .catch(e => console.log(e))
    }

    return (
        <Button 
            onClick={() => handleLogout()}
            size='small'
            sx={{
                p: 0,
                color: 'white', 
                borderBottom: '1px solid transparent', 
				'&:hover': { 
                    borderBottomColor: 'white', 
                    borderRadius: 0
                }
            }}
        >Гарах</Button>
    )
}

