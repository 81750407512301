import { TableCell, TableRow, useTheme } from '@mui/material'
import React from 'react'

export default function DeliveryItemRow({ idx, item }) {
    const theme = useTheme()
    const dateString = item?.visited_on?.split(' ')?.[0] || '-'
    const timeString = item?.visited_on?.split(' ')?.[1] || ''

    return (
        <TableRow
            key={`${idx}`}
            sx={{
                '& > td': { textAlign: 'center', p: '2px', fontSize: '13px' },
                '&: hover': { backgroundColor: '#e6e6e6' },
            }}
        >
            <TableCell>{idx+1}</TableCell>
            <TableCell sx={{ textAlign: 'left !important', pl: '10px !important' }}>{item?.note||'-'}</TableCell>
            <TableCell sx={{ textAlign: 'right !important', pr: '16px !important' }}>
                {`${dateString} `}
                <span style={{ color: theme.palette.primary.light, fontWeight: 500 }}>{timeString}</span>
            </TableCell>
        </TableRow>
    )
}
