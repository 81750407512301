import LicenseInput from '../CompleteRegistration/RegistrationForm/CompanyInfo/LicenseInput'
import { updateCompanyLicense } from '../../../api/company'
import { Box, Button } from '@mui/material'
import { useState } from 'react'

export default function RegisterLicense({ alertSuccess, handleClose, email, p, setAlert }) {
    const [license, setLicense] = useState([])
    const handleSubmit = () => {
        if (license?.length > 0) {
            const fd = new FormData()
            fd.append('email', email)
            fd.append('password', p)
            for (let l of license) { fd.append('license', l) }
            updateCompanyLicense(fd)
                .then(_ => { alertSuccess(); handleClose() })
                .catch(e => {
                    console.log("ERRs: ", e);
                    setAlert({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
                })
        }
    }

    return (
        <Box 
            sx={{ 
                p: 4,
                width: '500px',
                textAlign: 'center'
            }}
        >
            <LicenseInput
                licenseFiles={license}
                setLicenseFiles={setLicense}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 1 }}>
                <Button onClick={ handleSubmit } size='small' variant='contained' sx={{ p: '4px', width: '150px' }}>Илгээх</Button>
            </Box>
        </Box>
    )
}
