import { TableCell, TableRow } from "@mui/material"

export function formatDate(dateStr) {
	let date = new Date(dateStr)
	let formattedDatetimeStr = date.toISOString().slice(0, 19).replace("T", " ")
	return formattedDatetimeStr
}

export default function Row(props) {
	const { row, idx, page, pageSize, selectCustomer, deliveryZones, handleSelectCustId } = props
	const zone = deliveryZones?.find(d => d.name === row?.zone)?.name||'Сонгох'
	const rowCustTypes = row.cust_type?.map(ct => ct.name).join(', ')||'-'
	const cp = row.is_cmp ? 'Компани' : row.is_cmp === false ? 'Хувь хүн' : 'Тодорхойгүй'
	const created = row?.created ? formatDate(row.created) : ''
	const handleSelect = () => { selectCustomer(row) }

	return (
		<TableRow
			key={`${idx + 1}`}
			sx={{
				"&:last-child td, &:last-child th": { border: 0 },
				"& > td": { padding: "2px 4px", fontSize: "13px" },
				'&:hover': {
					backgroundColor: '#f1f1f1',
					cursor: 'pointer'
				},
			}}
		>
			<TableCell>
				{page === 1 ? idx + 1 : page * pageSize + idx + 1}
			</TableCell>
			<TableCell align="left" sx={{ paddingLeft: '15px !important' }}>{row.name}</TableCell>
			<TableCell align="left">{row.rn}</TableCell>
			<TableCell align="left">{row.email}</TableCell>
			<TableCell align="right">{row.phone}</TableCell>
			<TableCell align="center">{cp}</TableCell>
			<TableCell 
				align="center" 
				onClick={(e) => handleSelectCustId(e, row.id)}
				sx={{
					cursor: 'pointer',
					'&:hover': { 
						textDecoration: 'underline',
						color: 'secondary.light',
						fontWeight: 'bold'
					},
					textDecoration: zone === 'Сонгох' ? 'underline' : 'none',
					color: zone === 'Сонгох' ? 'secondary.light' : 'black',
					fontWeight: zone === 'Сонгох' ? 'bold' : 'normal'
				}}
			>{zone}</TableCell>
			<TableCell 
				align="center"
				onClick={handleSelect}
				sx={{
					cursor: 'pointer',
					'&:hover': {
						textDecoration: 'underline',
						color: 'secondary.light',
						fontWeight: 'bold'
					},
					textDecoration: zone === 'Сонгох' ? 'underline' : 'none',
					fontWeight: zone === 'Сонгох' ? 'bold' : 'normal',
					color: 'secondary.light'
				}}
			>
				{
					Boolean(row.lat && row.lng) 
						?	<span>&#9873;</span>
						:	'Сонгох'
				}
			</TableCell>
			<TableCell align="center">{rowCustTypes}</TableCell>
			<TableCell align="center">{row.note}</TableCell>
			<TableCell align="center">{created}</TableCell>
		</TableRow>
	)
}
