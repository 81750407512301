import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import { Typography } from '@mui/material'
import Radio from '@mui/material/Radio'

const custGroups = [
    { id: 1, name: 'PHARMO харилцагч' },
    { id: 2, name: 'MACS харилцагч' }
]

export default function SelectCustGroup({ custGroup, setCustGroup }) {
    const handleChange = (event) => { setCustGroup(event.target.value) }

    return (
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={custGroup}
                onChange={handleChange}
                sx={{ flexDirection: 'row' }}
            >
                {
                    custGroups?.map(cg => (
                        <FormControlLabel 
                            key={cg.id}
                            value={cg.id}
                            control={<Radio size='small' />}
                            label={<Typography fontSize={'15px'}>{cg.name}</Typography>}
                        />
                    ))
                }
            </RadioGroup>
        </FormControl>
    )
}
