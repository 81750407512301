import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { getDeliveryHistory, getDeliveryOrderDetail } from '../../../../../api/delivery'
import { scrollStyle } from '../../../../../utils/constants'
import DeliveryItems from '../CurrentDelivery/DeliveryItems'
import { DELIVERY_ORDER_PROCESS, useDeliveryCtx } from '..'
import TablePages from '../../../../common/TablePages'
import OrderDetail from '../OrderDetail'
import { useQuery } from 'react-query'
import FilterRow from './FilterRow'
import { useState } from 'react'

export default function DeliveryHistory() {
    const { delmanNames, zones } = useDeliveryCtx()

    // deliveries
    const [filters, setFilters] = useState({
        zone: null,
        delman: null,
        order_no: null
    })
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const onSuccess = () => { setCount(delvsData?.data?.count||0) }
    const { data: delvsData, isFetching: isDelvsFetching } = useQuery(['get_delivery_history', page, pageSize, filters], getDeliveryHistory, { refetchOnWindowFocus: true, onSuccess })
    const allOrders = delvsData?.data?.results?.map(d => d.orders?.map(o => ({...o, delman: d.delman, delivery: d}))).flat()
    // group orders by delman, zone, orderer
    const ordersByDeliveryByOrderer = allOrders?.reduce((acc, order) => {
        const added_delivery = acc?.find(a => a.delivery?.id === order.delivery?.id)
        if (added_delivery) {
            const added_orderer = added_delivery.orderers?.find(o => o.orderer?.id === order.orderer?.id)
            if (added_orderer) {
                added_orderer.orders?.push(order)
            } else {
                added_delivery.orderers?.push({ orderer: order.orderer, orders: [order] })
            }
        } else {
            acc?.push({ delivery: order.delivery, orderers: [{ orderer: order.orderer, orders: [order] }] })
        }
        return acc
    }, [])

    const deliveryOrdersCnt = allOrders?.reduce((acc, order) => {
        if (order.delivery_id) {
            acc[order.delivery_id] = (acc[order.delivery_id]||0) + 1
        }
        return acc
    }, {})
    //console.log("ORDERS: ", ordersByDeliveryByOrderer)

    // show delivery items (additional deliveries)
    const [deliveryId, setDeliveryId] = useState(null)
    const deliveryItems = delvsData?.data?.results?.find(d => d.id === deliveryId)?.items || []
    //console.log("Delv: ", deliveryId, deliveryItems)

    // show delivery order detail
    const [orderId, setOrderId] = useState(null)
    const { data: orderDetail, isFetched: orderDetailIsFetched } = useQuery(['get_order_detail', orderId], getDeliveryOrderDetail, { enabled: Boolean(orderId) })
    const orderItems = orderDetail?.data||[]

    /* const gotToMap = () => { // тухайн түгээлтийн замыг харах болгож өөрчлөх
        if (deliveries?.length === 0) {
            setMsg({ m: 'Түгээлт байхгүй!', s: 'warning' })
            return
        } else if (!deliveries?.find(d => d.started_on)) {
            setMsg({ m: 'Эхэлсэн түгээлт байхгүй!', s: 'warning' })
            return
        }
        window.open('/admin/delivery_map', '_blank')
    } */

    let order_ctr = 1
    // hover
    const [hovered, setHovered] = useState(null)

    return (
        <TableContainer
            sx={{ 
                p: 1, 
                boxShadow: 3,
                width: '90%',
                maxHeight: `calc(100% - ${10}px)`,
                height: `calc(100% - ${10}px)`,
                ...scrollStyle
            }}
        >
            <Table>
                <TableHead>
                    <TableRow
                        sx={{
                            '& > th': {
                                color: 'white',
                                textAlign: 'center',
                                backgroundColor: 'secondary.light',
                                borderRight: '1px solid white',
                                p: '2px 4px'
                            }
                        }}
                    >
                        <TableCell rowSpan={2}>№</TableCell>
                        <TableCell rowSpan={2}>Түгээлтийн дугаар</TableCell>
                        <TableCell rowSpan={2}>Түгээгч</TableCell>
                        <TableCell rowSpan={2}>Эхэлсэн</TableCell>
                        <TableCell rowSpan={2}>Дууссан</TableCell>
                        <TableCell rowSpan={2}>Явц</TableCell>
                        <TableCell colSpan={5} rowSpan={1}>Захиалга</TableCell>
                        <TableCell rowSpan={2}>Нэмэлт Хүргэлт</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& > th': {
                                color: 'white',
                                textAlign: 'center',
                                backgroundColor: 'secondary.light',
                                borderRight: '1px solid white',
                                p: '2px 4px'
                            }
                        }}
                    >
                        <TableCell rowSpan={1}>№</TableCell>
                        <TableCell rowSpan={1} sx={{ fontSize: '10px', lineHeight: 1 }}>Захиалгын дугаар</TableCell>
                        <TableCell rowSpan={1}>Захиалагч</TableCell>
                        <TableCell rowSpan={1}>Төлөв</TableCell>
                        <TableCell rowSpan={1}>Бүс</TableCell>
                    </TableRow>
                    <FilterRow
                        filters={filters}
                        setFilters={setFilters}
                        delmanNames={delmanNames}
                        zones={zones}
                    />
                </TableHead>
                <TableBody>
                {
                    ordersByDeliveryByOrderer?.length > 0
                        ?   ordersByDeliveryByOrderer?.map((delivery, idx) => (
                                <>
                                    {delivery.orderers?.map((orderer, orderer_idx) => (
                                        <>
                                            {orderer?.orders?.map((order, order_idx) => (
                                                <>
                                                    <TableRow
                                                        key={order?.id}
                                                        sx={{ 
                                                            '& > td': { 
                                                                p: '2px 6px',
                                                                borderRight: 1,
                                                                borderRightColor: '#e1e1e1'
                                                            },
                                                            '&:hover': {
                                                                backgroundColor: '#f0f0f0'
                                                            }
                                                        }}
                                                        onMouseEnter={() => setHovered(delivery.delivery?.id)}
                                                        onMouseLeave={() => setHovered(null)}
                                                    >
                                                        {orderer_idx === 0 && order_idx === 0 && (
                                                            <>
                                                                <TableCell rowSpan={deliveryOrdersCnt[order.delivery_id]||1} align='center'>{idx+1}</TableCell>
                                                                <TableCell rowSpan={deliveryOrdersCnt[order.delivery_id]||1} align='center' width='100px'>
                                                                    {order.delivery?.delivery_no||'-'}
                                                                </TableCell>
                                                                <TableCell rowSpan={deliveryOrdersCnt[order.delivery_id]||1} align='center'>
                                                                    {order.delman?.name||'-'}
                                                                </TableCell>
                                                                <TableCell rowSpan={deliveryOrdersCnt[order.delivery_id]||1} align='center'>
                                                                    {delivery.delivery?.started_on||'-'}
                                                                </TableCell>
                                                                <TableCell rowSpan={deliveryOrdersCnt[order.delivery_id]||1} align='center'>
                                                                    {delivery.delivery?.ended_on||'-'}
                                                                </TableCell>
                                                                <TableCell rowSpan={deliveryOrdersCnt[order.delivery_id]||1} align='center' width='60px'>
                                                                    {delivery.delivery?.progress ? `${delivery.delivery.progress} %` : '-'}
                                                                </TableCell>
                                                            </>
                                                        )}
                                                        <TableCell align='center' sx={{ backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial' }}>{order_ctr++}</TableCell>
                                                        <TableCell 
                                                            align="center"
                                                            sx={{
                                                                borderLeft: 1,
                                                                cursor: 'pointer',
                                                                textDecoration: 'underline',
                                                                color: 'primary.light',
                                                                '&:hover': { 
                                                                    fontWeight: 500,
                                                                    backgroundColor: '#f0f0f0',
                                                                    textDecoration: 'underline',
                                                                },
                                                                backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial'
                                                            }}
                                                            onClick={() => setOrderId(order.id)}
                                                        >
                                                            {order.orderNo}
                                                        </TableCell>
                                                        <TableCell align='center' sx={{ backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial' }}>{order?.orderer?.name||'-'}</TableCell>
                                                        <TableCell align='center' sx={{ backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial' }}>{DELIVERY_ORDER_PROCESS?.[order?.process]||'-'}</TableCell>
                                                        <TableCell align='center' sx={{ backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial' }}>{order?.zone?.name||'-'}</TableCell>
                                                        {
                                                            orderer_idx === 0 && order_idx === 0 && (
                                                                <>
                                                                    {
                                                                        order?.delivery?.items?.length > 0
                                                                            ?   <TableCell 
                                                                                    align='center'
                                                                                    sx={{ 
                                                                                        backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial',
                                                                                        cursor: 'pointer',
                                                                                        textDecoration: 'underline',
                                                                                        color: 'primary.light',
                                                                                        '&:hover': { fontWeight: 500 }
                                                                                    }}
                                                                                    onClick={() => { setDeliveryId(order.delivery_id) }}
                                                                                    rowSpan={deliveryOrdersCnt[order.delivery_id]||1}
                                                                                >{'Харах'}</TableCell>
                                                                            :   <TableCell align='center' rowSpan={deliveryOrdersCnt[order.delivery_id]||1}>-</TableCell>
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </TableRow>
                                                </>
                                            ))}
                                        </>
                                    ))}
                                </>
                            ))
                        :   <TableRow>
                                <TableCell colSpan={12} align='center' sx={{ color: '#4d4d4d' }}>
                                    {
                                        isDelvsFetching
                                            ?   <CircularProgress />
                                            :   'Үр дүн олдсонгүй!'
                                    }
                                </TableCell>
                            </TableRow>
                }
                </TableBody>
                <TablePages
                    count={count} 
                    page={page} 
                    setPage={setPage} 
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    colSpan={12}
                />
            </Table>
            {
                Boolean(orderId) && (
                    <OrderDetail 
                        orderItems={orderItems}
                        isFetched={orderDetailIsFetched}
                        setOrderId={setOrderId}
                    />
                )
            }
            {
                Boolean(deliveryId) && (
                    <DeliveryItems
                        deliveryItems={deliveryItems}
                        setDeliveryId={setDeliveryId}
                    />
                )
            }
        </TableContainer>
    )
}