import { updateDeliveryOrderStatus } from "../../../../../../api/delivery"
import { Box, Dialog, TableCell, Typography } from "@mui/material"
import { useState } from "react"

const STATUS_CHOICES = ['D', 'C', 'R', 'O']
export function UpdateOrderStatusCell({ order, DELIVERY_ORDER_PROCESS, isDelman, setMsg, waitMsg, refetch, successMsg, hovered }) {
    const choices = Object.keys(DELIVERY_ORDER_PROCESS)?.filter(k => STATUS_CHOICES.includes(k))
    const [open, setOpen] = useState(false)
    const handleClose = () => { setOpen(false) }
    const handleUpdateStatus = (status) => {
        const data = { delivery_id: order?.delivery_id, order_id: order.id, process: status }
        updateDeliveryOrderStatus(data)
            .then(_ => {
                refetch()
                successMsg()
                handleClose()
            })
            .catch(e => {
                //console.log("ERR: ", e)
                const notStarted = e?.response?.data?.delivery_id?.[0] === 'Delivery not started!'
                if (notStarted) {
                    setMsg({ m: 'Түгээлт эхлээгүй байна!', s: 'warning' })
                } else { waitMsg() }
            })
    }

    return (
        <>
            <TableCell
                align="center" 
                sx={{
                    borderLeft: 1,
                    backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial',
                    '&:hover': { 
                        cursor: 'pointer', 
                        backgroundColor: 'secondary.ligth',
                        textDecoration: 'underline',
                        backgroundColor: '#f1f1f1',
                    }
                }}
                onClick={() => isDelman ? setOpen(true) : null }
            >
                {order.process ? DELIVERY_ORDER_PROCESS[order.process] : '-'}
            </TableCell>
            <Dialog open={open} onClose={handleClose}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                        p: '10px',
                    }}
                >
                    {
                        choices.map((k, index) => (
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: 'primary.light',
                                        color: 'white',
                                        textDecodation: 'underline',
                                        borderRadius: 1,
                                    },
                                    p: '2px 5px',
                                    transition: '0.15s',
                                    fontSize: '14px',
                                }}
                                onClick={() => handleUpdateStatus(k)}
                            >{DELIVERY_ORDER_PROCESS[k]}</Typography>
                        ))
                    }
                </Box>
            </Dialog>
        </>
    )
}