import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { getDeliveryZones, updateCustomerZone } from "../../../../../api/delivery"
import { useCallback, useEffect, useRef, useState } from "react"
import { scrollStyle } from "../../../../../utils/constants"
import { getCustomers } from "../../../../../api/company"
import SelectDeliveryZone from "../SelectDeliveryZone"
import TablePages from "../../../../common/TablePages"
import { isEmpty, keys } from "underscore"
import { useQuery } from "react-query"
import FilterRow from "./Filter"
import Row from "./Row"

export default function PharmoCustomers({ setCustomer, isLocationUpdated, setMsg, waitMsg }) {
	const [columns, setColumns] = useState({
		name: { label: "Нэр", order: "" },
		rd: { label: "РД", order: "" },
		credit: { label: "Зээлийн лимит", order: "" },
		location: { label: "Байршил", order: "" },
		zone: { label: "Түгээлтийн бүс", order: "" },
		isBad: { label: "Зээлээр өгөхгүй?", order: "" },
		email: { label: "И-мэйл", order: "" },
		phone: { label: "Утасны дугаар", order: "" },
		orders_cnt: { label: "Захиалга тоо", order: "" },
		orders_sum: { label: "Захиалга дүн", order: "" },
		created: { label: "Огноо", order: "" }
	})
	// ordering
	const handleOrder = (fieldName) => {
		const c = columns?.[fieldName]
		const v =
			c?.order === ""
				? fieldName
				: c?.order === fieldName
					? `-${fieldName}`
					: ""
		setColumns({ ...columns, [fieldName]: { ...c, order: v } });
	}

	const orderParam = keys(columns)?.reduce((acc, k) => {
		const cur = columns?.[k]?.order;
		if (cur !== "") {
			acc += '&ordering=' + cur
		}
		return acc
	}, "")

	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(50)
	const [filtering, setFiltering] = useState(null)

	const [partners, setPartners] = useState([])
	const [count, setCount] = useState(0)
	const getData = (res) => { setPartners(res?.data?.results || []); setCount(res?.data?.count || 0) }
	const { data, refetch: refetchAllCusts } = useQuery(["get_customers", page, pageSize, orderParam, filtering], getCustomers, { onSuccess: getData })
	useEffect(() => { if (isLocationUpdated) { refetchAllCusts() } }, [isLocationUpdated])
	//console.log("D: ", data)
	useEffect(() => {
		if (!isEmpty(data?.data)) { setPartners(data?.data?.results || []) }
		else { setPartners([]) }
	}, [data?.data])

	const selectCustomer = (customer) => { setCustomer(customer) }
	const hhRef = useRef(null)

	// select delivery zone
	const [deliveryZones, setDeliveryZones] = useState([])
	const fetchDeliveryZones = () => {
		getDeliveryZones()
			.then(res => {
				setDeliveryZones([...res?.data, {id: 0, name: 'Болих'}] || [])
			})
			.catch(e => {
				console.log("ERR: ", e)
				waitMsg()
			})
	}
	useEffect(() => { fetchDeliveryZones() }, [])

	const [selectedCustId, setSelectedCustId] = useState(null)
	const handleSelectCustId = (e, custId) => {
		setSelectedCustId(custId)
		e?.stopPropagation()
	}
	const [zoneBoxOpen, setDirectionBoxOpen] = useState(false)
	const zoneBoxOff = () => { setDirectionBoxOpen(false); setSelectedCustId(null) }
	useEffect(() => {
		if (selectedCustId) {
			if (deliveryZones.length <= 0) {
				waitMsg()
			} else {
				setDirectionBoxOpen(true)
			}
		}
	}, [selectedCustId])
	const handleSelectDeliveryZone = useCallback((zoneId) => {
		let data = { customer_ids: [selectedCustId], of_supplier: false }
		if (zoneId) {
			data = { ...data, zone_id: zoneId }
		} else if (zoneId === 0) {
			data = { ...data, zone_id: null }
		}
		updateCustomerZone(data)
			.then(res => {
				if (res?.data) {
					refetchAllCusts()
					setMsg({ m: 'Амжилттай!', s: 'success' })
				}
			})
			.catch(e => {
				waitMsg()
			})
			.finally(() => { zoneBoxOff() })
	}, [selectedCustId])

	return (
		<>
			<TableContainer
				sx={{ 
					mt: 1,
					height: '100%',
					...scrollStyle,
					'&::-webkit-scrollbar': { width: 8, height: 8 }
				}}
			>
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow
							sx={{
								"& > th": {
									backgroundColor: "secondary.light",
									borderRight: "1px solid white",
									textAlign: "center",
									fontSize: "13px",
									color: "white",
									p: "4px"
								},
							}}
						>
							<TableCell ref={hhRef}>
								<Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>№</Typography>
							</TableCell>
							<TableCell sx={{ cursor: 'pointer' }} onClick={() => { handleOrder('name')}}>Байгууллагын нэр</TableCell>
							<TableCell sx={{ cursor: 'pointer' }} onClick={() => { handleOrder('rd')}}>Байгууллагын РД</TableCell>
							<TableCell sx={{ cursor: 'pointer' }} onClick={() => { handleOrder('branch')}}>Салбарын нэр</TableCell>
							<TableCell sx={{ cursor: 'pointer' }} onClick={() => { handleOrder('credit')}}>Зээлийн лимит</TableCell>
							<TableCell sx={{ cursor: 'pointer' }} onClick={() => { handleOrder('isBad')}}>Зээлээр өгөхгүй?</TableCell>
							{/* <TableCell sx={{ cursor: 'pointer' }} onClick={() => { handleOrder('email')}}>Дэлгэрэнгүй</TableCell> */}
							<TableCell sx={{ cursor: 'pointer' }} onClick={() => { handleOrder('zone')}}>Түгээлтийн бүс</TableCell>
							<TableCell sx={{ cursor: 'pointer' }} onClick={() => { handleOrder('location')}}>Байршил</TableCell>
							{/* <TableCell sx={{ cursor: 'pointer' }} onClick={() => { handleOrder('orders_cnt')}}>Захиалгын тоо</TableCell>
							<TableCell sx={{ cursor: 'pointer' }} onClick={() => { handleOrder('orders_sum')}}>Захиалгын дүн</TableCell>*/}
							<TableCell sx={{ cursor: 'pointer' }} onClick={() => { handleOrder('created')}}>Огноо</TableCell>
						</TableRow>
						<FilterRow 
							filtering={filtering} 
							setFiltering={setFiltering} 
							hhRef={hhRef}
						/>
					</TableHead>
					<TableBody>
						{
							partners?.map((row, idx) => (
								<Row
									key={`${idx}`}
									row={row}
									setPartners={setPartners}
									idx={idx}
									page={page}
									pageSize={pageSize}
									selectCustomer={selectCustomer}
									deliveryZones={deliveryZones}
									handleSelectCustId={handleSelectCustId}
								/>
							))
						}
					</TableBody>
					<TablePages
						count={count}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						colSpan={keys(columns)?.length + 1}
					/>
				</Table>
			</TableContainer>
			{
				deliveryZones.length > 0 &&
					<SelectDeliveryZone
						zoneBoxOpen={zoneBoxOpen}
						zoneBoxOff={zoneBoxOff}
						deliveryZones={deliveryZones}
						handleSelectDeliveryZone={handleSelectDeliveryZone}
					/>
			}
		</>
	)
}