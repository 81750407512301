import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { scrollStyle } from '../../../../../../../utils/constants'
import DeliveryZoneRow from './DeliveryZoneRow'

const headCols = ['№', 'Түгээлтийн бүс', 'Хариуцсан түгээгч', 'Засах', 'Устгах']
export default function DeliveryZoneTable({ zones, setSelectedZone }) {
    return (
        <TableContainer
            sx={{ 
                mt: 1,
                boxShadow: 3,
                borderRadius: 1, 
                height: '400px', 
                maxHeight: '400px',
                overflowY: 'auto',
                ...scrollStyle
            }}
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow
                        sx={{
                            '& > th': {
                                color: 'white',
                                textAlign: 'center',
                                backgroundColor: 'secondary.light',
                                borderRight: '1px solid white',
                                p: '2px 4px'
                            }
                        }}
                    >
                        {
                            headCols.map((c, idx) => 
                                <TableCell key={idx}>{c}</TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        zones?.map((z, idx) => 
                            (
                                <DeliveryZoneRow
                                    key={z?.id}
                                    zone={z}
                                    idx={idx}
                                    setSelectedZone={setSelectedZone}
                                />
                            )
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
