import { TableCell, TableFooter, TableRow, Typography } from '@mui/material'
import { toPrice } from '../../../../utils/functions'

export default function Footer({ totalCount, totalPrice }) {
    return (
        <TableFooter style={{ position: 'sticky', bottom: 0 }}>
            <TableRow sx={{ '&>td': { p: '4px' } }}>
                <TableCell colSpan={2} sx={{ borderColor: 'transparent' }}></TableCell>
                <TableCell colSpan={2} align='right'>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', fontStyle: 'italic' }}>Нийт тоо, ширхэг:</Typography>
                </TableCell>
                <TableCell align='right'>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{totalCount}</Typography>
                </TableCell>
                <TableCell />
            </TableRow>
            <TableRow sx={{ '&>td': { p: '4px' } }}>
                <TableCell colSpan={2} sx={{ borderColor: 'transparent' }}></TableCell>
                <TableCell colSpan={2} align='right'>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', fontStyle: 'italic' }}>Үнийн дүн:</Typography>
                </TableCell>
                <TableCell align='right'>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{toPrice(totalPrice)}</Typography>
                </TableCell>
                <TableCell />
            </TableRow>
        </TableFooter>
    )
}
