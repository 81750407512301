import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@mui/icons-material'
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material'
import OrderingCell from './OrderingCell'
import { useOrderListCtx } from '../..'
import FilterRow from './FilterRow'
import { useRef } from 'react'

const getIcon = (chc, intr) => chc ? <CheckBox sx={{ color: 'white', fontSize: '18px' }} /> : intr ? <IndeterminateCheckBox sx={{ color: 'white', fontSize: '18px' }} /> : <CheckBoxOutlineBlank sx={{ color: 'white', fontSize: '18px' }} />

export default function TableHeadRow({ showns, orders }) {
    const { sels, selectAll } = useOrderListCtx()

    const notAvailable = (o) => ['C', 'S'].includes(o.status) || ['A', 'O'].includes(o.process)
    const oIds = orders?.filter(o => !notAvailable(o)).map(o => o?.id)

    const chc = sels?.length > 0 && !oIds?.find(i => !sels.includes(i))
    const intr = sels?.length > 0 && oIds?.find(i => !sels.includes(i))

    const hhRef = useRef(null)

    return (
        <TableHead>
            <TableRow
                sx={{ 
                    '& > th': { 
                        backgroundColor: 'secondary.light',
                        borderRight: '1px solid white',
                        fontSize: '13px',
                        color: 'white',
                        p: '2px 4px'
                    }
                }}
                ref={hhRef}
            >
                <TableCell 
                    align='center' 
                    sx={{ p: 0 }}
                >
                    <Checkbox 
                        checked={chc} 
                        indeterminate={intr} 
                        onChange={() => { selectAll(oIds)}} 
                        sx={{ 
                            textAlign: 'center', 
                            p: '2px', 
                            color: 'white'
                        }}
                        icon={getIcon(chc, intr)||<CheckBox sx={{ color: 'white', fontSize: '18px' }} />}
                    />
                </TableCell>
                <TableCell align='center'>№</TableCell>
                <OrderingCell />
                {
                    showns.map(c => <TableCell key={c.field_name} align='center'>{c.name}</TableCell>)
                }
            </TableRow>
            <FilterRow 
                showns={showns} 
                hhRef={hhRef}
            />
        </TableHead>
    )
}