import { Box, Button, ListItemText, Paper, Typography } from '@mui/material'
import { COMPANY_TYPES } from '../../../../utils/constants'
import { addCompanyInfo } from '../../../../api/company'
import { AppRegistration } from '@mui/icons-material'
import { getCompanyName } from '../../../../api/auth'
import { getToast } from '../../helpers/ToastAlert'
import PharmoLogo from '../../../common/PharmoLogo'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import CompanyInfo from './CompanyInfo'
import AddPassword from './AddPassword'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const getFileExt = (str) => {
    const parts = str?.split('/')
    if (parts?.length === 2) return parts[1].toUpperCase()
}
const validationSchema = Yup.object().shape({
    name: Yup.string().max(100, 'Нэр хэт урт байна! 150 тэмдэгтээс бага байх ёстой!').required('Нэр оруулна уу!'),
    public_name: Yup.string().max(100, 'Нэр хэт урт байна! 150 тэмдэгтээс бага байх ёстой!').required('Нэр оруулна уу!'),
    rd: Yup.string().matches(/^\d{7}$/, 'Регистр буруу байна!').required('Регистр оруулна уу!'),
    cType: Yup.string().oneOf(Object.keys(COMPANY_TYPES), 'Үйл ажиллагааны чиглэл буруу байна!').required('Үйл ажиллагааны чиглэл сонгоно уу!'),
    note: Yup.string().max(255, 'Нэмэлт тайлбар хэт урт байна! 255 тэмдэгтээс бага байх ёстой!').nullable(),
    referral_code: Yup.string().min(6, 'Урилгын код 6 тэмдэгтээс бүрдсэн байх ёстой!').max(6, 'Урилгын код 6 тэмдэгтээс бүрдсэн байх ёстой!').nullable(),
})

export default function RegistrationForm({ email }) {
    const navi = useNavigate()
    const [msg, setMsg] = useState(null)    
    const alertSuccess = () => { setMsg({ m: 'Амжилттай илгээлээ!', s: 'success' }) }
    const msgOff = () => {  setMsg(null); navi('/signin') }
    const [error, setError] = useState(null)
    const handleErrOn = (err) => { setError(err) }
    const handleErrOff = () => { setError(null) }

    const [licenseFiles, setLicenseFiles] = useState([])
    const [logo, setLogo] = useState(null)

    // pwd dialog
    const [open, setOpen] = useState(false)
    const handleClosePwdDialog = () => { setOpen(false) }
    const [data, setData] = useState(null)
    const handleAddInfo = () => {
        addCompanyInfo(data)
            .then(res => {
                console.log("COMP: ", res)
                alertSuccess()
            })
            .catch(e => {
                console.log("ERR: ", e)
                const erd = e?.response?.data
                if (erd?.referral_code?.[0] === 'bad') { handleErrOn("Урилгын код буруу байна!") }
                if (erd?.rd?.[0] === 'bad') { handleErrOn("Регистрийн дугаар буруу байна!") }
                if (erd?.rd?.[0] === 'exist') { handleErrOn("Регистрийн дугаар бүртгэлтэй байна!") }
                if (erd?.phone) { handleErrOn("Утасны дугаар буруу байна!") }
                if (erd?.license?.error?.includes('file_size_exceed')) {
                    const fns = erd?.license?.files?.join(', ')
                    const limit = erd?.license?.limit
                    handleErrOn(`${fns} нэртэй файл${erd?.license?.files?.length>1 ? 'ууд' : ''}ын хэмжээ ${limit} MB-с их байна!`)
                }
                if (erd?.license?.error?.includes('file_type_not_allowed')) {
                    const fns = erd?.license?.files?.join(', ')
                    const types = erd?.license?.limit?.map(ft => getFileExt(ft))?.join(', ')
                    handleErrOn(`${fns} нэртэй файл${erd?.license?.files?.length>1 ? 'ууд' : ''}ын төрөл буруу! ${types} төрөлтэй файлуудыг хавсаргана уу!`)
                }
                //if (erd?.phone2) { handleErrOn("Утасны дугаар буруу байна!") }
                //if (erd?.phone3) { handleErrOn("Утасны дугаар буруу байна!") }
                if (erd?.detail?.includes('username/password')) { handleErrOn('Нууц үг буруу байна!') }
            })
    }

    const { handleSubmit, getFieldProps, setFieldValue, values, errors, touched } = useFormik({
        initialValues: {
            name: '',
            public_name: '',
            rd: '',
            cType: '',
            note: null,
            referral_code: null,
        },
        onSubmit: (vals) => {
            if (!licenseFiles?.length) {
                { setError('Тусгай зөвшөөрлийн файл хавсаргана уу!') }
                return
            } else if (!logo) {
                { setError('Лого хавсаргана уу!') }
                return
            }
            const data1 = { ...vals, logo, license: licenseFiles, email }
            const dataToSend = {
                    ...data1,
                    note: vals?.note === '' ? null : vals.note,
                    referral_code: vals?.referral_code === '' ? null : vals.referral_code,
                }
            setData(dataToSend)
            setOpen(true)
        },
        validationSchema,
        validateOnChange: false
    })

    useEffect(() => {
        if (values?.rd) {
            if (values.rd.length == 7 && /^\d{7}$/.test(values.rd)) {
                getCompanyName(values.rd).then(res => {
                    if (res?.data?.name) {
                        setFieldValue('name', res?.data?.name)
                    }
                }).catch(e => {
                    console.log("ERR: ", e)
                })
            }
        }
    }, [values?.rd])

    const getErrors = (name) => {
        const isErr = Object.keys(errors).includes(name) && Object.keys(touched).includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }

    const handleKeyDown = (e) => {
        const n = e?.target?.id
        if (n === 'rd') {
            if (['ArrowRight', 'Enter'].includes(e.code)) {
                const nf = document.getElementById('name')
                if (nf) { nf.focus() }
            }
            if (e.code === 'ArrowDown') { document.getElementById('cType')?.focus() }
        }
        if (n === 'name' && e.code === 'ArrowLeft') { document.getElementById('rd')?.focus() }
    }

    return (
        <Box
            component={Paper}
            elevation={5}
            sx={{ 
                height: '100%',
                minHeight: '500px',
                width: '500px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                p: 2,
                pt: 4,
                position: 'relative'
            }}
            //ref={rf}
        >
            <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                <PharmoLogo />
            </Box>
            {getToast(Boolean(error), handleErrOff, error, 'warning' )}
            {getToast(Boolean(msg?.m), msgOff, msg?.m, 'success' )}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 3 }}>
                <AppRegistration sx={{ fontSize: '35px', color: 'secondary.light', mr: 1 }}/>
                <ListItemText
                    primary={<Typography fontSize='18px' fontStyle='italic' sx={{ color: 'secondary.light' }}>Бүртгэл гүйцээх</Typography>}
                    secondary={<Typography fontSize='14px' fontStyle='italic' sx={{ color: 'primary.light' }}>Мэдээллээ бүрэн бөглөхийг хүсье.</Typography>}
                />
            </Box>
            <CompanyInfo 
                getErrors={getErrors}
                getFieldProps={getFieldProps}
                licenseFiles={licenseFiles}
                setLicenseFiles={setLicenseFiles}
                logo={logo}
                setLogo={setLogo}
                setFieldValue={setFieldValue}
                cType={values?.cType}
                handleKeyDown={handleKeyDown}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 1, mt: 0 }}>
                <Button
                    onClick={ handleSubmit }
                    size='small'
                    variant='contained'
                    sx={{ p: '4px', width: '150px' }}
                >Баталгаажуулах</Button>
            </Box>
            {
                open &&
                    <AddPassword
                        open={open}
                        handleClosePwdDialog={handleClosePwdDialog}
                        pwd={data?.password||''}
                        setData={setData}
                        handleAddInfo={handleAddInfo}
                    />
            }
        </Box>
    )
}
