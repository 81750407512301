import { getSupplierStaffsNamesRoles } from "../../../../api/staff"
import { createContext, useContext, useState } from "react"
import { getToast } from "../../../auth/helpers/ToastAlert"
import { isTokenValid } from "../../../../utils/functions"
import { getDeliveryZones } from "../../../../api/delivery"
import { useQuery } from "react-query"
import OrderTabs from "./OrderTabs"
import Orders from "./Orders"

const OrderListCtx = createContext()
export const useOrderListCtx = () => useContext(OrderListCtx)
export default function Order() {
	const { role } = isTokenValid()
	const isAB = ["A", "B"].includes(role)
	const { data: staffData } = useQuery("get_supplier_staffs_names_roles", getSupplierStaffsNamesRoles, { enabled: ["A", "B"].includes(role) })
	const { data: zonesData } = useQuery("get_zones_data", getDeliveryZones)
	const zones = zonesData?.data||[]

	const staffs = staffData?.data?.reduce((acc, cur) => {
		if (!acc["delmans"]) {
			acc["delmans"] = []
		}
		if (!acc["packers"]) {
			acc["packers"] = []
		}
		if (!acc["sellers"]) {
			acc["sellers"] = []
		}
		if (cur?.role === "D") { acc["delmans"].push(cur) }
		if (cur?.role === "S") { acc["sellers"].push(cur) }
		if (cur?.role === "P") { acc["packers"].push(cur)}
		return acc
	}, {})
	const { delmans, packers, sellers } = staffs ?? {}

	// selected order ids
	const [sels, setSels] = useState([])
	const handleSelect = (id) => {
		if (sels.includes(id)) {
			setSels(sels.filter((s) => s !== id))
		} else {
			setSels([...sels, id])
		}
	}

	const selectAll = (ids) => {
		const f = ids?.find((i) => !sels.includes(i))
		if (f) {
			const newIds = ids.filter((id) => !sels.includes(id))
			setSels((prev) => [...prev, ...newIds])
		} else {
			setSels((prev) => prev.filter((id) => !ids.includes(id)))
		}
	}

	// pagination
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(50)

	// filter
	const [filters, setFilters] = useState(null)
	const handleFilters = (value) => {
		setFilters(value)
		setPage(1)
	}
	const hasFilter = filters?.length > 0

	// ordering
	const [ordering, setOrdering] = useState("ordering=-id")
	const handleOrdering = () => {
		setOrdering(ordering === "ordering=-id" ? "ordering=id" : "ordering=-id")
	}

	// alert
	const [alert, setAlert] = useState(null)
	const alertOff = () => {
		setAlert(null)
	}

	const [msg, setMsg] = useState(null)
	const msgOff = () => { setMsg(null) }
    const successMsg = () => { setMsg({ m: 'Амжилттай!', s: 'success' }) }
    const waitMsg = () => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) }

	const [tab, setTab] = useState(1)

	return (
		<OrderListCtx.Provider
			value={{
				delmans,
				packers,
				sellers,
				staffs,
				zones,
				role,
				isAB,
				sels,
				selectAll,
				handleSelect,
				// query
				filters,
				handleFilters,
				hasFilter,
				ordering,
				handleOrdering,
				page,
				setPage,
				pageSize,
				setPageSize,
				// alert
				setAlert,
				// new msg
				msg,
				setMsg,
				msgOff,
				successMsg,
				waitMsg,
			}}
		>
			{getToast(Boolean(alert?.m), alertOff, alert?.m, alert?.s, 2000)}
			{getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 2000)}
			<OrderTabs
				filters={filters}
				setFilters={setFilters}
				setPage={setPage}
				tab={tab}
				setTab={setTab}
			/>
			<Orders
				tab={tab}				
			/>
		</OrderListCtx.Provider>
	)
}
