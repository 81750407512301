import { isTokenValid } from "../../../utils/functions"
import { Box, Tab, Tabs } from "@mui/material"
import CompanyInfo from "./CompanyInfo"
import { useState } from "react"
import Setting from "./Setting"

export default function Settings() {
    const { role } = isTokenValid()
    const isAB = ['PA', 'PM'].includes(role)
    const [tab, setTab] = useState(isAB ? 1 : 2)
    const handleChange = (e, newValue) => { setTab(newValue) }

    return (
        <Box sx={{ width: '100%', pl: 1 }}>
            <Tabs
                value={tab}
                onChange={handleChange}
                sx={{
                    minHeight: '28px',
                    height: '28px',
                    mb: '4px',
                    '& .MuiTab-root': { minHeight: '25px', height: '25px' }
                }}
            >
                { 
                    isAB &&
                        <Tab
                            label='Ерөнхий мэдээлэл'
                            value={1}
                        />
                }
                <Tab
                    label='Тохиргоо'
                    value={2}
                />
            </Tabs>
            {
                tab === 1
                    ?   <CompanyInfo />
                    :   <Setting />
            }
        </Box>
    )
}