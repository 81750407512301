import DeleteIconBtn from '../../../../../../utils/DeleteIconBtn'
import { apiUrl } from '../../../../../../global/axiosBase'
import { Box, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'

export default function License({ f, setFieldValue, error }) {
    const openOnNewTab = (lnk) => { window.open(lnk, '_blank', 'noopener,noreferrer') }
    const onCancel = (id) => {
        const n = f.filter(l => l?.id !== id)
        setFieldValue('license', n)
    }
    const [hoverId, setHoverId] = useState(null)

    return (
        <Box sx={{ mb: 3 }}>
            <Typography sx={{ fontSize: f?.length > 0 ? '12px' : '15px', color: '#666', ml: 2 }}>{f?.length > 0 ? 'Тусгай зөвшөөрөл' : 'Тусгай зөвшөөрөл хавсаргаагүй!'}</Typography>
            {
                f?.length > 0
                    ?   f.map((l, idx) => {
                            return <Box 
                                        key={l?.id}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            '&:hover': { backgroundColor: '#f2f2f2', cursor: 'pointer' }
                                        }}
                                        onMouseEnter={_ => setHoverId(idx)}
                                        onMouseLeave={_ => setHoverId(null)}
                                    >
                                        <Box 
                                            sx={{ 
                                                display: 'flex', 
                                                flexDirection: 'row', 
                                                alignItems: 'center',
                                                pl: 1 
                                            }}
                                        >
                                            <Tooltip title='Устгах' placement='left'>
                                                <DeleteIconBtn
                                                    idx={idx}
                                                    hoverId={hoverId}
                                                    onClose={_ => setHoverId(null)}
                                                    onYes={_ => onCancel(l?.id)}
                                                />
                                            </Tooltip>
                                            <Typography fontSize='15px' sx={{ maxWidth: '80%' }} onClick={() => { openOnNewTab(apiUrl + l?.url) }}>{l?.url?.split('/')?.pop()||''}</Typography>
                                        </Box>
                                    </Box>
                        })
                    :   null
            }
            {error && <Typography sx={{ fontSize: '15px', color: 'red' }}>{error}</Typography>}
        </Box>
    )
}