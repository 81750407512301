import { Box, Typography } from '@mui/material'

export default function Popup ({ name, color }) {
    return (
        <Box 
            sx={{
                position: 'absolute',
                bottom: 30,
                left: 10,
                borderRadius: '5px',
                border: `1px solid ${color}`,
                backgroundColor: 'white',
                boxShadow: 1,
                width: 'fit-content',
                p: '1px 4px',
            }}
            
        >
            <Typography sx={{ fontSize: '13px', fontWeight: 600, color }} noWrap>{name||'-'}</Typography>
        </Box>
    )
}