import { Dashboard, ShoppingCart, People, Settings, ProductionQuantityLimitsRounded, List, LocalShipping, Redeem, Dvr, Assessment, Engineering } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogTitle, Typography, useTheme } from '@mui/material'

export const boardHeight = 'calc(100vh - 50px)'

export const menus = [
	{
		icon: <ShoppingCart sx={{ color: 'secondary.light' }} />,
		name: 'Захиалга',
		route: 'orders',
	},
	{
		icon: <Redeem sx={{ color: 'secondary.light' }} />,
		name: 'Promotion',
		route: 'promotion',
	},
	{
		icon: <People sx={{ color: 'secondary.light' }} />,
		name: 'Харилцагч',
		route: 'customers',
	},
	{
		icon: <Dashboard sx={{ color: 'secondary.light' }} />,
		name: 'Бараа',
		route: 'products/list',
		subMenus: [
		{
			icon: <ProductionQuantityLimitsRounded sx={{ color: 'secondary.light' }} />,
			name: 'Жагсаалт',
			route: 'products/list',
		},
		{
			icon: <List sx={{ color: 'secondary.light' }} />,
			name: 'Ангилал',
			route: 'products/category',
		},
		]
	},
	{
	   icon: <LocalShipping sx={{ color: 'secondary.light' }} />,
	   name: 'Түгээлт',
	   route: 'delivery',
	},
	{
		icon: <Assessment sx={{ color: 'secondary.light' }} />,
		name: 'Тайлан',
		route: 'report',
	},
	{
		icon: <Engineering sx={{ color: 'secondary.light' }} />,
		name: 'Ажилтан',
		route: 'staff',
	},
	{
		icon: <Settings sx={{ color: 'secondary.light' }} />,
		name: 'Тохиргоо',
		route: 'settings',
	},
	{
		icon: <Dvr sx={{ color: 'secondary.light' }} />,
		name: 'Лог',
		route: 'system_log',
	},
]

export const VerifyDialog = ({ msg, open, setOpen, handleOk }) => {
	const handleClose = () => { setOpen(false) }
	const theme = useTheme()
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle><Typography sx={{ fontSize: '17px', color: theme.palette.primary.light, fontWeight: 'bold' }}>{msg}</Typography></DialogTitle>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
				<Button onClick={handleOk} size='small' variant='contained' sx={{ backgroundColor: theme.palette.secondary.light }}>Тийм</Button>
				<Button onClick={handleClose} size='small' variant='contained'>Үгүй</Button>
			</DialogActions>
		</Dialog>
	)
}