import { removeDeliveryZone } from "../../../../../../api/delivery"
import { getToast } from "../../../../../auth/helpers/ToastAlert"
import { Box, Button, Dialog, Typography } from "@mui/material"
import AddUpdateDeliveryZone from "./AddUpdateDeliveryZone"
import DeliveryZoneTable from "./DeliveryZoneTable"
import { useDeliveryCtx } from "../.."
import { useState } from "react"

export default function DeliveryZone() {
    const { zoneData, fetchZones, delmanNames: delmans } = useDeliveryCtx()
    const [open, setOpen] = useState(false)

    const [selectedZone, setSelectedZone] = useState(null)
    const handleDeleteZone = () => {
        removeDeliveryZone(selectedZone?.id)
            .then(_ => {
                fetchZones()
                setMsg({ m: 'Амжилттай устгалаа!', s: 'success' })
            })
            .catch(e => {
                console.log("ERR: ", e)
                waitMsg()
            })
            .finally(() => {
                setSelectedZone(null)
            })
    }

    // toast msg
    const [msg, setMsg] = useState(null)
    const msgOff = () => { setMsg(null) }
    const waitMsg = () => {setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })}

    return (
        <>
            <Button
                sx={{ 
                    m: 1, p: '2px 12px',
                    color: 'white',
                    backgroundColor: 'primary.light',
                    '&:hover': { backgroundColor: 'primary.main' }
                }}
                size='small'
                variant='contained'
                onClick={() => setOpen(true)}
            >
                Түгээлтийн бүсүүд ({zoneData?.length})
            </Button>
            <Dialog 
                open={open} 
                onClose={() => setOpen(false)}
            >
                <Box
                    sx={{
                        width: '400px',
                        height: '100%',
                        p: 1
                    }}
                >
                    {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s)}
                    <AddUpdateDeliveryZone
                        fetchZones={fetchZones}
                        msg={msg}
                        setMsg={setMsg}
                        msgOff={msgOff}
                        waitMsg={waitMsg}
                        zoneToEdit={selectedZone}
                        setZoneToEdit={setSelectedZone}
                    />
                    <DeliveryZoneTable
                        zones={zoneData}
                        setSelectedZone={setSelectedZone}
                        delmans={delmans}
                    />
                    <Dialog open={Boolean(selectedZone?.is_delete)} onClose={() => setSelectedZone(null)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                p: 4,
                                minWidth: '300px',
                            }}
                        >
                            <Typography>
                                <span style={{ fontWeight: 'bold' }}>{selectedZone?.name}</span> нэртэй түгээлтийн бүсийг устгах уу?
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 2,
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: 'primary.main',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleDeleteZone}
                                >Тийм</Typography>
                                <Typography
                                    sx={{
                                        color: 'secondary.light',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setSelectedZone(null)}
                                >Үгүй</Typography>
                            </Box>
                        </Box>
                    </Dialog>
                </Box>
            </Dialog>
        </>
    )
}
