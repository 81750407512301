import { Box, Typography, useTheme } from '@mui/material'
import { AddPhotoAlternate } from '@mui/icons-material'
import { useState } from 'react'
import Thumbs from './Thumbs'

export default function LicenseInput({ licenseFiles }) {
    const theme = useTheme()
    const [isHovered, setIsHovered] = useState(false)
    
    return (
        <Box
            sx={{
                border: '2px dashed grey', borderRadius: '10px', 
                p: 1, height: '120px', display: 'flex', flexDirection: 'column',
                justifyContent: 'center', alignItems: 'center',
                '&: hover': { borderColor: theme.palette.secondary.light, cursor: 'pointer' }
            }}
            onMouseOver={() => { setIsHovered(true) }}
            onMouseLeave={() => { setIsHovered(false) }}
        >
            <Typography fontSize='15px' sx={{ color: isHovered ? theme.palette.secondary.light : theme.palette.primary.light }}>Тусгай зөвшөөрөл:</Typography>
            {
                licenseFiles?.length > 0
                    ?   <Thumbs licenseFiles={licenseFiles} />
                    :   <AddPhotoAlternate sx={{ fontSize: '50px', color: 'grey', opacity: isHovered ? 1 : 0.5 }} />
            }
        </Box>
    )
}