import SmallTextField from '../../../../../utils/Fields/SmallTexField'
import { Grid } from '@mui/material'
import { useFormik } from 'formik'
import InfoBox from '../InfoBox'
import * as Yup from 'yup'

const validationSchema = Yup.object({
    email: Yup.string().email('Алдаатай имэйл хаяг байна!').required('Имэйл хаяг оруулна уу!').nullable(),
    phone: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').required('Утасны дугаар оруулна уу!').nullable()
})

export default function ContactInfo({ info, refetch, setMsg, updateCompanyInfo }) {
    const { email, phone, phone2, phone3 } = info
    const { handleSubmit, getFieldProps, errors, touched } = useFormik({
        initialValues: { email, phone, phone2, phone3 },
        onSubmit: (values) => {
            updateCompanyInfo(values)
                .then(_ => {
                    setMsg({ m: 'Амжилттай!', s: 'success' })
                    refetch()
                })
                .catch(e => { 
                    setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
                    console.log("ERR: ", e)
                })
        },
        validationSchema,
        validateOnChange: false
    })

    const getErrors = (name) => {
        const err = errors[name] && touched[name]
        return {
            error: err,
            helperText: err ? errors[name] : ''
        }
    }

    return (
        <InfoBox
            label='Холбоо барих мэдээлэл'
            handleUpdate={handleSubmit}
        >
            <Grid item xs={6}>
                <SmallTextField
                    label='Имэйл'
                    name='email'
                    {...getFieldProps('email')}
                    {...getErrors('email')}
                    size='small'
                />
            </Grid>
            <Grid item xs={6}>
                <SmallTextField
                    label='Утасны дугаар'
                    name='phone'
                    {...getFieldProps('phone')}
                    {...getErrors('phone')}
                    size='small'
                />
            </Grid>
            <Grid item xs={6}>
                <SmallTextField
                    label='Утасны дугаар 2'
                    name='phone2'
                    {...getFieldProps('phone2')}
                    {...getErrors('phone2')}
                    size='small'
                />
            </Grid>
            <Grid item xs={6}>
                <SmallTextField
                    label='Утасны дугаар 3'
                    name='phone3'
                    {...getFieldProps('phone3')}
                    {...getErrors('phone3')}
                    size='small'
                />
            </Grid>
        </InfoBox>
    )
}
