import { scrollStyle } from '../../../../../../../utils/constants'
import { selectPacker } from '../../../../../../../api/staff'
import { Box, Typography } from '@mui/material'
import { useOrderListCtx } from '../../..'
import TableCellMenu from './TableCellMenu'

const getName = (packers, id) => packers?.find(s => s.id === id)?.name||'-'

export default function SelectPackerCell({ row, sels, refetch }) {
    const { packers, isAB, setAlert } = useOrderListCtx()
    // select staff
    const choices = packers?.filter(s => s.id !== row?.delman)||[]
    const handleSelect = (packer_id = null) => {
        const inSels = sels?.includes(row?.id)
        const order_ids = inSels ? sels : [row?.id]
        selectPacker({ order_ids, packer_id })
                .then(_ => { refetch() })
                .catch(err => { 
                    console.log("ERR: ", err)
                    setAlert({ s: 'warning', m: 'Түр хүлээгээд дахин оролдоно уу!' })
                })
                .finally(() => {})
    }

    const name = getName(packers, row['packer'])

    return (
        <TableCellMenu
            current={<Typography sx={{ textAlign: 'center', fontSize: '13px' }}>{name}</Typography>}
            dropMenu={
                <Box
                    sx={{
                        display: isAB ? 'flex' : 'none', 
                        flexDirection: 'column', 
                        maxHeight: '300px',
                        backgroundColor: 'white', 
                        boxShadow: 8,
                        overflowY: 'auto', 
                        overflowX: 'hidden', 
                        ...scrollStyle,
                        '&::-webkit-scrollbar': { width: 5 }, 
                        width: '100%'
                    }}
                >
                    {choices?.length > 0 && choices.map(c => (
                        <Typography
                            key={`${c.id}`}
                            onClick={ (e) => { handleSelect(c.id) } }
                            sx={{
                                borderBottom: '1px solid transparent', borderRadius: 0, fontSize: '13px', p: '4px 8px',
                                '&:hover': { cursor: 'pointer', backgroundColor: '#f1f1f1', borderBottomColor: '#ad5555' }
                            }}
                        >{getName(packers, c.id)}</Typography>
                    ))}
                    {
                        row['packer'] !== null &&
                            <Typography
                                onClick={ () => { handleSelect() } }
                                sx={{
                                    borderBottom: '1px solid transparent', borderRadius: 0, fontSize: '13px', p: '4px 8px',
                                    '&:hover': { cursor: 'pointer', backgroundColor: '#f1f1f1', borderBottomColor: '#ad5555' },
                                    color: 'secondary.light'
                                }}
                            >Болих</Typography>
                    }
                </Box>
            }
        />
    )
}
