import AxiosWithToken from "../global/axiosBase"

export const getUserSettings = () => {
    return AxiosWithToken.get('auth/user_settings/')
}

export const updateBasketClearSettings = (data) => {
    return AxiosWithToken.patch('auth/user_settings/', data)
}

export const getUserInfo = () => {
    return AxiosWithToken.get('auth/user_info/')
}

