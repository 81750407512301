import SmallTextField from '../../../../../utils/Fields/SmallTexField'
import { updateAdminInfo } from '../../../../../api/auth'
import { Grid } from '@mui/material'
import { useFormik } from 'formik'
import InfoBox from '../InfoBox'
import * as Yup from 'yup'

const validationSchema = Yup.object({
    last_name: Yup.string().max(150, 'Овог хэт урт байна!').nullable(),
    name: Yup.string().max(150, 'Нэр хэт урт байна!').nullable(),
    phone: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').required('Утасны дугаар шаардлагатай!')
})

export default function AdminInfo({ adminInfo, refetch, setMsg }) {
    const { handleSubmit, getFieldProps, errors, touched } = useFormik({
        initialValues: { ...adminInfo },
        onSubmit: (values) => {
            updateAdminInfo(values)
                .then(res => { 
                    setMsg({ m: 'Амжилттай!', s: 'success' })
                    refetch()
                })
                .catch(e => {
                    setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
                    console.log("ERR: ", e)
                })
        },
        validationSchema,
        validateOnChange: false
    })

    const getErrors = (name) => {
        const err = errors[name] && touched[name]
        return {
            error: err,
            helperText: err ? errors[name] : ''
        }
    }

    return (
        <InfoBox
            label='Админий мэдээлэл'
            handleUpdate={handleSubmit}
        >
            <Grid item xs={6}>
                <SmallTextField
                    label='Овог'
                    name='last_name'
                    {...getFieldProps('last_name')}
                    {...getErrors('last_name')}
                    size='small'
                />
            </Grid>
            <Grid item xs={6}>
                <SmallTextField
                    label='Нэр'
                    name='name'
                    {...getFieldProps('name')}
                    {...getErrors('name')}
                    size='small'
                />
            </Grid>
            <Grid item xs={6}>
                <SmallTextField
                    label='Имэйл'
                    value={adminInfo?.email}
                    size='small'
                    inputProps={{ readOnly: true, style: { cursor: 'not-allowed' } }}
                    InputLabelProps={{ shrink: true }}
                    disabled
                />
            </Grid>
            <Grid item xs={6}>
                <SmallTextField
                    label='Утасны дугаар'
                    name='phone'
                    {...getFieldProps('phone')}
                    {...getErrors('phone')}
                    size='small'
                />
            </Grid>
        </InfoBox>
    )
}