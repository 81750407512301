import * as Yup from 'yup'
import YupPassword from 'yup-password'

export const drawerWidth = 220
export const attributeTypes = [
  { 'id': '1', 'name': 'Богино текст' },
  { 'id': '2', 'name': 'Урт текст' },
  { 'id': '3', 'name': 'Сонголттой' },
]

export const disabledColor = '#7a7a7a'

// Styles constants
export const scrollStyle = {
  '&::-webkit-scrollbar': {
    width: 8,
    height: 8,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: "#e1e1e1"
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'secondary.light',
    borderRadius: 0
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: "secondary.main",
  }
}
export const paperStyle = { borderRadius: 1, backgroundColor: 'white', boxShadow: 3, padding: 1 }

// Yup constants
YupPassword(Yup)
export const emailYup = {
  email: Yup.string().email('Алдаатай имэйл хаяг байна!').required('Имэйл хаяг оруулна уу!')
}
export const phoneYup = {
  phone: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').required('Утасны дугаар оруулна уу!')
}
export const pinYup = {
  pin: Yup.string().matches(/^\d{4}$/, '4 оронтой тоо байх ёстойг анхаарна уу!').required('Пин код үүсгэнэ үү!'),
}
export const phoneRgx = /^\d{8}$/

export const passCreateYup = {
  password1: Yup.string()
    .required('Нууц үг үүсгэнэ үү!')
    .min(8, '8-с доошгүй тэмдэгтээс бүрдсэн байх')
    .matches(/[a-zA-Z]/, 'Зөвхөн англи үсэг ашиглана!')
    .minLowercase(1, '1-с доошгүй жижиг үсэг орсон байх')
    .minUppercase(1, '1-с доошгүй том үсэг орсон байх')
    .minNumbers(1, '1-с доошгүй тоо агуулсан байх'),
  //.minSymbols(1, '1-с доошгүй тусгай тэмдэгт агуулсан байх: !?/-_ гэх мэт'),
  password2: Yup.string().oneOf([Yup.ref('password1'), null], 'Нууц үгүүд тохирохгүй байна!').required('Нууц үг давтан оруулна уу!')
}

export const pwdYup = {
  password: Yup.string()
    .required('Нууц үг оруулна уу!')
    .min(8, 'Нууц үг буруу байна!')
    .matches(/[a-zA-Z]/, 'Нууц үг буруу байна!')
    .minLowercase(1, 'Нууц үг буруу байна!')
    .minUppercase(1, 'Нууц үг буруу байна!')
    .minNumbers(1, 'Нууц үг буруу байна!'),
  //.minSymbols(1, '1-с доошгүй тусгай тэмдэгт агуулсан байх: !?/-_ гэх мэт'),
}

export const pwdChangeYup = {
  old_wd: Yup.string()
    .required('Хуучин нууц үгээ оруулна уу!'),
  new_pwd: Yup.string()
    .required('Шинэ нууц үгээ үүсгэнэ үү!')
    .min(8, '8-с доошгүй тэмдэгтээс бүрдсэн байх')
    .matches(/[a-zA-Z]/, 'Зөвхөн англи үсэг ашиглана!')
    .minLowercase(1, '1-с доошгүй жижиг үсэг орсон байх')
    .minUppercase(1, '1-с доошгүй том үсэг орсон байх')
    .minNumbers(1, '1-с доошгүй тоо агуулсан байх')
    //.minSymbols(1, '1-с доошгүй тусгай тэмдэгт агуулсан байх: !?/-_ гэх мэт')
    .notOneOf([Yup.ref('old_password')], 'Шинэ болон хуучин нууц үгүүд ижил байна!'),
  new_pwd2: Yup.string().oneOf([Yup.ref('password1'), null], 'Нууц үгүүд тохирохгүй байна!')
}

export const otpYup = {
  otp: Yup.string()
    .required('Кодоо оруулна уу!')
    .matches(/^[0-9]+$/, "Зөвхөн тоо агуулсан байх ёстой!")
    .min(6, '6 оронтой код байх ёстойг анхаарна уу!')
    .max(6, '6 оронтой код байх ёстойг анхаарна уу!')
}

// check if product filters object is empty (filters values all are array)
export const isFiltersEmpty = (filters) => !Object.values(filters).some(e => e.length > 0)

export const SUPPLIER_ROLES = ['A', 'B', 'S', 'D', 'P']
export const PHARMA_ROLES = ['PA', 'PM']
export const STAFFS = ['PM', 'S', 'D', 'B', 'P']
export const ADMINS = ['PA', 'A']
export const COMPANY_TYPES = {
  P: 'Эмийн сан',
  S: 'Эм ханган нийлүүлэгч',
}
export const ISCOMPANIES = ['true', 'false']
export const ISCOMPANIESCUST = { 'true': 'Компани', 'false': 'Хувь хүн', 'null': 'Тодорхойгүй' }
