import { Box, IconButton, InputAdornment, NativeSelect, TextField } from "@mui/material"
import { getToast } from "../../../../auth/helpers/ToastAlert"
import { Close, Search } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { useTheme } from "@emotion/react"

const SEARCH_FIELDS = [
    { field: 'cust', label: 'Захиалагч', placeholder: 'Нэр эсвэл регистр' },
    { field: 'customer', label: 'Харилцагч', placeholder: 'Нэр эсвэл регистр' },
    { field: 'orderNo', label: 'Захиалагын дугаар', placeholder: 'Хайх' },
]

export default function SearchInputTab({ setFilters, setPage, tab }) {
    const theme = useTheme()
    const [value, setValue] = useState(null)
    const [error, setError] = useState(null)

    const fields = tab === 1 ? SEARCH_FIELDS : tab === 2 ? SEARCH_FIELDS.filter(f => f.field !== 'customer') : SEARCH_FIELDS.filter(f => f.field !== 'cust')

    const clearFilter = () => {
        setFilters(prev => prev?.filter(f => f.fKey !== 'cust_name'))
        setFilters(prev => prev?.filter(f => f.fKey !== 'cust_rn'))
        setFilters(prev => prev?.filter(f => f.fKey !== 'customer_name'))
        setFilters(prev => prev?.filter(f => f.fKey !== 'customer_rn'))
        setFilters(prev => prev?.filter(f => f.fKey !== 'orderNo'))
        setPage(1)
    }
    const clear2 = () => {
        setValue(null)
        clearFilter()
    }
    useEffect(() => {
        if (value?.length === 0) { clearFilter() }
    }, [value])

    const [field, setField] = useState('cust')
    const handleFilter = () => {
        if (value?.length < 3) {
            setError('Хайлтанд 3 тэмдэгтээс их урттай утга оруулна уу')
            return
        }
        let newField = field
        if (field === 'cust' || field === 'customer') {
            newField = !isNaN(value) ? `${field}_rn` : `${field}_name`
        }
        setFilters(prev => {
            const newFilters = prev?.filter(f => f.fKey !== newField)||[]
            newFilters?.push({ fKey: newField, operator: '=', fValue: value })
            return newFilters
        })
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') { handleFilter() }
    }

    return (
        <Box sx={{ textAlign: 'right', width: '100%' }}>
            {getToast(Boolean(error), () => setError(null), error, 'warning')}
            <TextField
                size='small'
                placeholder={fields.find(f => f.field === field)?.placeholder}
                value={value||''}
                onChange={e => { setValue(e?.target?.value) }}
                sx={{
                    borderRadius: 0,
                    '& fieldset': { borderColor: '#666', borderRadius: 0 },
                    '&:hover fieldset': { borderColor: '#666' },
                    width: '500px',
                    '& .MuiInputBase-input::placeholder': { color: '#888', fontSize: '14px', opacity: 1, ml: '5px' },
                }}
                inputProps={{
                    style: {
                        padding: '2px 8px', 
                        fontSize: '14px', 
                        color: theme.palette.primary.light
                    }
                }}
                onKeyDown={handleEnter}
                error={Boolean(error)}
                autoComplete='off'
                variant="filled"
                InputProps={{
                    startAdornment: 
                        <InputAdornment position="end">
                            <NativeSelect
                                style={{ 
                                    width: '170px', 
                                    fontSize: '14px', 
                                    borderBottom: 'none' 
                                }}
                                value={field}
                                onChange={(e) => { setField(e.target.value) }}
                                size='small'
                            >
                                {
                                    fields.map(f => 
                                        <option value={f.field}>{f.label}</option>
                                    )
                                }
                            </NativeSelect>
                        </InputAdornment>,
                    endAdornment:
                        <InputAdornment position="end">
                            <Box>
                                {
                                    value?.length > 0 && 
                                    <IconButton onClick={clear2}>
                                        <Close />
                                    </IconButton>
                                }
                                <IconButton onClick={handleFilter}>
                                    <Search />
                                </IconButton>
                            </Box>
                        </InputAdornment>
                }}
            />
        </Box>
    )
}
