import { getOrderFieldsInfo, getOrders, updateOrderFieldsInfo } from '../../../../../api/order'
import { Box, Table, TableBody, TableContainer, Typography } from '@mui/material'
import TableCustomization from '../../../../common/TableCustomization'
import { scrollStyle } from '../../../../../utils/constants'
import TablePages from '../../../../common/TablePages'
import { useQuery } from 'react-query'
import TableHeadRow from './TableHead'
import { useOrderListCtx } from '..'
import { useState } from 'react'
import Row from './Row'

export default function Orders ({ tab }) {
    const { role, filters, page, pageSize, setPage, setPageSize, ordering } = useOrderListCtx()
    // orders
    const { data, isFetching, refetch } = useQuery(
        ['getOrderData', filters, ordering, pageSize, page],
        getOrders,
        { refetchOnWindowFocus: true }
    )

    const { count, results: orders } = data?.data ? data.data : {}
    // order fields
    const { data: fields, refetch: refetchFieldsInfo } = useQuery('getOrderFieldsInfo', getOrderFieldsInfo, { refetchOnWindowFocus: true })
    const fieldsInfo = fields?.data||[]
    const showns = fieldsInfo?.filter(f => f?.isShown)
    const cols = tab === 1 ? showns : tab === 2 ? showns?.filter(f => f.field_name !== 'customer') : showns?.filter(f => f.field_name !== 'cust')
    //console.log("S: ", cols)
    const colSpan = cols?.length ? cols.length + 3 : null

    // Сонголттой талбарууд
    const fieldsWithChoices = fieldsInfo?.filter(f => f?.choices?.length)

    const [expandedRow, setExpandedRow] = useState(null)

    if (!fieldsInfo?.length) return null

    return (
        <TableContainer 
            sx={{
                height: `calc(100% - 25px)`, 
                ...scrollStyle, 
                '&::-webkit-scrollbar': { 
                    width: 8,
                    height: 8
                }
            }}
        >
            <Table stickyHeader aria-label="simple table">
                <TableHeadRow 
                    showns={cols}
                    orders={orders}
                />
                {
                    orders?.length > 0 &&
                        <TableBody>
                            {
                                orders?.map(
                                    (row, idx) => 
                                        <Row
                                            key={`${row?.orderNo}`}
                                            initialRow={row} 
                                            role={role}
                                            idx={idx} 
                                            fieldsInfo={cols}
                                            fieldsWithChoices={fieldsWithChoices} 
                                            expandedRow={expandedRow}
                                            setExpandedRow={setExpandedRow}
                                            refetch={refetch}
                                            colsLength={cols?.length}
                                        />
                                    )
                            }
                        </TableBody>
                }
                <TablePages
                    count={count} 
                    page={page} 
                    setPage={setPage} 
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    colSpan={colSpan}
                    children={
                        <TableCustomization
                            fieldsInfo={fieldsInfo}
                            refetchFieldsInfo={refetchFieldsInfo}
                            updateFields={updateOrderFieldsInfo}
                        />
                    }
                />
            </Table>
            {
                orders?.length === 0 && !isFetching &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 10 }}>
                        <Typography fontSize='15px' fontWeight='bold' textAlign='center'>
                            Үр дүн олдсонгүй
                        </Typography>
                    </Box>
            }
        </TableContainer>
    )
}