import { getAllCustomersForDeliveryZone, getSupplierDeliveryZones, updateCustomerZone } from "../../../../../api/delivery"
import { Box, Button, Link, Typography } from "@mui/material"
import SelectCustGroup from "./SelectCustGroup"
import CustomersTable from "./CustomersTable"
import { useQuery } from "react-query"
import SelectZone from "./SelectZone"
import { sortBy } from "underscore"
import { useState } from "react"

const bodyWidth = '600px'
export default function CustomerZone({ successMsg, waitMsg, setMsg }) {
    // data
    const [custGroup, setCustGroup] = useState(1)
    const [customersData, setCustomersData] = useState([])
    //console.log("C: ", customersData)

    // customer zone filter
    const [custZone, setCustZone] = useState(-1)

    const pageSize=30
    const [count, setCount] = useState(0)    
    const [page, setPage] = useState(1)
    const onSuccess = (data) => { 
        setCustomersData(data?.data?.results||[])
        setCount(data?.data?.count||0)
    }
    const { refetch, isFetching } = useQuery(["get_customers", page, pageSize, custGroup, custZone], getAllCustomersForDeliveryZone, { onSuccess, onError: waitMsg, refetchOnWindowFocus: true })
    const customers = customersData?.customers||[]
    // zones data
    const { data } = useQuery('get_supplier_zones', getSupplierDeliveryZones, { refetchOnWindowFocus: true })
    const customerZones = [{ id: -1, name: 'Бүгд' }, { id: 0, name: 'Бүс сонгоогүй' }, ...sortBy([...data?.data||[]], 'name')]
    const zones = [...sortBy([...data?.data||[]], 'name'), { id: 0, name: 'Бүс сонгохгүй' }]

    // select zone
    const [zone, setZone] = useState('')
    const [selected, setSelected] = useState([])

    // handle select custGroup
    const handleSelectCustGroup = (custGroup) => {
        setCustGroup(custGroup)
        setSelected([])
        setZone('')
        setPage(1)
    }

    // handle select
    const handleSelectDeliveryZone = () => {
        let data = { customer_ids: selected, zone_id: zone||null, of_supplier: parseInt(custGroup) === 2 }
        if (selected?.length === 0) {
            setMsg({ m: 'Харилцагч сонгоогүй байна!', s: 'warning' })
        } else if (zone === '') {
            setMsg({ m: 'Бүс сонгоогүй байна!', s: 'warning' })
        } else {
            updateCustomerZone(data)
                .then(res => {
                    if (res?.data) {
                        refetch()
                        successMsg()
                        setSelected([])
                    }
                })
                .catch(e => {
                    waitMsg()
                    //console.log("ER: ", e)
                })
        }
    }

    const gotToAllocation = () => { window.open('/admin/delivery', '_blank') }

    return (
        <>
            {zones?.length > 0 &&
                <Box sx={{ p: 1, height: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: bodyWidth }}>
                        <SelectCustGroup
                            custGroup={custGroup}
                            setCustGroup={handleSelectCustGroup}
                        />
                        <SelectZone
                            zones={customerZones}
                            zone={custZone}
                            setZone={setCustZone}
                            label={'Бүсээр шүүх'}
                        />
                    </Box>
                    <CustomersTable 
                        customers={customers} 
                        zones={zones} 
                        selected={selected}
                        setSelected={setSelected}
                        bodyWidth={bodyWidth}
                        count={count}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                    />
                    <Box
                        sx={{
                            mt: '5px',
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center',
                            width: bodyWidth,
                        }}
                    >
                        {selected?.length > 0 && 
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                    color: 'secondary.main',
                                    textDecoration: 'underline',
                                    mr: 2
                                }}
                            >
                                Сонгосон: {selected.length}
                            </Typography>
                        }
                        <SelectZone
                            zones={zones}
                            zone={zone}
                            setZone={setZone}
                        />
                        <Button 
                            onClick={handleSelectDeliveryZone}
                            variant='contained'
                            size="small"
                        >
                            Хадгалах
                        </Button>
                    </Box>
                </Box>
               }
               {(!isFetching && !customerZones?.length) &&
                    <Box sx={{ p: 3, textAlign: 'center' }}>
                        <Typography>
                            Түгээлтийн бүсүүд үүсгээгүй байна!
                        </Typography>
                        <Link href='#' target='_blank' onClick={gotToAllocation}>Түгээлтийн бүс үүсгэх</Link>
                    </Box>
               }         
        </>
    )
}
