// delman color
export const delmanColors = [
    '#00A373',
    '#CD853F',
    '#00007C',
    '#40E0D0',
    '#ff4040',
    '#00BFFF',
    '#8B390D',
    '#CC33CC',
    '#228B22',
    '#F27C6E',
    '#FFE417',
    '#663399',
    '#FFA07A',
    '#32CD32',
    '#800000',
    '#FF8C00',
    '#00FFFF',
    '#C71585',
    '#BDB76B',
    '#7B68EE',
]


export const randomColor = () =>  `#${Math.floor(Math.random()*16777215).toString(16)}`

const delman_color_key = 'delman_color'

export default function getDelmanColor (delmanId) {
    try {
        let e = window.localStorage.getItem(delman_color_key)
        e = JSON.parse(e)
        let colorsToSelect = [ ...delmanColors ]
        if (e) {
            const f = e?.find(d => d.id === delmanId)?.color
            if (f) return f
            let leftColrs = colorsToSelect.filter(c => !e.find(d => d.color === c))
            const delmanColor = leftColrs?.shift()||randomColor()
            e.push({ id: delmanId, color: delmanColor })
            window.localStorage.setItem(delman_color_key, JSON.stringify(e))
            return delmanColor
        } else {
            const delmanColor = colorsToSelect?.shift()||randomColor()
            window.localStorage.setItem(delman_color_key, JSON.stringify([{ id: delmanId, color: delmanColor }]))
            return delmanColor
        }
    }
    catch {
        return randomColor()
    }
}