import { AdvancedMarker, useAdvancedMarkerRef } from '@vis.gl/react-google-maps'
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import LocIcon from './LocIcon'
import Popup from './Popup'

export default function DeliveryItem({ item, showDeliveryItems, setMarkersRef = null }) {
    const { lat, lng, color } = item
    const [open, setOpen] = useState(false)

    const [markerRef, marker] = useAdvancedMarkerRef()
    useEffect(() => {
        if (marker) {
            setMarkersRef(marker, item?.id)
        }
    }, [marker])

    if (!lat||!lng||!showDeliveryItems) return null

    return (
        <AdvancedMarker
            position={{ lat, lng }}
            onClick={() => setOpen(!open)}
            ref={markerRef}
            anchor={{ x: 0.5, y: 1 }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: 'fit-content',
                    border: 1
                }}
            >
                <LocIcon color={color} />
                {open && (<Popup item={item} color={color} />)}
            </Box>
        </AdvancedMarker>
    )
}
