import { scrollStyle } from "../../../../../../utils/constants"
import { Box, Dialog } from "@mui/material"

export default function SelectDelman({ delmanBox, delmanBoxOff, delmans, handleSelectDelman }) {
    const choices = [...delmans, { id: 0, name: 'Түгээгчийг болих' }]
    //console.log("select: ", delmanBox)
    return (
        <Dialog 
            open={Boolean(delmanBox)}
            onClose={delmanBoxOff}
        >
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    p: 2
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column', 
                        p: 2,
                        pt: 0,
                        maxHeight: '350px',
                        overflowY: 'auto',
                        cursor: 'pointer',
                        ...scrollStyle,
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'secondary.light',
                        },
                    }}
                >
                    {choices?.map((d, i) => (
                        <Box
                            key={d?.id}
                            sx={{ 
                                mt: 1,
                                borderRadius: 1,
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                    color: d.id === 0 ? 'secondary.light': 'primary.light',
                                },
                                transition: '0.15s',
                                p: '2px 6px',
                                color: d.id === 0 ? 'secondary.light' : 'black',
                            }}
                            onClick={() => { handleSelectDelman(d.id) }}
                        >
                            {d?.name}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Dialog>
    )
}
