import { Box, Button, IconButton, MenuItem, TextField } from '@mui/material'
import { SUPPLIER_STAFF_ROLES } from '../../utils'
import { Close } from '@mui/icons-material'

export default function AddStaffDialogContent({ handleClose, handleSubmit, getFieldProps, errors, touched, values, isEdit }) {
    const getErrors = (name) => {
        const isErr = Object.keys(errors)?.includes(name) && Object.keys(touched)?.includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }

    // enter shift
    const idMap = { f1: 'f2', f2: 'f3', f3: 'f4', f4: 'f5', f5: 'f6' }
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            const curElmId = e?.target?.id
            e.preventDefault()
            if (curElmId === 'f5' && values?.role) {
                handleSubmit()
            } else if (e?.target?.value) {
                document.getElementById(idMap[curElmId])?.focus()
            }
        }
    }

    return (
        <>
            <Box width='400px' sx={{ p: 2 }}>
                <Box sx={{ textAlign: 'right', mb: 1 }}>
                    <IconButton sx={{ p: '2px' }} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <TextField
                        label='Овог'
                        name='last_name'
                        {...getFieldProps('last_name')}
                        {...getErrors('last_name')}
                        size='small'
                        id='f1'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Нэр'
                        name='name'
                        {...getFieldProps('name')}
                        {...getErrors('name')}
                        size='small'
                        id='f2'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Регистрийн дугаар'
                        name='rd'
                        {...getFieldProps('rd')}
                        {...getErrors('rd')}
                        size='small'
                        id='f3'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Имэйл хаяг'
                        name='email'
                        {...getFieldProps('email')}
                        {...getErrors('email')}
                        size='small'
                        id='f4'
                        onKeyDown={handleEnter}
                        disabled={Boolean(isEdit)}
                    />
                    <TextField
                        label='Утасны дугаар'
                        name='phone'
                        {...getFieldProps('phone')}
                        {...getErrors('phone')}
                        size='small'
                        id='f5'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Ажил үүрэг'
                        name='role'
                        {...getFieldProps('role')}
                        {...getErrors('role')}
                        size='small'
                        select
                        id='f6'
                        onKeyDown={handleEnter}
                        disabled={Boolean(isEdit)}
                    >
                        {
                            Object.keys(SUPPLIER_STAFF_ROLES).map(s => <MenuItem key={`${s}`} value={s}>{SUPPLIER_STAFF_ROLES[s]}</MenuItem>)
                        }
                    </TextField>
                    <Button 
                        variant='contained' 
                        size='small'
                        sx={{ p: '7px' }}
                        onClick={handleSubmit}
                        id='f7'
                    >{Boolean(isEdit) ? 'Шинэчлэх' : 'Нэмэх'}</Button>
                    <Button 
                        variant='outlined' 
                        size='small'
                        sx={{ p: '6px', mt: 1 }}
                        onClick={handleClose}
                        id='f8'
                    >Болих</Button>
                </Box>
            </Box>
        </>
    )
}
