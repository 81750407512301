import { Box, Button, Dialog, InputAdornment, MenuItem, TextField, Typography } from '@mui/material'
import { verifyPharmoCustomer } from '../../../../../api/confirm'
import { ArrowDropDown, Email, Phone } from '@mui/icons-material'
import { COMPANY_TYPES } from '../../../../../utils/constants'
import DropMenu from '../../../../helpers/DropMenu'
import LicenseExp from './Fields/LicenseExp'
import { useTheme } from '@emotion/react'
import License from './Fields/License'
import { useFormik } from 'formik'
import Check from './Fields/Check'
import Rd from './Fields/Rd'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    name: Yup.string().max(255, 'Нэр хэт урт байна!').required('Нэр оруулна уу!'),
    public_name: Yup.string().max(255, 'Түгээмэл нэр хэт урт байна!').required('Түгээмэл нэр оруулна уу!'),
    rd: Yup.string().matches(/^\d{7}$/, 'Регистр буруу байна!').required('Регистрийн дугаар оруулна уу!'),
    cType: Yup.string().oneOf(Object.keys(COMPANY_TYPES), 'Үйл ажиллагааны чиглэл буруу байна!').required('Үйл ажиллагааны чиглэл сонгоно уу!'),
    //license: Yup.array().of(Yup.object()).nullable(),
    licenseExp: Yup.string().nullable().matches(/^\d{4}-\d{2}-\d{2}$/, 'Формат буруу! Жишээ: 2024-01-01'),
    isReviewed: Yup.boolean().test('isReviewed', ' Утга сонгоно уу!', (value, ctx) => {
        if (value === true) {
            const { name, public_name, rd, cType, licenseExp } = ctx.parent
            if (!name) { return ctx.createError({ path: 'name', message: 'Нэр оруулаагүй байна!' }) }
            if (!public_name) { return ctx.createError({ path: 'public_name', message: 'Түгээмэл нэр оруулаагүй байна!' }) }
            if (!rd) { return ctx.createError({ path: 'rd', message: 'Регистрийн дугаар оруулаагүй байна!' }) }
            if (!cType) { return ctx.createError({ path: 'cType', message: 'Үйл ажиллагааны чиглэл сонгоогүй байна!' }) }
            //if (!license) { return ctx.createError({ path: 'license', message: 'Тусгай зөвшөөрөл хавсаргаагүй байна!' }) }
            if (!licenseExp) { return ctx.createError({ path: 'licenseExp', message: 'Тусгай зөвшөөрөл дуусах хугацаа оруулаагүй байна!' }) }
            return true
        }
        return true
    })
})

export default function VerifyCustomer({ customer, setCustomer, refetch, setMsg, waitMsg, successMsg }) {
    const theme = useTheme()
    const onSubmit = (data) => {
        const license = data?.license?.map(lic => lic?.id)
        verifyPharmoCustomer({ company_id: customer?.id, ...data, license })
            .then(res => { handleClose(); successMsg(); refetch(); })
            .catch(e => {
                const erd = e?.response?.data
                console.log("ERR: ", erd)
                if (erd?.license?.[0] === 'required') {
                    setMsg({ m: 'Тусгай зөвшөөрөл хавсаргаагүй байна!', s: 'warning' })
                } else if (erd?.rd?.[0] === 'duplicated') {
                    setMsg({ m: 'Регистрийн дугаар бүртгэлтэй байна!', s: 'warning' })
                } else { waitMsg() }
            })
    }

    const { name, public_name, rd, cType, license, licenseExp, isReviewed } = customer
    const { handleSubmit, getFieldProps, setFieldValue, handleChange, values, errors, touched } = useFormik({
        initialValues: { name, public_name, rd, cType, license, licenseExp, isReviewed },
        validationSchema,
        onSubmit
    })

    const getErrors = (name) => {
        const isErr = Object.keys(errors).includes(name) && Object.keys(touched).includes(name)
        return { error: isErr, helperText: isErr ? errors[name] : ' ' }
    }

    const handleClose = () => { setCustomer(null) }

    return (
        <Dialog open={Boolean(customer)} onClose={handleClose}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px', p: 5, pt: 4, width: '600px' }}>
                <Typography fontSize='16px' sx={{ textAlign: 'center', color: 'primary.main', mb: 3 }}>Бүртгэл баталгаажуулалт</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'baseline', mb: 1 }}>
                    <TextField
                        label='Нэр'
                        inputProps={{ style: { fontSize: '15px' }}}
                        InputLabelProps={{ style: { fontSize: '15px' } }}
                        size='small'
                        sx={{ width: '49%' }}
                        value={customer?.name||''}
                        {...getErrors('name')}
                        {...getFieldProps('name')}
                    />
                    <TextField
                        label='Түгээмэл нэр'
                        inputProps={{ style: { fontSize: '15px' }}}
                        InputLabelProps={{ style: { fontSize: '15px' } }}
                        size='small'
                        sx={{ width: '49%' }}
                        value={customer?.public_name||''}
                        {...getErrors('public_name')}
                        {...getFieldProps('public_name')}
                    />
                </Box>
                <Rd
                    getFieldProps={getFieldProps}
                    getErrors={getErrors}
                    rd={values?.rd}
                    name={values?.name}
                />
                <DropMenu
                    title={
                        <TextField
                            label='Үйл ажиллагааны чиглэл'
                            inputProps={{ style: { fontSize: '15px', cursor: 'pointer' }}}
                            InputLabelProps={{ style: { fontSize: '15px' } }}
                            size='small'
                            value={COMPANY_TYPES?.[values?.cType]||''}
                            {...getErrors('cType')}
                            sx={{ width: '100%', borderColor: '#1b2e3c' }}
                            InputProps={{ readOnly: true, endAdornment: (<InputAdornment><ArrowDropDown sx={{ color: '#1b2e3c' }} /></InputAdornment>)}}
                        />
                    }
                    detail={
                        <Box sx={{ backgroundColor: 'white', boxShadow: 8 }}>
                            {Object.keys(COMPANY_TYPES).map(t => <MenuItem key={`${t}`} onClick={_ => { setFieldValue('cType', t) }} sx={{ width: '245px' }}>
                                    <Typography fontSize={'15px'} sx={{ color: '#1b2e3c' }}>{COMPANY_TYPES[t]}</Typography>
                                </MenuItem>)}
                        </Box>
                    }
                    top={38}
                    left={0}
                    width={'fit-content'}
                />
                <License
                    f={values?.license}
                    setFieldValue={setFieldValue}
                    error={errors?.license}
                />
                <LicenseExp
                    getFieldProps={getFieldProps}
                    getErrors={getErrors}
                />
                {/* {
                    values?.cType === 'S' &&
                        <TextField
                            label='MACS url'
                            inputProps={{ style: { fontSize: '15px' }}}
                            InputLabelProps={{ style: { fontSize: '15px' } }}
                            name='macsUrl'
                            {...getFieldProps('macsUrl')}
                            {...getErrors('macsUrl')}
                            size='small'
                            sx={{ width: '100%' }}
                        />
                } */}
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
                    <Typography>Холбоо барих мэдээлэл:</Typography>
                    <Email fontSize='25px' sx={{ color: theme?.palette?.primary?.light, ml: 2 }} />
                    <Typography>{`${customer?.email||''}`}</Typography>
                    <Phone fontSize='25px' sx={{ color: theme?.palette?.primary?.light, ml: 2 }} />
                    <Typography>{customer?.phone}</Typography>
                </Box>
                <Check label={'Мэдээлэл баталгаажсан эсэх?'} value={values?.isReviewed} handleChange={handleChange} />
                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', mt: 3 }}>
                    <Button variant='contained' onClick={ handleSubmit }>Хадгалах</Button>
                    <Button
                        sx={{
                            ml: 2,
                            width: '30%',
                            backgroundColor: 'secondary.light',
                            '&:hover': { backgroundColor: 'secondary.main' }
                        }}
                        onClick={handleClose}
                        variant='contained'
                    >Болих</Button>
                </Box>
            </Box>
        </Dialog>
    )
}