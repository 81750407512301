import { Box, Button, Dialog, Tab, Tabs, Typography } from '@mui/material'
import { updateCustomerLocation } from '../../../../api/customer'
import { getToast } from '../../../auth/helpers/ToastAlert'
import SupplierCustomers from './SupplierCustomers'
import InvitedCustomers from './InvitedCustomers'
import CustomerLocation from './CustomerLocation'
import PharmoCustomers from './PharmoCustomers'
import { useEffect, useState } from 'react'
import CustomerZone from './CustomerZone'

export default function Customer() {
    const [tab, setTab] = useState(0)

    const [customer, setCustomer] = useState(null)

    const [addressTxt, setAddressTxt] = useState(null)

    const [open, setOpen] = useState(false)
    const handleDialogClose = () => { setOpen(false) }
    useEffect(() => {
        if (!open) { setCustomer(null) }
    }, [open])

    const [msg, setMsg] = useState(null)
    const msgOff = () => { 
        setMsg(null)
        handleDialogClose()
    }
	const waitMsg = () => {setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })}
    const successMsg = () => { setMsg({ m: 'Амжилттай!', s: 'success' }) }

    const [newLocation, setNewLocation] = useState(null)
    const [isLocationUpdated, setIsLocationUpdated] = useState(false)
    const updateLocation = () => {
        const data = {
            customer_id: customer.id,
            is_pharmo_user: Boolean(customer.branch),
            ...newLocation
        }
        updateCustomerLocation(data)
            .then(res => {
                setMsg({ m: 'Амжилттай хадгалагдлаа!', s: 'success' })
                setIsLocationUpdated(true)
            })
            .catch(e => {
                //console.log("ERR: ", e)
                setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
            })
            .finally(() => {})
    }

    useEffect(() => {
        if (customer?.id) {
            setOpen(true)
            setAddressTxt(customer?.note)
            setIsLocationUpdated(false)
        }
    }, [customer])

    return (
        <>
            <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)}
                aria-label='tabs'
                sx={{
                    minHeight: '30px', height: '30px',
                    '& .MuiTab-root': { minHeight: '30px', height: '30px' }
                }}
            >
                <Tab label={'PHARMO харилцагч'} value={0} />
                <Tab label={'MACS харилцагч'} value={1} />
                <Tab label={'Урьсан хэрэглэгч'} value={2} />
                <Tab label={'Харилцагчийн бүс'} value={3} />
            </Tabs>
            <Box sx={{ height: '100%' }}>
                {tab === 0 && 
                    <PharmoCustomers
                        isLocationUpdated={isLocationUpdated} 
                        setCustomer={setCustomer}
                        waitMsg={waitMsg}
                        setMsg={setMsg}
                    />
                }
                {tab === 1 && 
                    <SupplierCustomers 
                        isLocationUpdated={isLocationUpdated} 
                        setCustomer={setCustomer} 
                        setMsg={setMsg}
                        waitMsg={waitMsg}
                    />
                }
                {tab === 2 && <InvitedCustomers />}
                {tab === 3 && 
                    <CustomerZone 
                        successMsg={successMsg}
                        waitMsg={waitMsg}
                        setMsg={setMsg}
                    />
                }
            </Box>
            <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth='lg'>
                <Box textAlign={'center'} mt={2}>
                    <Typography>
                        Харилцагч: <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{customer?.name}</span>
                    </Typography>
                </Box>
                <CustomerLocation
                    locationData={customer}
                    addressTxt={addressTxt}
                    setAddressTxt={setAddressTxt}
                    setNewLocation={setNewLocation}
                />
                <Box textAlign={'center'} mb={2}>
                    <Button
                        variant='contained'
                        size='small'
                        onClick={updateLocation}
                    >Хадгалах</Button>
                </Box>
            </Dialog>
            {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s)}
        </>
    )
}