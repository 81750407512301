import { getToast } from '../../../auth/helpers/ToastAlert'
import { useAuthCtx } from '../../../../context/AuthCtx'
import ResultsToggleButton from './ResultsToggleButton'
import ProductSkeleton from './ProductSkeleton'
import { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import ProductList from './ProductList'
import Grid from '@mui/material/Grid'
import Featured from './Featured'
import Paginate from './Paginate'
import Product from './Product'
import Promos from './Promos'

export default function Results({ data, page, setPage, pageSize, isFetching, sf, setSf, choices, promoData, supplierId }) {
	const { alert, addAlert, handleAddAlertOff } = useAuthCtx()

	const { count, results: products } = data?.data ? data.data : {}
	const totalPages = Math.ceil(count / pageSize)

	useEffect(() => { if (page) { window.scrollTo(0, 0) } }, [page])

	const [alignment, setAlignment] = useState('box')

	return (
		<Box
			sx={{
				borderRadius: 2, backgroundColor: 'white', m: 1, boxShadow: 5,
				p: 1, display: 'flex', flexGrow: 1
			}}
		>
			{getToast(Boolean(alert), handleAddAlertOff, addAlert?.alertMsg, addAlert?.msgClr, 1500)}
			<Stack sx={{ width: '100%' }}>
				<Featured sf={sf} setSf={setSf} choices={choices} />
				<Box 
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Paginate
						count={count}
						totalPages={totalPages}
						sf={sf}
						page={page}
						setPage={setPage}
						isFetching={isFetching}
					/>
					<ResultsToggleButton
						alignment={alignment}
						setAlignment={setAlignment}
					/>
				</Box>
				<Grid container justifyContent='center' sx={{ p: 0, m: 0 }}>
					{/* {sf === 4 && <Promos promos={promoData?.data?.results} supplierId={supplierId} />} */}
					{
						isFetching
							?	Array.from(Array(30)).map((p, idx) => (
											<Box
												key={'skeleton' + idx}
												display='flex'
												flexDirection='row'
												justifyContent='center'
												sx={{ m: 1, p: '2px' }}
											><ProductSkeleton /></Box>
										)
									)
							:	Boolean(products?.length > 0 && sf !== 4)
									?	alignment === 'list'
											? 	<ProductList products={products} />
											: 	products.map(product => (
													<Box
														key={product.id}
														display='flex'
														flexDirection='row'
														justifyContent='center'
														sx={{ m: 1, p: '2px' }}
													>
														<Product product={product} />
													</Box>
												)
											)
									:	null
					}
				</Grid>
			</Stack>
		</Box>
	)
}