import { List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { keys } from 'underscore'

export default function TabMenu({ tabs, selIdx, handleNavi }) {
    return (
        <List disablePadding>
            {
                keys(tabs).map(t => {
                    return <ListItem 
                                key={t}
                                disablePadding 
                                sx={{ 
                                    borderBottom: '1px solid #e0e0e0', 
                                    borderBottomColor: selIdx === t ? '#009688' : '#e0e0e0',
                                    backgroundColor: selIdx === t ? '#f5f5f5' : 'transparent',
                                }}
                                onClick={() => { handleNavi(t) }}
                            >
                                <ListItemButton>
                                    <ListItemText primary={tabs[t]?.name} />
                                </ListItemButton>
                            </ListItem>
                    })
            }
        </List>
    )
}
