import { Box, List } from '@mui/material'
import BranchItem from './BranchItem'
import { CustomDivider } from '..'

export default function SelectBranch({ branches, branchId, setBranchId }) {
	const selectBranch = (b) => {
		setBranchId(b?.id)
		window.localStorage.setItem('adr', b?.name)
	}

  	return (
		<>
			<CustomDivider text={'Салбар сонгох'} />
			<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<List 
					sx={{ 
						maxWidth: '600px', 
						maxHeight: '150px',
						overflowY: 'auto'
					}}
				>
					{ branches?.map(
						(b, idx) => <BranchItem 
										key={`${idx}`}
										branch={b}
										selectBranch={selectBranch}
										branchId={branchId}
										idx={idx}
									/>
					) }
				</List>
			</Box>
		</>
	)
}
