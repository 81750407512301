import { forceLogout, showNewLogin, showNewOrderNotif, showDeliveryStart, showDeliveryEnd } from './utils'
import { createContext, useContext, useEffect, useState } from 'react'
import { isTokenValid } from '../utils/functions'

const CHANNEL_NAME = 'pharmo-channel'
let channel = null
export const getBroadcastChannel = () => {
    if (!channel) {
        channel = new BroadcastChannel(CHANNEL_NAME)
    }
    return channel
}
export const closeBroadcastChannel = () => {
    if (channel) {
        channel.close()
        channel = null
    }
}

// BroadcastChannel
const BcCtx = createContext(null)

export const BcProvider = ({ children }) => {
    const [pushData, setPushData] = useState(null)

    const [notif, setNotif] = useState(null)
    const showNotif = (pushData) => {
        if (pushData?.data?.type === 'NO') { showNewOrderNotif(pushData) }
        if (pushData?.data?.type === 'DS') { showDeliveryStart(pushData) }
        if (pushData?.data?.type === 'DE') { showDeliveryEnd(pushData) }
        if (pushData?.data?.type === 'NL') { showNewLogin(pushData) }
        //if (pushData?.type === 'OO') { showOrderDeliveryNotif(pushData); console.log("RECEIVE") }
    }
    useEffect(() => {
        if (notif) {
            showNotif(notif)
            setNotif(null)
        }
    }, [notif])

	useEffect(() => {
        const pharmoChannel = getBroadcastChannel()
		// show system notif
		pharmoChannel.onmessage = (event) => {
            const data = event?.data
            setPushData(data)
            //console.log("E2: ", event?.data)
            // show notification
            if (data?.type === 'notif') {
                setNotif(data)
            }
            // force logout
            if (['pharmo.mn', 'test.pharmo.mn'].includes(process.env.REACT_APP_MAIN_API)) {
                if (data?.type === 'action' && data?.action === 'force_logout' && data?.target_id) {
                    const { user_id } = isTokenValid()
                    if (parseInt(data.target_id) === user_id) {
                        forceLogout()
                        pharmoChannel.onmessage = null
                    }
                }
            }
		}
		return () => { closeBroadcastChannel() }
	}, [])

	return (<BcCtx.Provider value={{ pushData, setPushData }}>{children}</BcCtx.Provider>)
}

export const useBcCtx = () => useContext(BcCtx)
