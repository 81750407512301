import { Checkbox, IconButton, TableCell, TableRow, styled } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { toPrice } from '../../../../../../utils/functions'
import SelectProcessCell from './Cells/SelectProcessCell'
import { PAYMENT_TYPES } from '../../../../../constants'
import SelectStatusCell from './Cells/SelectStatusCell'
import SelectPackerCell from './Cells/SelectPackerCell'
import TrueFalseCell from './Cells/TrueFalseCell'
import BranchCell from './Cells/BranchCell'
import { useEffect, useState } from 'react'
import SellerCell from './Cells/SellerCell'
import { useOrderListCtx } from '../..'
import NoteCell from './Cells/NoteCell'
import OrderDetail from './OrderDetail'

const cellWidthMap = {
    cust: '150px',
    customer: '150px',
    orderNo: '80px',
    createdOn: '130px',
    payType: '80px',
    totalPrice: '100px',
}

const CreatedOnCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.primary.light
}))

export default function Row({ initialRow, idx, fieldsInfo, expandedRow, setExpandedRow, refetch, colsLength }) {
    const { role, sels, delmans, zones, handleSelect, page, pageSize } = useOrderListCtx()
    const [row, setRow] = useState(initialRow)
    const delmanName = delmans?.find(d => d.id === row?.delman)?.name||'-'
    useEffect(() => { setRow(initialRow) }, [initialRow])
    const inSels = sels?.includes(row?.id)
    const [open, setOpen] = useState(expandedRow === row?.id)
    const handleOpen = () => {
        setOpen(!open)
        if (!open) { setExpandedRow(row?.id) }
    }

    const cust_name = row.cust ? Boolean(row.branch && row.branch !== 'Үндсэн салбар') ? `${row.cust} (${row.branch})` : row.cust : ''

    const checkDisable = ['C', 'S'].includes(row.status) || ['A', 'O'].includes(row.process)

    return (
        <>
            <TableRow
                key={`${idx + 1}`}
                sx={{
                    maxHeight: '20px',
                    '&:last-child td, &:last-child th': { border: 0 },
                    '& > td': { p: '2px', fontSize: '13px', textAlign: 'center' },
                    '&:hover': { backgroundColor: '#f1f1f1' },
                    backgroundColor: open ? '#f1f1f1' : 'initial'
                }}
            >
                <TableCell>
                    <Checkbox size='small' disabled={checkDisable} checked={inSels} onChange={() => { handleSelect(row?.id) }} sx={{ p: '2px' }} />
                </TableCell>
                <TableCell>{ page === 1 ? idx + 1 : (page - 1) * pageSize + idx + 1 }</TableCell>
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={ handleOpen }
                    >
                        { open ? <KeyboardArrowUpIcon color='secondary' /> : <KeyboardArrowDownIcon color='secondary' /> }
                    </IconButton>
                </TableCell>
                {
                    fieldsInfo.map( col => {
                        return col.field_name === 'status'
                                    ?   <SelectStatusCell row={row} setRow={setRow} />
                                    :   (col.field_name === 'process')
                                            ?   <SelectProcessCell row={row} role={role} setRow={setRow} />
                                            :   col.field_name === 'branch'
                                                    ?   <BranchCell branch={row?.branch} of_customer={Boolean(row.customer)} />
                                                    :   ['packer'].includes(col.field_name)
                                                            ?   <SelectPackerCell row={row} refetch={refetch} sels={sels} />
                                                            :   col.field_name === 'note'
                                                                    ?   <NoteCell hasNote={row.note} orderId={row.id} />
                                                                    :   col.field_name === 'seller'
                                                                            ?   <SellerCell sellerId={row.seller} />
                                                                            :   col.field_name === 'createdOn'
                                                                                    ?   <CreatedOnCell>{row[col.field_name]}</CreatedOnCell>
                                                                                    :   <TableCell
                                                                                            key={ col.field_name }
                                                                                            width={ cellWidthMap[col.field_name]||'initial' }
                                                                                            sx={{
                                                                                                textAlign: ['cust', 'customer'].includes(col.field_name) ? 'left !important' : 'center',
                                                                                                pl: ['cust', 'customer'].includes(col.field_name) ? '10px !important' : '0px',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                col.field_name === 'totalPrice'
                                                                                                    ?   toPrice(row[col.field_name])
                                                                                                    :   ['qp', 'has_loc', 'is_come'].includes(col.field_name)
                                                                                                            ?   <TrueFalseCell value={row[col.field_name]} />
                                                                                                            :   col.field_name === 'payType'
                                                                                                                    ?   PAYMENT_TYPES[row?.payType]||'-'
                                                                                                                    :   col.field_name === 'delivery'
                                                                                                                            ?   row?.delivery?.delivery_no||'-'
                                                                                                                            :   col.field_name === 'zone'
                                                                                                                                    ?   zones?.find(z => z?.id === row?.zone)?.name||'-'
                                                                                                                                    :   col.field_name === 'cust'
                                                                                                                                            ?   cust_name
                                                                                                                                            :   col.field_name === 'delman'
                                                                                                                                                    ?   delmanName
                                                                                                                                                    :   row[col.field_name]||''
                                                                                            }
                                                                                        </TableCell>
                    })
                }
            </TableRow>
            {open && <OrderDetail row={row} setRow={setRow} colsLength={colsLength} />}
        </>
    )
}