import { getOrdersToShip, getOrdersToShipByDelman, updateShipmentLocation } from '../../../../../../api/shipment'
import {APIProvider, ControlPosition, Map, MapControl, useMap } from '@vis.gl/react-google-maps'
import { defaultCenter, defaultZoom, maxBounds } from '../../../Shipment/utils'
import { useEffect, useState, useRef, createContext, useContext } from 'react'
import { getToast } from '../../../../../auth/helpers/ToastAlert'
import { mapOptions } from '../../../../../utils'
import ControlPanelBox from './ControlPanelBox'
import ContextMenuBox from './ContextMenuBox'
import DeliveryStops from './DeliveryStops'
import { useQuery } from 'react-query'
import { Box } from '@mui/material'

const boundRestriction = {
    restriction: {
        latLngBounds: maxBounds,
        strictBounds: true
    },
}
function isWithinBounds(lat, lng, bounds) {
    if (!bounds||!lat||!lng) return
    const { east, west, north, south } = bounds
    return (
        lat >= south && lat <= north &&
        lng >= west && lng <= east
    )
}
function isWithinMaxBounds(lat, lng) {
    return isWithinBounds(lat, lng, maxBounds)
}

const AllocationCtx = createContext(null)
export const useAllocationCtx = () => useContext(AllocationCtx)

export function AllocationMap() {
    const [zoom, setZoom] = useState(defaultZoom)
    const [center, setCenter] = useState(defaultCenter)

    const saveDelmansColor = () => {}

    const map = useMap()
    const [stopPoints, setStopPoints] = useState([])
    useEffect(() => {
        if (map && stopPoints.length > 0) {
            const bounds = new window.google.maps.LatLngBounds()
            stopPoints.forEach((stop) => { bounds.extend(new window.google.maps.LatLng(stop.lat, stop.lng)) })
            map.fitBounds(bounds)
        }
    }, [map, stopPoints])

    const [ordersCnt, setOrdersCnt] = useState(null)
    const stopsCnt = stopPoints?.length||0

    const [delmans, setDelmans] = useState(null)

    const [showStopLabel, setShowStopLabel] = useState(true)

    const [startLocation, setStartLocation] = useState(null)
    //console.log("sl: ", startLocation)

    const pointsRef = useRef(null)

    const [msg, setMsg] = useState(null)
    const alertWarning = () => setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })

    const handleOrdersData = (res) => {
        const data = res?.data
        if (data) {
            const ordererOrders = data.results?.filter(r => r.branchId && r.lat && r.lng) // pharmo user orders
            const sellerOrders = data.results?.filter(r => !r.branchId && r.customer?.id && r.lat && r.lng) // supplier seller orders (customer)
            const notLocationData = data.results?.filter(r => !r.lat || !r.lng)
            const maxBranchIdOfOrderer = ordererOrders?.reduce((acc, cur) => Math.max(acc, cur.branchId), 0)
            const ordererPoints = ordererOrders?.reduce((acc, cur) => {
                const exist = acc.find(a => a.branchId === cur.branchId)
                if (exist) {
                    exist.orders.push(cur.order)
                    exist.orderIds.push(cur.id)
                } else {
                    acc.push({ ...cur, orders: [cur.order], orderIds: [cur.id] })
                }
                return acc
            }, [])||[]
            const sellerPoints = sellerOrders?.reduce((acc, cur) => {
                const custBranchId = cur.customer.id + maxBranchIdOfOrderer
                const exist = acc.find(a => a.branchId === custBranchId)
                if (exist) {
                    exist.orders.push(cur.order)
                    exist.orderIds.push(cur.id)
                } else {
                    acc.push({ ...cur, branchId: custBranchId, cmp: cur?.customer?.name||'', orders: [cur.order], orderIds: [cur.id] })
                }
                return acc
            }, [])||[]
            const points = [...ordererPoints, ...sellerPoints]
            sellerPoints.forEach(p => { console.log("P: ", p, isWithinMaxBounds(p.lat, p.lng)) })
            const pointsInUB = points?.filter(p => isWithinMaxBounds(p.lat, p.lng))||[] // order user's location must be in Ulaanbaatar
            setStopPoints(pointsInUB)
            setOrdersCnt(data.count||null)
            pointsRef.current = pointsInUB
            const ds = data.delmans||[]
            if (ds?.length > 0) {
                setDelmans(saveDelmansColor(ds))
            }
            if (data?.startLoc) { setStartLocation(data.startLoc) }
        }
    }

    const [delmanId, setDelmanId] = useState(null)
    const [isFetchOrders, setIsFetchOrders] = useState(true)
    const onSuccess = (res) => handleOrdersData(res)
    const onError = (e) => { setMsg({ s: 'warning', m: 'Түр хүлээгээд дахин оролдоно уу!' }); console.log("E: ", e) }
    const { refetch: refetchAllStops, isFetching } = useQuery('get_orders_to_allot', getOrdersToShip, { onSuccess, onError, enabled: isFetchOrders, refetchOnWindowFocus: true } )
    const { refetch: refetchDelmanStops, isFetching: delmanStopsFetching } = useQuery(['get_orders_to_allot_by_delman', delmanId], getOrdersToShipByDelman, { onSuccess, onError, enabled: !isFetchOrders, refetchOnWindowFocus: true })
    const refetch = () =>  isFetchOrders ? refetchAllStops() : refetchDelmanStops()
    const isFetched = !isFetching && !delmanStopsFetching

    // rectangle
    const rectRef = useRef(null)
    const rectStartPointRef = useRef(null)
    const boundsRef = useRef(null)

    const [toSelect, setToSelect] = useState(false)
    const [selected, setSelected] = useState([])

    useEffect(() => { 
        document.title = 'Хуваарилалт'
        const handleKeyDown = (e) => { 
            if (e.code === 'KeyS') { setToSelect(true) }
            if (e.code === 'Escape') { setToSelect(false); setSelected([]) }
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    const handleMouseDown = (e) => {
        const latLng = e?.latLng
        if (latLng) {
            rectStartPointRef.current = { lat: latLng.lat(), lng: latLng?.lng() }
            rectRef.current = new window.google.maps.Rectangle()
        }
    }

    const handleMouseMove = (e) => {
        if (rectStartPointRef.current) {
            const lat = e?.latLng?.lat()
            const lng = e?.latLng?.lng()
            if (lat && lng) {
                const bounds = {
                    north: Math.max(rectStartPointRef.current.lat, lat),
                    south: Math.min(rectStartPointRef.current.lat, lat),
                    east: Math.max(rectStartPointRef.current.lng, lng),
                    west: Math.min(rectStartPointRef.current.lng, lng),
                }
                rectRef.current?.setOptions({
                    strokeColor: "#005BFF",
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: "#005BFF",
                    fillOpacity: 0.2,
                    map,
                    bounds,
                })
                boundsRef.current = bounds
            }
        }
    }
    const handleMouseUp = () => {
        if (boundsRef.current && pointsRef.current) {
            const newPoints = pointsRef.current.filter(p => isWithinBounds(p.lat, p.lng, boundsRef.current) && !selected.map(s => s.branchId).includes(p.branchId))
            setSelected(prev => ([...prev, ...newPoints]))
        }
        rectRef.current?.setMap(null)
        rectRef.current = null
        rectStartPointRef.current = null
        boundsRef.current = null
    }

    // select delman or handle context menu (right click)
    const [cm, setCm] = useState(null)
    const handleDefaultContextMenu = (e) => { e?.preventDefault() }

    // map
    const mapRef = useRef(null)
    useEffect(() => {
        if (map) {
            map.addListener('mousedown', handleMouseDown)
            map.addListener('mousemove', handleMouseMove)
            map.addListener('rightclick', (e) => {
                setCm({
                    x: e.pixel?.x,
                    y: e.pixel?.y
                })
            })
            mapRef.current = map
        }
    }, [map])
    useEffect(() => {
        mapRef.current?.setOptions({
            draggableCursor: toSelect ? 'crosshair' : 'grab',
            draggingCursor: toSelect ? 'crosshair' : 'grab',
        })
    }, [toSelect, mapRef])
    // map container
    const boxRef = useRef(null)
    useEffect(() => {
        if (boxRef.current) {
            boxRef.current.addEventListener('mouseup', handleMouseUp)
        }
        return () => { boxRef.current?.removeEventListener('mouseup', handleMouseUp) }
    }, [boxRef])

    const handleClickForTest = (e) => {
        setCm(null)
        if (!process.env.REACT_APP_MAIN_API?.includes('pharma.mn')) {
            console.log("C: ", e?.detail?.latLng?.lat, e?.detail?.latLng?.lng)
            return null
            if (e?.detail?.latLng?.lat && e?.detail?.latLng?.lng) {
                updateShipmentLocation({
                    delmanId: 572,
                    lat: e.detail.latLng.lat.toFixed(6),
                    lng: e.detail.latLng.lng.toFixed(6),
                })
                    .then(res => { console.log("Res: ", res) })
                    .catch(err => { console.log("Err: ", err) })
            }
        }
    }

    return (
        <AllocationCtx.Provider
            value={{
                stopPoints,
                delmans,
                selected,
                setSelected,
                toSelect,
                setToSelect,
                setMsg,
                alertWarning,
                cm,
                setCm, 
                refetchAllStops,
                refetchDelmanStops,
                refetch,
                delmanId,
                setDelmanId,
                showStopLabel,
                setShowStopLabel
            }}
        >
            <Box
                sx={{ width: '100%', height: '100vh' }}
                ref={boxRef}
                onContextMenu={handleDefaultContextMenu}
            >
                {getToast(Boolean(msg), () => { setMsg(null) }, msg?.m, msg?.s, 1500)}
                <Map
                    defaultCenter={center}
                    defaultZoom={zoom}
                    disableDefaultUI={true}
                    minZoom={10}
                    maxZoom={18}
                    mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                    gestureHandling={toSelect ? 'none' : 'greedy'}
                    options={{ 
                        ...mapOptions,
                        ...boundRestriction,
                    }}
                    onClick={handleClickForTest}
                >
                    <MapControl position={ControlPosition.TOP_LEFT}>
                        <ControlPanelBox
                            delmans={delmans}
                            ordersCnt={ordersCnt}
                            stopsCnt={stopsCnt}
                            toSelect={toSelect}
                            setToSelect={setToSelect}
                            setSelected={setSelected}
                            delmanId={delmanId}
                            setDelmanId={setDelmanId}
                            isFetchOrders={isFetchOrders}
                            setIsFetchOrders={setIsFetchOrders}
                            showStopLabel={showStopLabel}
                            setShowStopLabel={setShowStopLabel}
                        />
                    </MapControl>
                    {
                        isFetched &&
                            <DeliveryStops
                                stopPoints={stopPoints}
                                delmans={delmans}
                                showStopLabel={showStopLabel}
                                toSelect={toSelect}
                                selected={selected}
                                setSelected={setSelected}
                            />
                    }
                    {cm && <ContextMenuBox />}
                </Map>
            </Box>
        </AllocationCtx.Provider>
    )
}

export default function AllocationMapProvider() {    
    return (
        <APIProvider
            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
            onLoad={() => { console.log('Maps API has loaded.'); }}
            onError={(err) => console.error('Maps API loading failed.', err)}
        >
            <AllocationMap />
        </APIProvider>
    )
}