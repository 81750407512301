import { getCompanyInfo, updateCompanyInfo } from '../../../../api/company'
import { getToast } from '../../../auth/helpers/ToastAlert'
import ContactInfo from './ContactInfo'
import { useQuery } from 'react-query'
import { Box } from '@mui/material'
import AdminInfo from './AdminInfo'
import { useState } from 'react'
import OrgInfo from './OrgInfo'

export default function CompanyInfo() {
    const { data, isFetched, refetch } = useQuery(["get_company_info"], getCompanyInfo, { refetchOnWindowFocus: true })
    const info = data?.data
    const [msg, setMsg] = useState(null)
    const msgOff = () => { setMsg(null) }

    return (
        <Box sx={{ width: '600px', pl: 2 }}>
            {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 1500)}
            {
                isFetched
                    ?   <>
                            <AdminInfo
                                adminInfo={info?.admin}
                                refetch={refetch}
                                setMsg={setMsg}
                            />
                            <OrgInfo
                                info={info}
                                updateCompanyInfo={updateCompanyInfo}
                                refetch={refetch}
                                setMsg={setMsg}
                            />
                            <ContactInfo
                                info={info}
                                updateCompanyInfo={updateCompanyInfo}
                                refetch={refetch}
                                setMsg={setMsg}
                            />
                        </>
                    :   ''
            }            
        </Box>
    )
}