import { deleteDelivery, endDelivery, getDeliveryOrderDetail, removeOrderFromDelivery, startDelivery } from '../../../../../api/delivery'
import { Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import VerifiedDeleteIcon from '../../../../common/UtilComps/VerifiedDeleteIcon'
import DeliveryStartOrEndCell from './DeliveryStartOrEndCell'
import { scrollStyle } from '../../../../../utils/constants'
import { DELIVERY_ORDER_PROCESS, useDeliveryCtx } from '..'
import { UpdateOrderStatusCell } from './DeliveryRowCells'
import DeliveryItems from './DeliveryItems'
import OrderDetail from '../OrderDetail'
import { useQuery } from 'react-query'
import { useState } from 'react'

export default function CurrentDelivery() {
    const { setMsg, successMsg, waitMsg, getDelvsCntRefetch, isDelman, allOrders, deliveries, refetchDelvs, isDelvsFetching } = useDeliveryCtx()

    // group orders by delman, zone, orderer
    const ordersByDeliveryByOrderer = allOrders?.reduce((acc, order) => {
        const added_delivery = acc?.find(a => a.delivery?.id === order.delivery?.id)
        if (added_delivery) {
            const added_orderer = added_delivery.orderers?.find(o => o.orderer?.id === order.orderer?.id)
            if (added_orderer) {
                added_orderer.orders?.push(order)
            } else {
                added_delivery.orderers?.push({ orderer: order.orderer, orders: [order] })
            }
        } else {
            acc?.push({ delivery: order.delivery, orderers: [{ orderer: order.orderer, orders: [order] }] })
        }
        return acc
    }, [])

    const deliveryOrdersCnt = allOrders?.reduce((acc, order) => {
        if (order.delivery_id) {
            acc[order.delivery_id] = (acc[order.delivery_id]||0) + 1
        }
        return acc
    }, {})
    //console.log("ORDERS: ", ordersByDeliveryByOrderer)

    const handleRemove = ({ order_id, delivery_id }) => {
        removeOrderFromDelivery({ order_id, delivery_id })
            .then(res => {
                refetchDelvs()
                successMsg()
                getDelvsCntRefetch()
            })
            .catch(e => {
                //console.log("ERR: ", e)
                waitMsg()
                const started = e?.response?.data?.delivery_started?.[0]
                const ended = e?.response?.data?.delivery_ended?.[0]
                if (started) {
                    setMsg({ m: started, s: 'warning' })
                } else if (ended) {
                    setMsg({ m: ended, s: 'warning' })
                }
            })
    }

    const handleCancel = (delivery_id) => {
        deleteDelivery(delivery_id)
            .then(res => {
                refetchDelvs()
                successMsg()
                getDelvsCntRefetch()
            })
            .catch(e => {
                console.log("ERR: ", e)
                waitMsg()
                const started = e?.response?.data?.delivery === 'Delivery already started!'
                if (started) {
                    setMsg({ m: 'Түгээлт эхлэсэн байна!', s: 'warning' })
                }
            })
    }

    const handleStart = (delivery) => {
        const delivery_id = delivery?.id
        if (!delivery_id?.started_on) {
            startDelivery({delivery_id})
                .then(res => {
                    successMsg()
                    refetchDelvs()
                }).catch(e => {
                    console.log("Start err: ", e)
                })
        }
    }

    const handleEnd = (delivery) => {
        const delivery_id = delivery?.id
        if (!delivery?.ended_on) {
            endDelivery({delivery_id})
                .then(res => {
                    successMsg()
                    refetchDelvs()
                }).catch(e => {
                    console.log("End error: ", e)
                    const notStarted = e?.response?.data?.delivery_id?.[0] === 'Delivery not started!'
                    if (notStarted) {
                        setMsg({ m: 'Түгээлт эхлээгүй байна!', s: 'warning' })
                    }
                })
        }
    }

    // show delivery items (additional deliveries)
    const [deliveryId, setDeliveryId] = useState(null)
    const deliveryItems = deliveries?.find(d => d.id === deliveryId)?.items || []
    //console.log("D: ", deliveryItems, deliveryId)

    // show delivery order detail
    const [orderId, setOrderId] = useState(null)
    const { data: orderDetail, isFetched: orderDetailIsFetched } = useQuery(['get_order_detail', orderId], getDeliveryOrderDetail, { enabled: Boolean(orderId) })
    const orderItems = orderDetail?.data||[]

    const gotToMap = () => {
        if (deliveries?.length === 0) {
            setMsg({ m: 'Түгээлт байхгүй!', s: 'warning' })
            return
        } else if (!deliveries?.find(d => d.started_on)) {
            setMsg({ m: 'Эхэлсэн түгээлт байхгүй!', s: 'warning' })
            return
        }
        window.open('/admin/delivery_map', '_blank')
    }

    let order_ctr = 1
    // hover
    const [hovered, setHovered] = useState(null)

    return (
        <>
            <Button
                size='small'
                variant='contained'
                onClick={gotToMap}
                sx={{
                    m: 1, p: '2px 12px',
                    color: 'white',
                    backgroundColor: 'primary.light',
                    '&:hover': { backgroundColor: 'primary.main' }
                }}
            >
                Түгээлтийн явц харах
            </Button>
            <TableContainer
                sx={{
                    p: 1,
                    boxShadow: 3,
                    width: '90%',
                    maxHeight: `calc(100% - ${50}px)`,
                    height: `calc(100% - ${50}px)`,
                    ...scrollStyle
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow
                            sx={{
                                '& > th': {
                                    color: 'white',
                                    textAlign: 'center',
                                    backgroundColor: 'secondary.light',
                                    borderRight: '1px solid white',
                                    p: '2px 4px',
                                    lineHeight: 1
                                }
                            }}
                        >
                            <TableCell rowSpan={2}>№</TableCell>
                            <TableCell rowSpan={2}>Түгээлтийн дугаар</TableCell>
                            <TableCell rowSpan={2}>Түгээгч</TableCell>
                            <TableCell rowSpan={2}>Эхэлсэн</TableCell>
                            <TableCell rowSpan={2}>Дууссан</TableCell>
                            <TableCell rowSpan={2}>Явц</TableCell>
                            <TableCell colSpan={6} rowSpan={1}>Захиалга</TableCell>
                            <TableCell rowSpan={2}>Нэмэлт Хүргэлт</TableCell>
                            <TableCell rowSpan={2}>Түгээлт цуцлах</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                '& > th': {
                                    color: 'white',
                                    textAlign: 'center',
                                    backgroundColor: 'secondary.light',
                                    borderRight: '1px solid white',
                                    p: '2px 4px'
                                }
                            }}
                        >
                            <TableCell rowSpan={1}>№</TableCell>
                            <TableCell rowSpan={1} sx={{ fontSize: '10px', lineHeight: 1 }}>Захиалгын дугаар</TableCell>
                            <TableCell rowSpan={1}>Захиалагч</TableCell>
                            <TableCell rowSpan={1}>Төлөв</TableCell>
                            <TableCell rowSpan={1}>Бүс</TableCell>
                            <TableCell rowSpan={1} sx={{ fontSize: '10px', lineHeight: 1 }}>Түгээлтээс хасах</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        ordersByDeliveryByOrderer?.length > 0
                            ?   ordersByDeliveryByOrderer?.map((delivery, idx) => (
                                    <>
                                        {delivery.orderers?.map((orderer, orderer_idx) => (
                                            <>
                                                {orderer?.orders?.map((order, order_idx) => (
                                                    <>
                                                        <TableRow 
                                                            key={order?.id}
                                                            sx={{ 
                                                                '& > td': { 
                                                                    p: '2px 6px',
                                                                    borderRight: 1,
                                                                    borderRightColor: '#e1e1e1'
                                                                },
                                                                '&:hover': {
                                                                    backgroundColor: '#f0f0f0'
                                                                }
                                                            }}
                                                            onMouseEnter={() => setHovered(delivery.delivery?.id)}
                                                            onMouseLeave={() => setHovered(null)}
                                                        >
                                                            {orderer_idx === 0 && order_idx === 0 && (
                                                                <>
                                                                    <TableCell rowSpan={deliveryOrdersCnt[order.delivery_id]||1} align='center'>{idx+1}</TableCell>
                                                                    <TableCell rowSpan={deliveryOrdersCnt[order.delivery_id]||1} align='center'>
                                                                        {order.delivery?.delivery_no||'-'}
                                                                    </TableCell>
                                                                    <TableCell rowSpan={deliveryOrdersCnt[order.delivery_id]||1} align='center'>
                                                                        {order.delman?.name||'-'}
                                                                    </TableCell>
                                                                    <DeliveryStartOrEndCell
                                                                        rowSpan={deliveryOrdersCnt[order.delivery_id]||1}
                                                                        isDelman={isDelman}
                                                                        handleClick={() => handleStart(order.delivery)}
                                                                        deliveryTime={delivery.delivery?.started_on}
                                                                    />
                                                                    <DeliveryStartOrEndCell
                                                                        rowSpan={deliveryOrdersCnt[order.delivery_id]||1}
                                                                        isDelman={isDelman}
                                                                        handleClick={() => handleEnd(order.delivery)}
                                                                        deliveryTime={delivery.delivery?.ended_on}
                                                                        label='Дуусгах'
                                                                    />
                                                                    <TableCell
                                                                        rowSpan={deliveryOrdersCnt[order.delivery_id]||1}
                                                                        align='center'
                                                                        width='60px'
                                                                    >
                                                                        {delivery.delivery?.progress ? `${delivery.delivery.progress} %` : '-'}
                                                                    </TableCell>
                                                                </>
                                                            )}
                                                            <TableCell
                                                                align='center'
                                                                sx={{ backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial' }}
                                                            >{order_ctr++}</TableCell>
                                                            <TableCell
                                                                align="center" 
                                                                sx={{ 
                                                                    borderLeft: 1,
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline',
                                                                    color: 'primary.light',
                                                                    '&:hover': { 
                                                                        backgroundColor: '#f0f0f0',
                                                                        fontWeight: 500,
                                                                    },
                                                                    backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial'
                                                                }}
                                                                onClick={() => setOrderId(order.id)}
                                                            >
                                                                {order.orderNo}
                                                            </TableCell>
                                                            <TableCell align='center' sx={{ backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial' }}>{order?.orderer?.name||'-'}</TableCell>
                                                            <UpdateOrderStatusCell 
                                                                order={order}
                                                                DELIVERY_ORDER_PROCESS={DELIVERY_ORDER_PROCESS}
                                                                isDelman={isDelman}
                                                                setMsg={setMsg}
                                                                waitMsg={waitMsg}
                                                                successMsg={successMsg}
                                                                refetch={refetchDelvs}
                                                                hovered={hovered}
                                                            />
                                                            <TableCell align='center' sx={{ backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial' }}>{order?.zone?.name||'-'}</TableCell>
                                                            <TableCell align="center" sx={{ backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial' }}>
                                                                {
                                                                    isDelman
                                                                        ?   ''
                                                                        :   <VerifiedDeleteIcon
                                                                                verifyText={'Захиалгыг түгээлтээс хасах уу!'}
                                                                                onYes={() => handleRemove({ order_id: order.id, delivery_id: order.delivery_id })}
                                                                            />
                                                                }
                                                            </TableCell>
                                                            {
                                                                orderer_idx === 0 && order_idx === 0 && (
                                                                    <>
                                                                        {
                                                                            order?.delivery?.items?.length > 0
                                                                                ?   <TableCell 
                                                                                        align='center'
                                                                                        sx={{ 
                                                                                            backgroundColor: hovered === order.delivery_id ? '#f0f0f0' : 'initial',
                                                                                            cursor: 'pointer',
                                                                                            textDecoration: 'underline',
                                                                                            color: 'primary.light',
                                                                                            '&:hover': { fontWeight: 500 }
                                                                                        }}
                                                                                        onClick={() => { setDeliveryId(order.delivery_id) }}
                                                                                        rowSpan={deliveryOrdersCnt[order.delivery_id]||1}
                                                                                    >{'Харах'}</TableCell>
                                                                                :   <TableCell align='center' rowSpan={deliveryOrdersCnt[order.delivery_id]||1}>-</TableCell>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                orderer_idx === 0 && order_idx === 0 && (
                                                                    <TableCell rowSpan={deliveryOrdersCnt[order.delivery_id]||1} align='center'>
                                                                        {
                                                                            isDelman
                                                                                ?   ''
                                                                                :   <VerifiedDeleteIcon
                                                                                        verifyText={'Түгээлтийг цуцлах уу!'}
                                                                                        onYes={() => handleCancel(order.delivery?.id)}
                                                                                    />
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            }
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </>
                                        ))}
                                    </>
                                ))
                            :   <TableRow>
                                    <TableCell colSpan={14} align='center' sx={{ color: '#4d4d4d' }}>
                                        {
                                            isDelvsFetching
                                                ?   <CircularProgress />
                                                :   'Одоогоор түгээлт байхгүй байна.'
                                        }
                                    </TableCell>
                                </TableRow>
                    }
                    </TableBody>
                </Table>
                {
                    Boolean(orderId) && (
                        <OrderDetail
                            orderItems={orderItems}
                            isFetched={orderDetailIsFetched}
                            setOrderId={setOrderId}
                        />
                    )
                }
                {
                    Boolean(deliveryId) && (
                        <DeliveryItems
                            deliveryItems={deliveryItems}
                            setDeliveryId={setDeliveryId}
                        />
                    )
                }
            </TableContainer>
        </>
    )
}