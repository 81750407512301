import { Box, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import VerifyTable from '../VerifyTable'
import CheckPin from './CheckPin'
import Qr from './Qr'

export default function VerifyBox({ boxOff, payType, inv }) {
    const { qrTxt, totalPrice, totalCount } = inv ? inv : {}
    
    return (
        <Box 
            sx={{ 
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
                p: 2
            }}
        >
            <Box>
                <IconButton
                    onClick={boxOff}
                    sx={{ 
                        position: 'absolute', 
                        top: '5px', 
                        right: '10px' 
                    }}
                >
                    <Close
                        sx={{ 
                            color: '#666', 
                            fontSize: '20px'
                        }}
                    />
                </IconButton>
            </Box>
            <VerifyTable 
                payType={payType} 
                totalPrice={totalPrice} 
                totalCount={totalCount}
            />
            {
                payType === 'C'
                    ?   <Qr qr={qrTxt} totalPrice={totalPrice} totalCount={totalCount} boxOff={boxOff} />
                    :   <CheckPin boxOff={boxOff} />
            }
        </Box>
    )
}