import { Box, Container, Divider, Link, Paper, Toolbar, Typography } from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { useContext, useEffect, useState } from 'react'
import { getToast } from '../auth/helpers/ToastAlert'
import SelectDeliveryType from './SelectDeliveryType'
import { PHARMA_ROLES } from '../../utils/constants'
import { getOrderBranches } from '../../api/company'
import { isTokenValid } from '../../utils/functions'
import { useAuthCtx } from '../../context/AuthCtx'
import { usePushCtx } from '../../context/PushCtx'
import { addNewOrder } from '../../api/order'
import AllowNotifBox from './AllowNotifBox'
import SelectPayType from './SelectPayType'
import SelectBranch from './SelectBranch'
import { useQuery } from 'react-query'
import { createContext } from 'react'
import OrderItems from './OrderItems'
import Verify from './Verify'

export function CustomDivider ({ text }) {
    return (
        <Divider sx={{ mt: 3, mb: '5px', "&::before, &::after": { borderColor: "primary.main" } }} textAlign='center'>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography 
                    fontSize='14px'
                    fontStyle='italic'
                    marginLeft={1}
                    sx={{ color: 'primary.main' }}
                >
                    {text}
                </Typography>
            </Box>
        </Divider>
    )
}

// order ctx
const OrderCtx = createContext()
export const useOrderCtx = () => useContext(OrderCtx)

// main component
export default function Order() {
    const { basket, fetchBasket } = useAuthCtx()
    const { role } = isTokenValid()
    const { requestNotificationPermission } = usePushCtx()

    const { data, isFetched } = useQuery('getOrderBranches', getOrderBranches)
    const branches = data?.data||[]
    const branchCnt = branches?.length||0

    // order data
    const [branchId, setBranchId] = useState(null)
    const [isCome, setIsCome] = useState(false)
    const [payType, setPayType] = useState('L')
    const [note, setNote] = useState(null)
    useEffect(() => {
        if (isFetched) {
            if (branches?.length === 1) { setBranchId(branches?.[0]?.id) }
        }
    },[isFetched])

    const reset = () => { setBranchId(null) }

    const [newOrder, setNewOrder] = useState(null)

    const [ordErr, setOrdErr] = useState(null)
    const handleErrOff = () => { setOrdErr(null) }

    const [ocp, setOcp] = useState(false)

    const [showAllowNotifBox, setShowAllowNotifBox] = useState(false)

    const createOrder = () => {
        if (ocp === false) {
            setOcp(true)
            addNewOrder({ branch_id: branchId, basket_id: basket?.id, note, is_come: isCome })
                .then(res => {
                    setNewOrder(res?.data)
                    reset()
                    fetchBasket()
                    window.localStorage.removeItem('adr')
                    window.localStorage.removeItem('pt')
                    if (PHARMA_ROLES?.includes(role) && Notification.permission === 'default') {
                        setShowAllowNotifBox(true)
                        requestNotificationPermission()
                    }
                })
                .catch(e => {
                    const errMsg = e?.response?.data?.detail
                    if ( errMsg === 'Bad customer!') {
                        setOrdErr('Тухайн нийлүүлэгчээс захиалга хийх боломжгүй байна!')
                    } else { setOrdErr('Түр хүлээгээд дахин оролдоно уу!') }
                    console.log("ERRR: ", e)
                })
                .finally(() => { setOcp(false) })
        }
    }

    useEffect(() => {
        setNewOrder(null)
        return () => {
            setNewOrder(null)
            window.localStorage.removeItem('tp')
            window.localStorage.removeItem('tc')
        }
    }, [])

    // restart
    const loc = useLocation()
    useEffect(() => {
        if (loc?.state) {
            if (loc?.state?.fromTemplet) { setNewOrder(null); fetchBasket() }
        }
    }, [loc?.state])
    const navi = useNavigate()
    const goBack = () => { navi('/') }

    // err
    const [qtysErr, setQtysErr] = useState(null)
    const [itemsErr, setItemsErr] = useState(null)

    return (
        <OrderCtx.Provider 
            value={{
                branchCnt,
                createOrder,
                newOrder,
                isCome,
                note,
                setNote
            }}
        >
            {
                showAllowNotifBox &&
                    <AllowNotifBox
                        open={showAllowNotifBox}
                        handleClose={() => { setShowAllowNotifBox(false) }}
                    />
            }
            {
                loc?.pathname === '/order'
                    ?   <Container sx={{ mb: 4, height: 'auto' }} maxWidth={'md'}>
                            <Toolbar />
                            <Box sx={{ pl: 1, mt: 2, mb: 1 }}>
                                <Link href='#' onClick={ goBack }>
                                    <Typography fontSize='16px' fontWeight='bold'>Буцах</Typography>
                                </Link>
                            </Box>
                            <Paper sx={{ boxShadow: 10, p: 2 }}>
                                {getToast(Boolean(ordErr), handleErrOff, ordErr, 'warning', 2500)}
                                {getToast(Boolean(itemsErr), () => {}, itemsErr, 'warning', 1500)}
                                <Box sx={{ mt: 3, p: 0, width: '100%' }}>
                                    <OrderItems 
                                        qtysErr={qtysErr} 
                                        setQtysErr={setQtysErr} 
                                    />
                                    {
                                        (branches?.length > 1 && isFetched) &&
                                            <SelectBranch
                                                branches={branches}
                                                branchId={branchId}
                                                setBranchId={setBranchId}
                                            />
                                    }
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 2,
                                            mt: 2
                                        }}
                                    >
                                        <SelectDeliveryType 
                                            isCome={isCome}
                                            setIsCome={setIsCome}
                                        />
                                        <SelectPayType
                                            payType={payType}
                                            setPayType={setPayType}
                                        />
                                    </Box>
                                    <Verify
                                        newOrder={newOrder}
                                        qtysErr={qtysErr}
                                        itemsErr={itemsErr}
                                        payType={payType}
                                        ocp={ocp}
                                        branchId={branchId}
                                    />
                                </Box>
                            </Paper>
                        </Container>
                    :   loc?.pathname === '/order/completed'
                            ?   <Outlet />
                            :   null
            }
        </OrderCtx.Provider>
    )
}
