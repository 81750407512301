import LogoInput from "../../../../auth/CompleteRegistration/RegistrationForm/CompanyInfo/LogoInput"
import { COMPANY_TYPES, SUPPLIER_ROLES } from "../../../../../utils/constants"
import SmallTextField from "../../../../../utils/Fields/SmallTexField"
import { isTokenValid } from "../../../../../utils/functions"
import { Box, Grid } from "@mui/material"
import LicenseInput from "./LicenseInput"
import { useState } from "react"
import InfoBox from "../InfoBox"

export function ReadOnlyField({ label, value }) {
    return (
        <SmallTextField
            label={label}
            value={value}
            size='small'
            inputProps={{ style: { cursor: 'not-allowed' } }}
        />
    )
}

export default function OrgInfo({ info, refetch, updateCompanyInfo, setMsg }) {
    const { role } = isTokenValid()
    const [logo, setLogo] = useState(info?.logo)
    const [publicName, setPublicName] = useState(info?.public_name)
    const handleUpdate = () => {
        if (!logo) {
            setMsg({ m: 'Лого хавсаргана уу!', s: 'warning' })
            return
        } else if (!publicName) {
            setMsg({ m: 'Түгээмэл нэр оруулна уу!', s: 'warning' })
            return
        }
        updateCompanyInfo({ logo, public_name: publicName })
            .then(res => {
                setMsg({ m: 'Амжилттай!', s: 'success' })
                refetch()
                window.dispatchEvent(new CustomEvent('logoUpdated', { detail: {logo: res?.data?.logo} }))
            })
            .catch(e => {
                setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
                console.log("ERR: ", e)
            })
    }

    return (
        <InfoBox
            label='Компаний мэдээлэл'
            handleUpdate={handleUpdate}
        >
            <Grid item xs={6}>
                <ReadOnlyField
                    label={'Компаний нэр'}
                    value={info?.name}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyField 
                    label='Компаний РД'
                    value={info?.rd}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyField 
                    label={'Үйл ажилллагааны чиглэл'} 
                    value={COMPANY_TYPES?.[info?.cType]} 
                />
            </Grid>
                {
                    SUPPLIER_ROLES.includes(role) && 
                        <Grid item xs={6}>
                            <ReadOnlyField 
                                label={'Урилгын код'}
                                value={info?.referral_code}
                            />
                        </Grid>
                }
            <Grid item xs={6}>
                <SmallTextField
                    label={'Түгээмэл нэр'}
                    value={publicName}
                    onChange={(e) => setPublicName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 2 }}>
                    <LicenseInput licenseFiles={info?.license} />
                    <LogoInput logo={logo} setLogo={setLogo} />
                </Box>
            </Grid>
        </InfoBox>
    )
}