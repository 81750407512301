import { toPrice } from '../../../../../../utils/functions'
import { TableCell, TableRow, Tooltip } from '@mui/material'
import { Redeem } from '@mui/icons-material'

export default function Item({ isQtyChanged, changedColor, item, idx }) {
    const isGift = item?.is_promo_item||item?.is_promo_gift

    return (
        <TableRow
            sx={{ 
                '& > td': { 
                    p: '2px 6px', 
                    fontSize: '13px' 
                },
                backgroundColor: isQtyChanged(item) ? changedColor : 'transparent',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' }
            }}
        >
            <TableCell width={'20px'}>{idx+1}</TableCell>
            <TableCell>{item.itemName}</TableCell>
            <TableCell 
                align='right'
                sx={{ pr: '10px !important' }}
            >
                {
                    isGift
                        ?   <Tooltip title='Урамшууллын бараа' placement='right'>
                                <Redeem sx={{ color: 'primary.light', fontSize: '18px' }}  />
                            </Tooltip>
                        :   toPrice(item.itemPrice)
                }
                    
            </TableCell>
            <TableCell align='center'>{item.iQty}</TableCell>
            <TableCell align='center' sx={{ color: 'secondary.light' }}>{item.itemQty}</TableCell>
            <TableCell 
                align='right'
                sx={{ pr: '10px !important' }}
            >
                {isGift ? '-' : toPrice(item.itemTotalPrice)}
            </TableCell>
        </TableRow>
    )
}
