import { useOrderListCtx } from '../../../..'
import DropCell from '../DropCell'

export default function ZoneCell() {
    const { zones, filters, handleFilters } = useOrderListCtx()
    const choices = zones?.reduce((acc, cur) => ({...acc, [cur?.id]: cur?.name}), {})

    return (
        <DropCell
            field='zone' 
            choices={choices} 
            filters={filters} 
            setFilters={handleFilters} 
        />
    )
}
