import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Tooltip, styled } from '@mui/material'
import { getToast } from '../../auth/helpers/ToastAlert'
import TableColumnsOrder from './TableColumnsOrder'
import { Settings } from '@mui/icons-material'
import { useState } from 'react'

const StyledSettingsIcon = styled(Settings)(({ theme }) => ({
    color: theme.palette.secondary.light,
    fontSize: '30px'
}))
export default function TableCustomization({ fieldsInfo, refetchFieldsInfo, updateFields }) {
    // dialog open/close
    const [open, setOpen] = useState(false)
    const handleOpen = (e) => { setOpen(true); e.stopPropagation() }
    const handleClose = () => { setOpen(false) }

    // update columns
    const [updatedCols, setUpdatedCols] = useState(fieldsInfo)

    const [msg, setMsg] = useState(null)

    const handleSave = () => {
        updateFields({ fields: updatedCols })
            .then(res => {
                refetchFieldsInfo()
                handleClose()
            })
            .catch(pe => 
                setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
            )
    }

    return (
        <Box sx={{ mr: 2 }}>
            {getToast(Boolean(msg?.m), () => setMsg(null), msg?.m, 'warning', 1500)}
            <Tooltip title='Хүснэгтийн тохиргоо' placement='right'>
                <IconButton onClick={(e) => handleOpen(e)} sx={{ m: 0, ml: 3, p: '6px', zIndex: 1500 }}>
                    <StyledSettingsIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Grid container spacing={1}>
                        <TableColumnsOrder
                            updatedCols={updatedCols}
                            setUpdatedCols={setUpdatedCols}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size='small' variant='contained' onClick={handleSave}>Хадгалах</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}