import { Box, Dialog, DialogContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { scrollStyle } from '../../../../../../utils/constants'
import DeliveryItemRow from './DeliveryItemRow'
import { Close } from '@mui/icons-material'

export default function DeliveryItems({ deliveryItems, setDeliveryId }) {
    return (
        <Dialog 
            open={Boolean(deliveryItems?.length > 0)}
            onClose={() => { setDeliveryId(null) }}
        >
            <DialogContent sx={{ p: '10px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right'
                    }}
                >
                    <IconButton 
                        onClick={() => { setDeliveryId(null) }}
                        sx={{ p: '2px' }}
                    >
                        <Close sx={{ fontSize: '18px' }} />
                    </IconButton>
                </Box>
                <Box>
                    <TableContainer
                        sx={{
                            height: '350px',
                            maxHeight: '400px',
                            overflowY: 'auto',
                            ...scrollStyle,
                            boxShadow: 4,
                            borderRadius: 1
                        }}
                    >
                        <Table 
                            size="small" 
                            aria-label="purchases"
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow 
                                    sx={{
                                            '& > th': { 
                                                fontSize: '13px', 
                                                fontWeight: 'bold', 
                                                textAlign: 'center', 
                                                p: '2px 4px', 
                                                borderRight: 1,
                                                borderRightColor: 'white',
                                                backgroundColor: 'secondary.light',
                                                color: 'white'
                                            } 
                                        }}
                                >
                                    <TableCell width='30px'>№</TableCell>
                                    <TableCell width='250px' sx={{ textAlign: 'left !important', pl: '10px !important' }}>Хүргэлт</TableCell>
                                    <TableCell width='150px'>Очсон цаг</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {deliveryItems?.map((item, idx) => <DeliveryItemRow idx={idx} item={item} />)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </DialogContent>
        </Dialog>
    )
}