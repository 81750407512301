import { TableCell, TableRow, Tooltip, useTheme } from "@mui/material"
import { COMPANY_TYPES } from "../../../../utils/constants"
import { useExternalComponentRect } from "../../../hooks"
import { FilterAlt } from "@mui/icons-material"
import TextFieldFilter from "./TextFieldFilter"
import DropMenuFilter from "./DropMenuFilter"

export default function FilterRow({ filters, setFilters, hhRef }) {
    const theme = useTheme()
	const { height } = useExternalComponentRect({ externalRef: hhRef })

    return (
        <TableRow
			sx={{
				"& > td": {
					borderBottom: "1px solid gray",
					borderBottomColor: theme.palette.primary.light
				},
				position: 'sticky',
				top: height+4||'initial',
				zIndex: 1
			}}
        >
            <TableCell
				align="center"
				sx={{ p: '2px' }}
			>
                <Tooltip title="Шүүж хайх мөр" placement="right">
                    <FilterAlt
						sx={{
							color: theme.palette.secondary.light,
							fontSize: "18px",
							margin: 0
						}}
					/>
                </Tooltip>
            </TableCell>
            <TextFieldFilter
				setFilters={setFilters}
				filters={filters}
				field="user__email"
			/>
            <TextFieldFilter
				setFilters={setFilters}
				filters={filters}
				field="user__phone"
			/>
            <TextFieldFilter
				setFilters={setFilters}
				filters={filters}
				field="name"
			/>
            <TextFieldFilter
				setFilters={setFilters}
				filters={filters}
				field="rd"
			/>
			<DropMenuFilter
				setFilters={setFilters}
				filters={filters}
				field="cType"
				choices={COMPANY_TYPES}
			/>
            <TableCell colSpan={4} sx={{ p: '2px' }} />
        </TableRow>
    )
}
