import { useOrderListCtx } from '../../../..'
import DropCell from '../DropCell'

export function SellerCell() {
    const { sellers, filters, handleFilters } = useOrderListCtx()
    const s = sellers?.reduce((acc, cur) => ({...acc, [cur?.id]: cur?.name}), {})
    return (
        <DropCell field='seller' choices={s} filters={filters} setFilters={handleFilters} />
    )
}

export function DelmanCell() {
    const { delmans, filters, handleFilters } = useOrderListCtx()
    const d = delmans?.reduce((acc, cur) => ({...acc, [cur?.id]: cur?.name}), {})
    return (
        <DropCell field='delman' choices={d} filters={filters} setFilters={handleFilters} />
    )
}

export function PackerCell() {
    const { packers, filters, handleFilters } = useOrderListCtx()
    const p = packers?.reduce((acc, cur) => ({...acc, [cur?.id]: cur?.name}), {})
    return (
        <DropCell field='packer' choices={p} filters={filters} setFilters={handleFilters} />
    )
}
