import { Button, IconButton, InputAdornment, TextField, Tooltip, Typography, Zoom } from '@mui/material'
import { registerUser, getRegistrationOTP } from '../../../../../../api/auth'
import { otpYup } from '../../../../../../utils/constants'
import { getToast } from '../../../../helpers/ToastAlert'
import { Timer } from '../../../../helpers/Timer'
import { Refresh } from '@mui/icons-material'
import { useState, useEffect } from 'react'
import { isEmpty } from 'underscore'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const validationSchema = Yup.object({ ...otpYup })

export default function OTPVerify({ values, setErrors, goToSignIn }) {
    const [alert, setAlert] = useState(null)
    const alertOff = () => { setAlert(null) }

    const onSuccess = () => { 
        setAlert({ m: 'Имэйл хаяг амжилттай баталгаажлаа!', s: 'success' })
        setTimeout(() => { goToSignIn() }, 2000)
    }

    const [otpErr, setOtpErr] = useState('')
    // make registration using user inputs and otp
    const doRegister = (otp) => {
        const { email, phone, password1: password } = values
        registerUser({ email, phone, otp, password }).then(r => {
            onSuccess()
        }).catch(e => {
            const err = e?.response?.data
            const email = err?.email?.[0]
            if (email) { setErrors({ email }) }
            const phone = err?.phone?.[0]
            if (phone) { setErrors({ phone }) }
            const password = err?.password?.[0]
            if (password) { setErrors({ password }) }
            const otp = err?.otp?.[0]
            if (otp) { setOtpErr(otp) }
            console.log("ERR: ", e)
        })
    }

    const [waitAlert, setWaitAlert] = useState(false)
    const handleWaitOpen = () => { setWaitAlert(true) }

    // get otp
    const [leftover, setLeftover] = useState(null)
    const [isReqSending, setIsReqSending] = useState(false)

    const sendOtp = () => {
        setIsReqSending(true)
        getRegistrationOTP({ email: values?.email, phone: values?.phone })
            .then(r => {
                setAlert({ m: 'Баталгаажуулах код илгээлээ!', s: 'success' })
            })
            .catch(e => { console.log("ERR: ", e) })
            .finally(() => { setIsReqSending(false) })
    }

    const onSubmit = ({ otp }) => { doRegister(otp) }
    const { handleSubmit, getFieldProps, errors, values: otpVals } = useFormik({
        initialValues: { otp: '' },
        onSubmit,
        validationSchema
    })

    // tooltip
    const [tooltip, setTooltip] = useState(false)
    const handleTooltipOpen = () => { setTooltip(true) }
    const handleTooltipClose = () => { setTooltip(false) }
    useEffect(() => {
        if (tooltip) { setTimeout(() => { handleTooltipClose() }, 3000) }
    }, [tooltip])
    useEffect(() => {
        if (otpErr?.includes('код авна уу')) { handleTooltipOpen() }
    }, [otpErr])

    const handleGetOtp = () => { leftover ? handleWaitOpen() : sendOtp() }

    const handleEnter = (e) => { if (e.key === 'Enter') { handleSubmit() } }

    const toHide = otpVals?.otp && isEmpty(errors)

    return (
        <>
            <TextField
                autoFocus
                label='Баталгаажуулах код'
                name='otp'
                size='small'
                sx={{ width: '100%', mb: 2 }}
                InputProps={{
                    endAdornment: <InputAdornment position='end'>
                                    {(Boolean(leftover) && isReqSending === false) && <Timer initial={leftover} setRemaining={setLeftover} />}
                                    <Tooltip 
                                        title={<Typography fontSize={'13px'}>Дахин код авах</Typography>} 
                                        TransitionComponent={Zoom} 
                                        placement='top' sx={{ fontSize: '26px' }}
                                        disableHoverListener
                                        disableFocusListener
                                        open={tooltip}
                                        onOpen={handleTooltipOpen}
                                        onClose={handleTooltipClose}
                                    >
                                        <IconButton 
                                            sx={{ m: 0, color: leftover ? '#757575' : 'primary.main', visibility: toHide ? 'hidden' : 'visible'}} 
                                            onClick={ handleGetOtp }
                                            onMouseEnter={ handleTooltipOpen }
                                            onMouseLeave={ handleTooltipClose }
                                        >
                                            <Refresh />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                }}
                {...getFieldProps('otp')}
                id='otp'
                error={ Boolean(errors?.otp) || Boolean(otpErr) }
                helperText={ Boolean(errors?.otp) ? errors.otp : otpErr ? otpErr : ' ' }
                onKeyDown={handleEnter}
            />
            <Button variant='contained' size='small' sx={{ p: '6.5px', width: '100%' }} onClick={ handleSubmit }>
                Баталгаажуулах
            </Button>
            { getToast(Boolean(alert?.m), alertOff, alert?.m, alert?.s, 2000) }
        </>
    )
}