import { Box, Button, TextField, Typography } from '@mui/material'
import { getCompanyName } from '../../../../../../api/auth'
import { useEffect, useState } from 'react'

export default function Rd({ getFieldProps, getErrors, rd, name }) {
    const [ cn, setCn ] = useState(null) // company name
    const [errMsg, setErrMsg] = useState(null)
    useEffect(() => { 
        if (cn) { setCn(null) } 
        if (errMsg) { setErrMsg(null) } 
    }, [name, rd])
    const getCn = () => {
        getCompanyName(rd)
            .then(res => {
                if (res?.data?.name) { setCn(res?.data?.name) }
                else { setErrMsg('Регистр олдсонгүй!') }
            })
            .catch(e => {
                if (e?.response?.status === 500 && e?.response.data === 'server') {
                    setErrMsg('Сервер холбогдох боломжгүй!')
                }
            })
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'baseline' }}>
            <TextField
                label='Регистр'
                inputProps={{ style: { fontSize: '15px' }}}
                InputLabelProps={{ style: { fontSize: '15px' } }}
                name='rd'
                {...getFieldProps('rd')}
                {...getErrors('rd')}
                size='small'
                sx={{ width: '130px', pb: '2px' }}
            />
            <Button onClick={getCn} variant='contained' size='small' sx={{ p: 1, pt: '10px', pb: '6px', mr: 2, width: '130px' }}>Шалгах</Button>
            <Typography sx={{ fontSize: '15px', color: errMsg ? 'red' : 'green' }}>{errMsg ? errMsg : cn ? cn : ''}</Typography>
        </Box>
    )
}
