import { addSupplierStaff, updateSupplierStaff } from '../../../../../api/staff'
import AddStaffDialogContent from './AddStaffDialogContent'
import { emailYup } from '../../../../../utils/constants'
import { SUPPLIER_STAFF_ROLES } from '../utils'
import { Button, Dialog } from '@mui/material'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const validationSchema = Yup.object({
    last_name: Yup.string().max(150, 'Нэр хэт урт байна!').required('Ажилтны овог оруулна уу!'),
    name: Yup.string().max(150, 'Нэр хэт урт байна!').required('Ажилтны нэр оруулна уу!'),
    rd: Yup.string().transform(val => val.trim().replace(/\s+/g, '')).matches(/^[а-яА-Я]{2}\d{8}$/, 'Регистрийн дугаар буруу байна!').required('Регистрийн дугаар оруулна уу!'),
    ...emailYup,
    phone: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').required('Утасны дугаар оруулна уу!'),
    role: Yup.string().oneOf(Object.keys(SUPPLIER_STAFF_ROLES), 'Ажилтны төрөл буруу байна!').required('Ажил үүрэг сонгоно уу!')
})
const validationEditSchema = Yup.object({
    last_name: Yup.string().max(150, 'Нэр хэт урт байна!').required('Ажилтны овог оруулна уу!'),
    name: Yup.string().max(150, 'Нэр хэт урт байна!').required('Ажилтны нэр оруулна уу!'),
    rd: Yup.string().transform(val => val.trim().replace(/\s+/g, '')).matches(/^[а-яА-Я]{2}\d{8}$/, 'Регистрийн дугаар буруу байна!').required('Регистрийн дугаар оруулна уу!'),
    phone: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').required('Утасны дугаар оруулна уу!'),
})

export default function AddStaffButton({ refetch, successMsg, waitMsg, editing, setEditing }) {
    const isEdit = editing !== null && Object.keys(editing).length > 0

    const [open, setOpen] = useState(false)

    // form
    const { handleSubmit, getFieldProps, setErrors, setValues, setTouched, errors, touched, values } = useFormik({
        initialValues: editing||{
            'last_name': '',
            'name': '',
            'rd': '',
            'email': '',
            'phone': '',
            'role': ''
        },
        onSubmit: (fVals) => {
            if (Boolean(isEdit)) {
                const data = { ...fVals, staff_id: fVals?.id }
                updateSupplierStaff(data)
                    .then(_ => {
                        successMsg()
                        refetch()
                        handleClose()
                    })
                    .catch(e => {
                        const err = e?.response?.data
                        const emailErr = err?.email
                        const phoneErr = err?.phone
                        const rdErr = err?.rd
                        if (emailErr) { setErrors({ email: emailErr }) }
                        else if (phoneErr) { setErrors({ phone: phoneErr }) }
                        else if (rdErr) { setErrors({ rd: rdErr }) }
                        else { waitMsg(); console.log("E: ", e) }
                    })
            } else {
                addSupplierStaff(fVals)
                    .then(_ => {
                        successMsg()
                        refetch()
                        handleClose()
                    })
                    .catch(e => {
                        const err = e?.response?.data
                        const emailErr = err?.email
                        const phoneErr = err?.phone
                        const rdErr = err?.rd
                        if (emailErr) { setErrors({ email: emailErr }) }
                        else if (phoneErr) { setErrors({ phone: phoneErr }) }
                        else if (rdErr) { setErrors({ rd: rdErr }) }
                        else { waitMsg(); console.log("E: ", e) }
                    })
            }
        },
        validationSchema: Boolean(isEdit) ? validationEditSchema: validationSchema,
        validateOnChange: false
    })

    const handleAddOpen = () => {
        setOpen(true)
    }

    const handleFlukyClose = () => { 
        setOpen(false)
        if (editing) {
            setEditing(null)
            setValues({})
            setTouched({})
        }
    }
    const handleClose = () => {
        setOpen(false)
        if (editing) {
            setEditing(null)
        }
        if (values) {
            setValues({})
            setTouched({})
        }
    }

    useEffect(() => {
        if (isEdit) { setValues(editing) }
    }, [isEdit])

    useEffect(() => {
        if (isEdit) {
            if (Object.keys(values).length > 0) {
                setOpen(true)
            }
        }
    }, [values, isEdit])
    //console.log("V: ", touched)

    return (
        <>
            <Button 
                size='small'
                variant='contained'
                onClick={ handleAddOpen }
                sx={{ width: 'fit-content', p: '2px 6px' }}
            >Ажилтан нэмэх</Button>
            <Dialog open={open} onClose={ handleFlukyClose }>
                <AddStaffDialogContent
                    handleClose={ handleClose }
                    handleSubmit={ handleSubmit }
                    getFieldProps={ getFieldProps }
                    errors={ errors }
                    touched={ touched }
                    values={ values }
                    isEdit={ isEdit }
                />
            </Dialog>
        </>
    )
}