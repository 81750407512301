import { getMasLog, getSupplierStaffLog } from '../../../../api/log'
import { Box, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import StaffLog from './StaffLog'
import MacsLog from './MacsLog'

// const cols = ['№', 'Огноо', 'Ажилтны нэр', 'Ажилтны имэйл', 'Ажилтны төрөл', 'Үйлдэл', 'Тайлбар', 'IP хаяг']
export default function SystemLog() {
    const [tab, setTab] = useState(0)

    // getSupplierStaffLog
    // getMacsLog

    return (
        <>
            <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)} 
                aria-label='tabs' 
                sx={{
                    minHeight: '30px', height: '30px',
                    '& .MuiTab-root': { minHeight: '30px', height: '30px' }
                }}
            >
                <Tab label={'Ажилтны лог'} value={0} />
                {/* <Tab label={'MACS лог'} value={1} /> */}
            </Tabs>
            <Box sx={{ height: '100%' }}>
                { tab === 0 && <StaffLog /> }
                {/* { tab === 1 && <MacsLog />} */}
            </Box>
        </>
    )
}

