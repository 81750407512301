import { toPrice } from "../../../../../../../../utils/functions"
import { TableCell, TableRow, Tooltip } from "@mui/material"
import { Redeem } from "@mui/icons-material"

export default function OrderItem({ idx, item, role }) {
    const isGift = item?.is_promo_gift||item?.is_promo_item
    return (
        <TableRow
            sx={{
                '& > td': { textAlign: 'center', p: '2px' },
            }}
        >
            <TableCell>{idx+1}</TableCell>
            <TableCell sx={{ textAlign: 'left !important' }}>{item?.itemName}</TableCell>
            <TableCell 
                sx={{ 
                    textAlign: 'right !important', 
                    pr: isGift ? '30px !important' : '16px !important' 
                }}
            >
                {
                    isGift
                        ?   <Tooltip title='Урамшууллын бараа' placement='right'>
                                <Redeem sx={{ color: 'primary.light', fontSize: '20px' }}  />
                            </Tooltip>
                        :   toPrice(item?.itemPrice)
                }
            </TableCell>
            <TableCell>{item?.iQty||'-'}</TableCell>
            {role !== 'P' && <TableCell sx={{ color: 'secondary.light' }}>{item?.itemQty}</TableCell>}
            <TableCell 
                sx={{ textAlign: 'right !important' }}
            >
                {
                    isGift
                        ?   '-'
                        :   toPrice(item?.itemTotalPrice)
                }
            </TableCell>
        </TableRow>
    )
}
