import { TableCell, Typography } from '@mui/material'

export default function DeliveryStartOrEndCell({ rowSpan, isDelman, handleClick, deliveryTime, label = 'Эхлүүлэх' }) {
    return (
        <TableCell 
            rowSpan={rowSpan}
            align='center'
        >
            {
                isDelman
                    ?   <Typography
                            onClick={handleClick}
                            sx={{
                                cursor: 'pointer',
                                fontSize: '14px',
                                textDecoration: deliveryTime ? 'none' : 'underline',
                                '&:hover': {
                                    color: 'secondary.light',
                                    backgroundColor: '#f0f0f0'
                                },
                            }}
                        >
                            {deliveryTime||label}
                        </Typography>
                    :   deliveryTime||'-'
            }
        </TableCell>
    )
}
