import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { CustomDivider } from '..'

export default function SelectDeliveryType({ isCome, setIsCome }) {
    return (
        <Box
            sx={{ width: '100%' }}
        >
            <CustomDivider text={'Хүргэлтийн хэлбэр'} />
            <RadioGroup
                value={isCome}
                onChange={() => setIsCome(!isCome)}
                row
                sx={{ width: '100%', justifyContent: 'center' }}
            >
                <FormControlLabel
                    value={false} 
                    control={<Radio />} 
                    label={<Typography
                                sx={{ 
                                    color: '#555', 
                                    fontSize: '14px'
                                }}
                            >Хүргэлтээр</Typography>
                        }
                />
                <FormControlLabel 
                    value={true} 
                    control={<Radio />}
                    label={<Typography
                                sx={{ 
                                    color: '#555', 
                                    fontSize: '14px'
                                }}
                            >Очиж авах</Typography>
                        }
                />
            </RadioGroup>
        </Box>
    )
}