import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { createDelivery, getOrdersToDeliver, updateDeliveryZoneDelman } from "../../../../../api/delivery"
import SelectDelman from "./SelectDelman"
import DeliveryZone from "./DeliveryZone"
import { useQuery } from "react-query"
import { useDeliveryCtx } from ".."
import { useState } from "react"

export default function Allocation() {
    const { setMsg, successMsg, waitMsg, delmanNames, getDelvsCntRefetch, refetchDelvs, setTab, isTab1 } = useDeliveryCtx()
    const onSuccess = () => { if (data?.data?.length === 0 && !isTab1) { setTab(1) } }
    const { data, refetch } = useQuery('get_order_to_deliver', getOrdersToDeliver, { refetchOnWindowFocus: true, onSuccess })
    const ordersToDeliver = data?.data||[]
    // group orders by delman then by zone then by orderer
    const ordersByDelmanByZoneByOrderer = ordersToDeliver?.reduce((acc, order) => {
        const added_delman = acc?.find(a => a.delman?.id === order.delman?.id)
        if (added_delman) {
            const added_zone = added_delman.zones?.find(z => z.zone?.id === order.zone?.id)
            if (added_zone) {
                const added_orderer = added_zone.orderers?.find(o => o.orderer?.id === order.orderer?.id)
                if (added_orderer) {
                    added_orderer.orders?.push(order)
                } else {
                    added_zone.orderers?.push({ orderer: order.orderer, orders: [order] })
                }
            } else {
                added_delman.zones?.push({ zone: order.zone, orderers: [{ orderer: order.orderer, orders: [order] }] })
            }
        } else {
            acc?.push({ delman: order.delman, zones: [{ zone: order.zone, orderers: [{ orderer: order.orderer, orders: [order] }] }] })
        }
        return acc
    }, [])
    //console.log("O: ", ordersByDelmanByZoneByOrderer)

    const delmanOrdersCnt = ordersToDeliver?.reduce((acc, order) => {
        if (order.delman?.id) {
            acc[order.delman.id] = (acc[order.delman.id]||0) + 1
        }
        return acc
    }, {})

    const zoneOrdersCnt = ordersToDeliver?.reduce((acc, order) => {
        if (order.zone?.id) {
            acc[order.zone.id] = (acc[order.zone.id]||0) + 1
        }
        return acc
    }, {})

    // select delman
    const [delmanBox, setDelmanBox] = useState(null)
    //console.log("DB: ", zones)
    const delmanBoxOn = (zoneId) => { setDelmanBox(zoneId) }
    const delmanBoxOff = () => { setDelmanBox(null) }
    const handleSelectDelman = (delmanId) => {
        updateDeliveryZoneDelman({ zone_id: delmanBox, delman_id: delmanId||null })
            .then(res => {
                delmanBoxOff()
                refetch()
                successMsg()
            })
            .catch(e => {
                //console.log("ERR: ", e)
                waitMsg()
            })
    }

    const zones = ordersToDeliver?.map(z => ({ zone_id: z.zone?.id, delman_id: z.delman?.id}))||[]
    const handleAllocation = (delman_id) => {
        if (!delman_id) { setMsg({ m: 'Тухайн бүс рүү гарах түгээгч сонгоно уу!', s: 'warning' }); return }
        const data = { delman_id, zone_ids: zones.filter(z => z.delman_id === delman_id).map(z => z.zone_id) }
        createDelivery(data)
            .then(res => {
                refetch()
                successMsg()
                getDelvsCntRefetch()
                refetchDelvs()
            })
            .catch(e => {
                console.log("ERR: ", e)
                const derr = e?.response?.data?.delman
                if (derr) {
                    setMsg({ m: derr, s: 'warning' })
                } else {
                    waitMsg()
                }
            })
    }
    let counter = 1
    let orderer_ctr = 1

    const [hoveredZoneId, setHoveredZoneId] = useState(null)
    const [hoveredDelmanId, setHoveredDelmanId] = useState(null)

    return (
        <Box>
            <DeliveryZone />
            <TableContainer
                sx={{
                    p: 1,
                    boxShadow: 3,
                    width: '80%'
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow
                            sx={{
                                '& > th': {
                                    color: 'white',
                                    textAlign: 'center',
                                    backgroundColor: 'secondary.light',
                                    borderRight: '1px solid white',
                                    p: '2px 4px'
                                }
                            }}
                        >
                            <TableCell>№</TableCell>
                            <TableCell>Түгээлтийн бүс</TableCell>
                            <TableCell>Хариуцсан түгээгч</TableCell>
                            <TableCell>№</TableCell>
                            <TableCell>Захиалагч</TableCell>
                            <TableCell>№</TableCell>
                            <TableCell>Захиалгын дугаар</TableCell>
                            <TableCell>Түгээлтэнд гаргах</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            ordersByDelmanByZoneByOrderer?.length === 0
                                ?   <TableRow><TableCell colSpan={8} align='center' sx={{ color: '#4d4d4d' }}>Түгээлтэнд гаргах захиалга байхгүй байна!</TableCell></TableRow>
                                :   ordersByDelmanByZoneByOrderer?.map((delman, idx) => (
                                        <>
                                            {delman.zones?.map((zone, zone_idx) => (
                                                <>
                                                {zone.orderers?.map((orderer, orderer_idx) => (
                                                    <>
                                                    {orderer.orders?.map((order, order_idx) => (
                                                        <TableRow
                                                            key={order.id}
                                                            sx={{
                                                                '& > td': {
                                                                    p: '2px 6px',
                                                                    borderRight: 1,
                                                                    borderRightColor: '#e1e1e1',
                                                                    backgroundColor: hoveredZoneId === zone?.zone?.id || hoveredDelmanId === delman?.delman?.id ? '#f0f0f0' : 'white',
                                                                },
                                                                //'&:hover': { backgroundColor: '#f0f0f0' }
                                                            }}
                                                            onMouseEnter={() => setHoveredZoneId(zone?.zone?.id)}
                                                            onMouseLeave={() => setHoveredZoneId(null)}
                                                        >
                                                            {orderer_idx === 0 && order_idx === 0 && (
                                                                <>
                                                                    <TableCell rowSpan={zoneOrdersCnt?.[order.zone?.id]||1} align='center'>{counter++}</TableCell>
                                                                    <TableCell rowSpan={zoneOrdersCnt?.[order.zone?.id]||1} align='center'>{zone.zone?.name}</TableCell>
                                                                    <TableCell
                                                                        rowSpan={zoneOrdersCnt?.[order.zone?.id]||1}
                                                                        align='center'
                                                                        onClick={() => delmanBoxOn(zone?.zone?.id)}
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            '&:hover': {
                                                                                textDecoration: 'underline',
                                                                                color: 'secondary.light',
                                                                                backgroundColor: '#f0f0f0',
                                                                            },
                                                                            textDecoration: order.delman?.id ? 'underline' : 'none',
                                                                            color: order.delman?.id ? 'secondary.light' : 'black',
                                                                            borderRight: 1
                                                                        }}
                                                                    >
                                                                        {order.delman?.name||'Сонгох'}
                                                                    </TableCell>
                                                                </>
                                                            )}
                                                            {
                                                                order_idx === 0 && (
                                                                    <>
                                                                        <TableCell align="center" rowSpan={orderer?.orders?.length||1}>{orderer_ctr++}</TableCell>
                                                                        <TableCell rowSpan={orderer?.orders?.length||1}>{order?.orderer?.name}</TableCell>
                                                                    </>
                                                                )
                                                            }
                                                            <TableCell align="center">{order_idx + 1}</TableCell>
                                                            <TableCell align="center" sx={{ borderLeft: 1 }}>{order.orderNo}</TableCell>
                                                            {
                                                                orderer_idx === 0 && order_idx === 0 && zone_idx === 0 && (
                                                                    <>
                                                                        <TableCell
                                                                            rowSpan={delmanOrdersCnt[order.delman?.id]||1} 
                                                                            align='center'
                                                                            onMouseEnter={() => setHoveredDelmanId(delman?.delman?.id)}
                                                                            onMouseLeave={() => setHoveredDelmanId(null)}
                                                                            sx={{
                                                                                backgroundColor: hoveredZoneId === zone?.zone?.id ? 'white !important' : 'white',
                                                                                '&:hover': {
                                                                                    background: '#f0f0f0 !important'
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                sx={{
                                                                                    p: '2px 4px',
                                                                                    fontSize: '12px',
                                                                                    color: 'white',
                                                                                    backgroundColor: 'primary.light',
                                                                                    '&:hover': { backgroundColor: 'primary.dark' }
                                                                                }}
                                                                                onClick={() => { handleAllocation(order.delman?.id) }}
                                                                            >Түгээлтэнд гаргах</Button>
                                                                        </TableCell>
                                                                    </>
                                                                )
                                                            }
                                                        </TableRow>
                                                    ))}
                                                    </>
                                                ))}
                                                </>
                                            ))}
                                        </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <SelectDelman
                delmanBox={delmanBox}
                delmanBoxOff={delmanBoxOff}
                delmans={delmanNames}
                handleSelectDelman={handleSelectDelman}
            />
        </Box>
    )
}
