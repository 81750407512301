import { PROCESS_COLORS, PROCESS_TYPES } from '../../../../../../constants'
import { changeProcess } from '../../../../../../../api/order'
import { Box, Typography, useTheme } from '@mui/material'
import { useOrderListCtx } from '../../..'
import TableCellMenu from './TableCellMenu'
import { isEmpty, keys } from 'underscore'
import { capitalize } from './utils'

const FOR_EMPTY = {}
const FOR_PACKER = { M: 'Бэлтгэж эхэлсэн', P: 'Бэлэн болсон' }
const AB_CHOICES = {
    N: 'Шинэ',
    M: 'Бэлтгэж эхэлсэн', 
    P: 'Бэлэн болсон',
    D: 'Хүргэгдсэн',
    A: 'Хүлээн авсан',
    R: 'Буцаагдсан',
    C: 'Хаалттай'
}
const getOrderProcess = (role) => {
    if (['A', 'B'].includes(role)) { return AB_CHOICES }
    else if (role === 'P') { return FOR_PACKER }
    else { return FOR_EMPTY }
}

export const getProcessColor = (process) => PROCESS_COLORS.find(c => c.name === process)?.color

export default function SelectProcessCell({ row, setRow }) {
    const { role, setMsg, waitMsg } = useOrderListCtx()
    const theme = useTheme()
    const prc = getOrderProcess(role)
    const choices = !isEmpty(prc) ? Object.fromEntries(Object.entries(prc).filter(([key]) => key !== row?.process)) : {}
    //console.log("PRC: ", prc, choices)

    // change process
    const handleChange = (process) => {
        changeProcess({ order_id: row?.id, process })
            .then(res => { setRow({ ...row, process }) })
            .catch(e => {
                const inDelivery = e?.response?.data?.in_delivery?.[0] === 'Order in delivery!'
                if (inDelivery) { setMsg({m: 'Захиалга түгээлтэнд гарсан бөгөөд түгээлт нь дуусаагүй байна!', s: 'warning'}) }
                else { waitMsg() }
                console.log("ER: ", e)
            })
    }

    const clr = row?.process ? getProcessColor(row.process) : '#d52127'

    const noSelect = row.process === 'O' || row?.status === 'S'

    return (
        <TableCellMenu
            current={<Typography
                        fontSize='13px'
                        noWrap 
                        sx={{ 
                            textAlign: 'center', width: '100%',
                            backgroundColor: clr||'initial', 
                            color: clr ? 'white' : 'initial'
                        }}
                    >
                        {PROCESS_TYPES[row?.process]??'-'}
                    </Typography>}
            dropMenu={
                <Box
                    sx={{ 
                        display: noSelect ? 'none' : 'flex', flexDirection: 'column',
                        backgroundColor: 'white', boxShadow: 8
                    }}
                >
                    {keys(choices)?.map(k => (
                        <Typography
                            key={`${k}`}
                            onClick={ () => { handleChange(k) } }
                            sx={{
                                borderBottom: '1px solid transparent', borderRadius: 0,
                                p: '4px 10px', fontSize: '13px', color: theme.palette.primary.light,
                                '&:hover': { backgroundColor: '#f1f1f1', cursor: 'pointer', borderBottom: '1px solid #ad5555' }
                            }}
                        >{capitalize(choices[k])}</Typography>
                    ))}
                </Box>
            }
        />
    )
}
