import getDelmanColor from '../delmanColors'
import DeliveryLoc from './DeliveryLoc'

export default function DeliveryLocs({ deliveryLocs }) {
    const dc = deliveryLocs?.map(d => {
        const color = getDelmanColor(d.delman?.id)
        return { ...d, color }
    })

    return (
        <>
            {
                dc?.map(d => (
                    <DeliveryLoc
                        key={d.id}
                        data={d}
                    />
                ))
            }
        </>
    )
}
