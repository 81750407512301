import { TableCell, TableRow, Tooltip, useTheme } from "@mui/material"
import { ISCOMPANIESCUST } from "../../../../../../utils/constants"
import { useExternalComponentRect } from "../../../../../hooks"
import TextFieldFilter from './FilterCells/TextFieldFilter'
import { FilterAlt } from "@mui/icons-material"
import DropCell from '../DropCell'

export default function FilterRow({ filtering, setFiltering, hhRef }) {
	const theme = useTheme()
	const { height } = useExternalComponentRect({ externalRef: hhRef })

	return (
		<TableRow
			sx={{
				"& > td": {
					p: "2px ",
					borderBottom: "1px solid gray",
					borderBottomColor: theme.palette.primary.light
				},
				position: 'sticky',
				top: height||'initial',
				zIndex: 1
			}}
		>
			<TableCell align="center" sx={{ p: "2px" }}>
				<Tooltip title="Шүүж хайх мөр" placement="right">
					<FilterAlt 
						sx={{ 
							color: theme.palette.secondary.light, 
							fontSize: "20px",
							margin: 0
						}}
					/>
				</Tooltip>
			</TableCell>
			<TextFieldFilter setFilters={setFiltering} field="name" />
			<TextFieldFilter setFilters={setFiltering} field="rn" />
			<TextFieldFilter setFilters={setFiltering} field="email" />
			<TextFieldFilter setFilters={setFiltering} field="phone" />
			<DropCell field='is_cmp' choices={ISCOMPANIESCUST} filters={filtering} setFilters={setFiltering} />
			<TableCell colSpan={5} sx={{ p: '2px' }} />
		</TableRow>
	)
}
