import { getToast } from '../../../auth/helpers/ToastAlert'
import { getSupplierStaffs } from '../../../../api/staff'
import AddStaffButton from './AddStaffButton'
import { useQuery } from 'react-query'
import StaffsList from './StaffList'
import { Box } from '@mui/material'
import { useState } from 'react'

export default function Staff() {
    const [role, setRole] = useState(null)
    const [ordering, setOrdering] = useState('name')
    const [page, setPage] = useState(1)
    const pageSize = 30
    const { data, refetch, isFetched } = useQuery(['get_supplier_staffs', page, pageSize, role, ordering], getSupplierStaffs, { retry: false })
    const staffs = data?.data?.results || []

    // toast
    const [msg, setMsg] = useState(null)
    const msgOff = () => { setMsg(null) }
    const successMsg = () => { setMsg({ m: 'Амжилттай!', s: 'success' }) }
    const waitMsg = () => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) }

    // editing
    const [editing, setEditing] = useState({})
    const handleEdit = (staff) => { setEditing(staff) }

    return (
        <>
            {
                isFetched
                    ?   <Box 
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                gap: '4px'
                            }}
                        >
                            <AddStaffButton
                                refetch={ refetch }
                                successMsg={ successMsg }
                                waitMsg={ waitMsg }
                                editing={ editing }
                                setEditing={ setEditing }
                            />
                            <StaffsList
                                staffs={staffs}
                                fetchStaffs={ refetch }
                                successMsg={ successMsg }
                                waitMsg={ waitMsg }
                                handleEdit={ handleEdit }
                            />
                            { getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 1500 ) }
                        </Box>
                    :   null
            }
        </>
    )
}
