import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { toPrice } from '../../../../utils/functions'
import { useOrderCtx } from '../..'

export default function VerifyTable({ payType, totalPrice, totalCount }) {
    const { branchCnt } = useOrderCtx()
    const adr = window.localStorage.getItem('adr')||'Очиж авах'
    const tp = payType === 'C' ? totalPrice : window.localStorage.getItem('tp')||''
    const tc = payType === 'C' ? totalCount : window.localStorage.getItem('tc')||''

    return (
        <Table
            sx={{
                width: '300px',
                mb: 1
            }}
        >
            <TableBody 
                sx={{
                    '& > tr > td': { 
                        m: 0,
                        padding: '2px 6px',
                        fontSize: '14px' 
                    },
                }}
            >
                <TableRow>
                    <TableCell sx={{ color: 'primary.light' }}>Нийт үнэ</TableCell>
                    <TableCell align='right' sx={{ color: '#444', fontSize: '14px', color: 'secondary.light', fontWeight: 500 }}>{tp ? toPrice(tp) : ''}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'primary.light' }}>Нийт, тоо ширхэг</TableCell>
                    <TableCell align='right' sx={{ color: '#444', fontSize: '14px' }}>{tc}</TableCell>
                </TableRow>
                {
                    Boolean(branchCnt && branchCnt > 1) &&
                    <TableRow>
                        <TableCell sx={{ color: 'primary.light' }}>Салбар</TableCell>
                        <TableCell align='right' sx={{ color: '#444', fontSize: '14px' }}>{adr}</TableCell>
                    </TableRow>
                }
            </TableBody>
        </Table>
    )
}
